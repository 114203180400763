import { vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vShow as _vShow, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-control" }
const _hoisted_2 = { class: "flex flex-row space-x-4" }
const _hoisted_3 = ["disabled", "id"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "label-text-alt text-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      name: _ctx.name,
      rules: _ctx.rules
    }, {
      default: _withCtx(({ field, errorMessage, errors }) => [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", _mergeProps(field, {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vModel) = $event)),
            disabled: _ctx.disabled,
            type: "checkbox",
            id: _ctx.id,
            class: ["my-2 checkbox", { 'border-error': errors.length > 0 }]
          }), null, 16, _hoisted_3), [
            [_vModelCheckbox, _ctx.vModel]
          ]),
          _createElementVNode("label", {
            for: _ctx.id,
            class: _normalizeClass(["label flex-1", {'cursor-not-allowed': _ctx.disabled, 'cursor-pointer': !_ctx.disabled}])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["label-text", { 'text-error': errors.length > 0, 'text-opacity-50': _ctx.disabled }])
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 2)
          ], 10, _hoisted_4)
        ]),
        _withDirectives(_createElementVNode("label", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(errorMessage), 1)
        ], 512), [
          [_vShow, !!errorMessage]
        ])
      ]),
      _: 3
    }, 8, ["name", "rules"])
  ]))
}