
import { defineComponent, inject } from 'vue'
import Axios from '@/api/axios'
import { HydraResponse } from '@interface/api/Response'
export default defineComponent({
  name: 'base-paginator-pagination',
  inject: ['setPaginatorPage', 'paginatorPage'],
  props: {
    split: {
      type: Number,
      default: 5
    }
  },
  setup () {
    const injection: any = {}
    const paginatorResponse = inject<HydraResponse>('paginatorResponse')
    if (paginatorResponse) {
      injection.paginatorResponse = paginatorResponse
    }

    return injection
  },
  computed: {
    response (): HydraResponse | null {
      if (!this.paginatorResponse) { return null }
      return this.paginatorResponse
    },
    newPrev () {
      return this.current - 1
    },
    newNext () {
      return this.current + 1
    },
    current (): number {
      if (!this.response) { return -1 }
      if (!this.response['hydra:view']) { return 1 }
      const uri = new URL(Axios.defaults.baseURL + this.response['hydra:view']['@id'])
      const page = uri.searchParams.get('page')
      if (!page) {
        return -1
      }
      return parseInt(page)
    },
    last (): number {
      if (!this.response) { return -1 }
      if (!this.response['hydra:view']) { return 1 }
      const uri = new URL(Axios.defaults.baseURL + this.response['hydra:view']['hydra:last'])
      const page = uri.searchParams.get('page')
      if (!page) {
        return -1
      }
      return parseInt(page)
    },
    first (): number {
      if (!this.response) { return -1 }
      if (!this.response['hydra:view']) { return 1 }
      const uri = new URL(Axios.defaults.baseURL + this.response['hydra:view']['hydra:first'])
      const page = uri.searchParams.get('page')
      if (!page) {
        return -1
      }
      return parseInt(page)
    },
    pages (): Array<number> {
      if (!this.last) { return [] }
      return Array.from({ length: this.last }, (_, i) => i + 1)
    },
    middle () {
      return Math.ceil(this.split / 2)
    },
    buttonsToRender () {
      if (this.current < this.split) {
        return Array.from({ length: this.split }, (_, i) => i + 1)
      }
      const last = this.pages.length - this.split
      if ((this.current - 1) > last) {
        return Array.from({ length: this.split }, (_, i) => last + (i + 1))
      }
      return Array.from({ length: this.split }, (_, i) => (this.middle - (this.split - i)) + this.current).filter(v => v >= this.first && v <= this.last)
    },
    pagination () {
      return this.pages.length < this.buttonsToRender.length ? this.pages : this.buttonsToRender
    }
  },
  methods: {
    next () {
      let nextPage = this.paginatorPage + 1
      if (nextPage >= this.last) {
        nextPage = this.last
      }
      this.setPaginatorPage(nextPage)
    },
    prev () {
      let nextPage = this.paginatorPage - 1
      if (nextPage <= this.first) {
        nextPage = this.first
      }
      this.setPaginatorPage(nextPage)
    }
  }
})
