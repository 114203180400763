import { Router, RouteRecordRaw } from 'vue-router'
import MeLayout from '@module/customer_frontend/layouts/me.vue'
import IndexView from '@module/customer_frontend/views/index.vue'
import ProfileView from '@module/customer_frontend/views/profile.vue'
import AccountView from '@module/customer_frontend/views/account.vue'
import AccountGeneralView from '@module/customer_frontend/views/account/general.vue'
import AccountTransactionsView from '@module/customer_frontend/views/account/transactions.vue'
import ConsumerView from '@module/customer_frontend/views/_consumer.vue'

const routes : Array<RouteRecordRaw> =
  [
    {
      path: '',
      name: 'me_index',
      component: IndexView
    },
    {
      path: 'profile',
      name: 'me_profile',
      component: ProfileView
    },
    {
      path: 'consumer/:consumer',
      name: 'me_consumer',
      component: ConsumerView
    },
    {
      path: 'account',
      name: 'me_account',
      component: AccountView,
      children: [
        {
          path: '',
          name: 'me_account_general',
          component: AccountGeneralView
        },
        {
          path: 'transactions',
          name: 'me_account_transactions',
          component: AccountTransactionsView
        }
      ]
    }
  ]

const record : RouteRecordRaw = {
  name: 'me_root',
  path: '/me',
  meta: {
    requiresAuth: true
  },
  children: routes,
  component: MeLayout
}

export default (router: Router) => {
  router.addRoute(record)
}
