import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import Client from '@/api/axios'
import Routing from '@/symfony'
import { PriceSchedule } from '@interface/entity/priceschedule'
import { PriceScheduleCreate, PriceSchedulePatch } from '@interface/api/PriceSchedule'

export interface PriceScheduleApi{
  create: (schedule: PriceScheduleCreate) => Promise<AxiosPromise>
  update: (id: string, schedule: PriceSchedulePatch) => Promise<AxiosPromise>
}

const resolveClient = (schedule: PriceScheduleCreate) => {
  schedule.client = Routing.generate('api_clients_get_item', { id: schedule.client })
}

export const scheduleApi: PriceScheduleApi = {
  async create (schedule: PriceScheduleCreate): Promise<AxiosPromise> {
    resolveClient(schedule)
    return Client.post(Routing.generate('api_price_schedules_post_collection'), schedule)
  },
  async update (id, schedule: PriceSchedulePatch): Promise<AxiosPromise> {
    resolveClient(schedule)
    return Client.patch(Routing.generate('api_price_schedules_patch_item', { id: id }), schedule, {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
  }
}
