<template>
    <div class="flex flex-col space-y-4">
      <div class="card bg-base-100 shadow shadow-xl">
        <div class="card-body">
          <base-paginator url="/api/clients" :show-meta="false">
            <template v-slot="{ list, loading }">
              <div class="flex mb-4">
                <base-paginator-search class="w-52"></base-paginator-search>
                <div class="flex-1"></div>
                <router-link class="btn btn-success" :to="{name: 'client_new'}">
                  <PlusIcon class="w-6 h-6"></PlusIcon> Schule hinzufügen
                </router-link>
              </div>
              <table class="table w-full">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="client.id" v-for="client in list">
                    <template v-if="loading">
                      <td>
                        <div class="rounded-xl animate-pulse bg-neutral h-2 w-full"></div>
                      </td>
                      <td>
                        <div class="rounded-xl animate-pulse bg-neutral h-2 w-full"></div>
                      </td>
                      <td>
                        <div class="rounded-xl animate-pulse bg-neutral h-2 w-full"></div>
                      </td>
                    </template>
                    <template v-else>
                      <td>{{ client.name }}</td>
                      <td>
                        <strong>{{client.street}}</strong> <br>
                        {{ client.zip }} {{client.city}}
                      </td>
                      <td>
                        <div class="tooltip float-right" data-tip="Bearbeiten">
                          <router-link class="btn btn-outline btn-circle btn-primary" :to="{name: 'client_detail', params: {id: client.id}}">
                            <EditIcon class="w-6 h-6"></EditIcon>
                          </router-link>
                        </div>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </template>
          </base-paginator>
        </div>
      </div>

    </div>
</template>
<script>
export default {
  name: 'client-index',
  components: {},

  i18n: {
    messages: {
      'de-DE': {
        sortingOptions: {
          none: 'Keine Sortierung',
          placeholder: 'Sortieren Sie nach:',
          name: 'Schulname',
          zip: 'Postleitzahl',
          city: 'Stadt'
        }
      }
    }
  }
}
</script>
