
import { defineComponent, PropType, toRef } from 'vue'
import { useRouter } from 'vue-router'

interface BreadcrumbRecord {
  icon?: string,
  label: string,
  routeName: string
}

export default defineComponent({
  name: 'BreadcrumbNav',
  props: {
    routeNames: {
      required: true,
      type: Array as PropType<string[]>
    }
  },
  setup (props) {
    const router = useRouter()
    const routeNames = toRef(props, 'routeNames')
    const routes: Array<BreadcrumbRecord> = routeNames.value.map((routeName: string) => {
      const route = router.resolve({ name: routeName })
      const record: BreadcrumbRecord = {
        routeName: routeName,
        label: routeName
      }
      if (route.meta.nav) {
        record.label = route.meta.nav.label ?? routeName
        record.icon = route.meta.nav.icon ?? ''
      }
      return record
    })
    return {
      routes
    }
  }
})
