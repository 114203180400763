import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import Client from '@/api/axios'
import Routing from '@/symfony'
import { Customer } from '@interface/entity/customer'

export interface BankStatementApi{
  all: () => Promise<AxiosPromise>
  get: (id: Number) => Promise<AxiosPromise>,
  assign: (id: Number, customer: string) => Promise<AxiosPromise>,
  ignore: (id: Number) => Promise<AxiosPromise>
}

export const statement: BankStatementApi = {
  async all (): Promise<AxiosPromise> {
    return Client.get(Routing.generate('api_transfer_hub_statements_get_collection'))
  },
  async get (id: Number): Promise<AxiosPromise> {
    return Client.get(Routing.generate('api_transfer_hub_statements_get_item', { id: id }))
  },
  async assign (id: Number, customer: string): Promise<AxiosPromise> {
    return Client.patch(
      Routing.generate('api_transfer_hub_statements_assign_item', { id: id }),
      {
        customer: Routing.generate('api_customers_get_item', { id: customer })
      },
      {
        headers: {
          'Content-Type': 'application/merge-patch+json'
        }
      }
    )
  },
  async ignore (id: Number): Promise<AxiosPromise> {
    return Client.patch(Routing.generate('api_transfer_hub_statements_assign_item', { id: id }), {
      ignored: true
    },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
  }
}
