
import Cleave from 'cleave.js'
import { defineComponent, PropType } from 'vue'
import { EducationCard } from '@interface/entity/educationcard'

interface Data {
  cleave: null | Cleave
}

export default defineComponent({
  props: {
    cards: {
      required: true,
      type: Object as PropType<Array<EducationCard>>
    }
  },
  data (): Data {
    return {
      cleave: null
    }
  },
  mounted () {
    this.cleave = new Cleave(this.$refs.input as HTMLElement, {
      blocks: [3, 3, 3, 3]
    })
  },
  computed: {
    activeCards (): Array<EducationCard> {
      return this.cards.filter((card: EducationCard) => card.active)
    },
    educationCard (): EducationCard {
      return this.activeCards[0]
    },
    cardNumber (): String {
      if (!this.cleave) {
        return ''
      }
      return this.cleave.getFormattedValue()
    },
    expiration () {
      return [
        new Intl.DateTimeFormat('de-DE', { month: '2-digit' }).format(this.validTill),
        new Intl.DateTimeFormat('de-DE', { year: 'numeric' }).format(this.validTill)
      ].join('/')
    },
    validTill () {
      return Date.parse(this.educationCard.validTill)
    }
  }
})
