import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import Client from '@/api/axios'
import Routing from '@/symfony'
import { ClientPatch } from '@interface/api/Client'

export interface ClientApi{
  frontend: () => Promise<AxiosPromise>
  all: () => Promise<AxiosPromise>
  get: (id: string) => Promise<AxiosPromise>
  create: (client: any) => Promise<AxiosPromise>
  update: (id: string, client: ClientPatch) => Promise<AxiosPromise>
  getUri: (id: string) => string
}

export const client: ClientApi = {
  async all (): Promise<AxiosPromise> {
    return Client.get(Routing.generate('api_clients_get_collection'))
  },
  async frontend (): Promise<AxiosPromise> {
    return Client.get(Routing.generate('payload'))
  },
  async get (id: string): Promise<AxiosPromise> {
    return Client.get(Routing.generate('api_clients_get_item', { id: id }))
  },
  async create (client: any): Promise<AxiosPromise> {
    return Client.post(Routing.generate('api_clients_post_collection'), client)
  },
  async update (id, client: ClientPatch): Promise<AxiosPromise> {
    return Client.patch(Routing.generate('api_clients_patch_item', { id: id }), client, {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
  },
  getUri (id:string) {
    const url = Routing.generate('api_clients_get_item', { id }, false)
    const baseUrl: string = Client.defaults.baseURL ?? ''
    return url.replace(baseUrl, '')
  }
}
