import { defineComponent } from 'vue'
import type { PropType } from 'vue'

export default defineComponent({
  props: {
    modelValue: Object as PropType<any>
  },
  computed: {
    vModel: {
      get ():any {
        return this.modelValue
      },
      set (value: any) {
        this.$emit('update:modelValue', value)
      }
    }
  }
})
