import { createApp } from 'vue'
import App from './App.vue'
import { register } from './components'
import './assets/css/Tailwind.css'
import './assets/css/Main.css'
import 'v-calendar/dist/style.css'

import './registerServiceWorker'
import emitter from './emitter'
// @ts-ignore
import Multiselect from 'vue-multiselect'
// @ts-ignore
import Notifications from '@kyvg/vue3-notification'
import Modal from '@plugin/modal'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import router from './router'
import store from './store'
import '@fontsource/ubuntu'
import '@fontsource/ubuntu/300.css'
import '@fontsource/ubuntu/500.css'
import '@fontsource/ubuntu/700.css'
import '@fontsource/ubuntu/300-italic.css'
import '@fontsource/ubuntu/500-italic.css'
import '@fontsource/ubuntu/700-italic.css'
import '@/setup/yup'
import '@/setup/rules'
import i18n from '@/setup/i18n'
import { registerModules } from '@/register-modules'
import customer from '@module/customer_backend'
import me from '@module/customer_frontend'
import user from '@module/user_backend'
import client from '@module/client_backend'
import accounting from '@module/accounting_backend'
import reports from '@module/reports_backend'

registerModules({
  customer,
  user,
  me,
  client,
  accounting,
  reports
})

const app = createApp(App).use(store).use(router)
app.use(Notifications)
app.use(i18n)
app.use(Modal)
app.use(autoAnimatePlugin)
register(app)
// @ts-ignore
app.component('vue-multiselect', Multiselect)

app.config.globalProperties.emitter = emitter
app.config.unwrapInjectedRef = true

app.mount('#app')
