<template>
  <div class="min-h-screen min-w-screen p-4 flex flex-col justify-center items-center  overflow-none relative">
    <img src="https://cultina.de/wp-content/uploads/couscous-and-vegetables-bowl-trend-food-PHQLURJ.jpg" class="background w-full h-full" />
    <div class="center" v-auto-animate>
      <div class="grid grid-cols-1 grid-rows-1 gap-0 overflow-y-auto" v-if="loaded">
        <div class="bg-base-100 card overflow-hidden mx-8 translate-y-2">
          <div class="bg-opacity-70 h-full px-4 pt-4 pb-6 bg-neutral text-neutral-content" >
            <router-view name="header"></router-view>
          </div>
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'layout-login',
  data () {
    return {
      loaded: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.loaded = true
    }, 200)
  }
}
</script>
<style lang="postcss" scoped>
  .background{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .center{
    max-width: 700px;
    width: 100%;
  }
</style>
