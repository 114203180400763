import { RouteRecordRaw } from 'vue-router'
import store from '@/store'
import { Customer } from '@interface/entity/customer'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      nav: {
        label: 'Dashboard'
      }
    },
    redirect (to) {
      // @ts-ignore
      const user = store.getters['login/user']
      if (user) {
        return { name: 'customer_index' }
      }
      const customer = store.getters['login/customer']
      if (customer) {
        return { name: 'me_index' }
      }
      return { name: 'login_index' }
    }
  },
  {
    path: '/registrierung',
    redirect: {
      name: 'login_register'
    }
  },
  {
    path: '/login/reset_password/:token',
    redirect (to) {
      // @ts-ignore
      sessionStorage.setItem('reset_token', to.params.token)
      return { name: 'login_password_forget' }
    }
  }
]

export default routes
