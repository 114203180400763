
import { defineComponent, PropType } from 'vue'
import { Form } from 'vee-validate'
import { Client } from '@interface/entity/client'
import { Customer } from '@interface/entity/customer'
import { Consumer } from '@interface/entity/consumer'
import { PriceSchedule } from '@interface/entity/priceschedule'
import { mapGetters } from 'vuex'

interface Data {
  priceSchedule: PriceSchedule |null
  amount: number,
  loading: boolean,
}
export default defineComponent({
  name: 'SubscriptionForm',
  components: {
    Form
  },
  data (): Data {
    return {
      priceSchedule: null,
      amount: 1,
      loading: false
    }
  },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true
    },
    consumer: {
      type: Object as PropType<Consumer>,
      required: true
    },
    submit: {
      type: Function,
      required: true
    }
  },
  mounted () {
    this.priceSchedule = this.consumerPriceSchedule
    if (this.consumer.subscription) {
      this.amount = this.consumer.subscription.amount
    }
  },
  methods: {
    async handleSubmit (values:any) {
      if (!this.priceSchedule) { return }
      this.loading = true
      await this.submit({
        amount: this.amount,
        priceSchedule: this.priceSchedule.id,
        customer: this.customer.id,
        consumer: this.consumer.id
      })
      this.loading = false
    }
  },
  computed: {
    ...mapGetters('cache', ['clients', 'apiClients']),
    isPriceSelectDisabled () {
      if (!this.consumer.subscription) {
        return false
      }
      return this.consumer.subscription.meta.active
    },
    client (): Client {
      if (typeof this.consumer.client === 'string') {
        const client: string = this.consumer.client as unknown as string
        return this.apiClients[client]
      }
      return this.clients[this.consumer.client.id]
    },
    isSameAmount (): boolean {
      if (!this.consumerPriceSchedule) {
        return false
      }
      return this.amount === this.consumer.subscription.amount
    },
    consumerPriceSchedule (): PriceSchedule {
      return Object.values(this.client.priceSchedule).filter((priceSchedule: PriceSchedule) => {
        if (!this.consumer.subscription) { return false }
        if (!this.consumer.subscription.priceSchedule) { return false }
        return this.consumer.subscription.priceSchedule.includes(priceSchedule.id as unknown as string)
      })[0]
    }
  }
})
