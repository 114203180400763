/**
 * @see https://github.com/gka/palettes/blob/master/src/PalettePreview.svelte
 *
 */
import { computed, ref, toRef } from 'vue'
import chroma, { Color, Scale } from 'chroma-js'
// import { colorBlindSim } from './colorBlind'
import _range from 'lodash.range'

export interface PaletteOptions {
  left: Array<string>,
  right: Array<string>,
  amount?: number,
  diverging?: boolean
  bezier?: boolean
  correctLightness?: boolean
}
export const useColorPalette = (options: PaletteOptions) => {
  const colors = toRef(options, 'left')
  const colors2 = toRef(options, 'right')
  const amount = toRef(options, 'amount', colors.value.length + colors2.value.length)
  const numColors = computed(() => amount.value)
  const diverging = toRef(options, 'diverging', !!options.diverging)
  const bezier = toRef(options, 'bezier', !!options.bezier)
  const correctLightness = toRef(options, 'correctLightness', !!options.correctLightness)
  const autoGradient = (color: string, numColors: number): Color[] => {
    const lab = chroma(color).lab()
    const lRange = 100 * (0.95 - 1 / numColors)
    const lStep = lRange / (numColors - 1)
    const lStart = (100 - lRange) * 0.5
    const range = _range(lStart, lStart + numColors * lStep, lStep)
    let offset = 0
    if (!diverging.value) {
      offset = 9999
      for (let i = 0; i < numColors; i++) {
        const diff = lab[0] - range[i]
        if (Math.abs(diff) < Math.abs(offset)) {
          offset = diff
        }
      }
    }
    return range.map((l: number) => chroma.lab(l + offset, lab[1], lab[2]))
  }
  function autoColors (color: string, numColors: number, reverse = false) {
    if (diverging.value) {
      const colors = autoGradient(color, 3).concat(chroma('#f5f5f5'))
      if (reverse) colors.reverse()
      return colors
    } else {
      return autoGradient(color, numColors)
    }
  }

  const generator = (amount: number) => {
    const num = amount
    const even = num % 2 === 0

    const numColorsLeft = diverging.value ? Math.ceil(num / 2) + (even ? 1 : 0) : num
    const numColorsRight = diverging.value ? Math.ceil(num / 2) + (even ? 1 : 0) : 0

    const genColors = colors.value.length !== 1 ? colors.value : autoColors(colors.value[0], numColorsLeft)
    const genColors2 = colors2.value.length !== 1 ? colors2.value : autoColors(colors2.value[0], numColorsRight, true)

    // @ts-ignore
    const stepsLeft = colors.value.length ? chroma.scale(bezier.value && colors.value.length > 1 ? chroma.bezier(genColors) : genColors).correctLightness(correctLightness.value).colors(numColorsLeft) : []

    // @ts-ignore
    const stepsRight = diverging.value && colors2.value.length ? chroma.scale(bezier.value && colors2.value.length > 1 ? chroma.bezier(genColors2) : genColors2).correctLightness(correctLightness.value).colors(numColorsRight) : []

    const stepsOverall = (even && diverging.value ? stepsLeft.slice(0, stepsLeft.length - 1) : stepsLeft)
    if (amount < 2) {
      return stepsOverall
    }
    return stepsOverall.concat(stepsRight.slice(1))
  }

  return {
    palette: computed(() => generator(numColors.value)),
    generate: generator
  }
}
