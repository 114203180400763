import { RouteRecordRaw, Router } from 'vue-router'
import DefaultLayout from '@layout/default.vue'
import IndexView from '@module/customer_backend/views/index.vue'
import DetailView from '@module/customer_backend/views/_id.vue'
import ConsumerView from '@module/customer_backend/views/_consumer.vue'

const routes : Array<RouteRecordRaw> =
  [
    {
      path: '',
      name: 'customer_index',
      component: IndexView
    },
    {
      path: ':id',
      name: 'customer_detail',
      component: DetailView
    },
    {
      path: 'consumer/:consumer',
      name: 'customer_consumer',
      component: ConsumerView
    }
  ]

const record : RouteRecordRaw = {
  path: 'customer/',
  children: routes,
  component: DefaultLayout
}

export default (router: Router) => {
  router.addRoute('backend_root', record)
}
