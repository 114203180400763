<template>
  <div class="dropdown dropdown-end relative" :class="{'dropdown-open': isOpen}">
    <slot name="toggle" :toggle="toggle" :open="open" :close="close"></slot>
    <div class="dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box absolute w-auto">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'drop-down',
  data () {
    return {
      isOpen: false
    }
  },
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'bottom'
    }
  },
  beforeMount () {
    this.isOpen = this.opened
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
      this.$emit('toggle', { isOpen: this.isOpen })
    },
    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    }
  },
  watch: {
    opened (newValue) {
      this.isOpen = newValue
    }
  }
}
</script>
