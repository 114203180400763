import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_select = _resolveComponent("base-select")!

  return (_openBlock(), _createBlock(_component_base_select, {
    label: "Klasse",
    options: _ctx.alphabet,
    modelValue: _ctx.vModel,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vModel) = $event))
  }, null, 8, ["options", "modelValue"]))
}