<template>
  <date-picker locale="de" v-bind="attributes" mode="date" v-model="vModel" class="flex-1">
    <template v-slot="{ togglePopover, inputEvents, inputValue }">
      <div class="form-control">
        <label class="label" v-if="label" :for="id">
          <span class="label-text" :class="{'text-opacity-50': disabled}" >{{ label }}</span>
        </label>
        <Field
          :name="name"
          v-model="vModel"
          :rules="rules"
          v-slot="{ field, errorMessage, errors }"
        >
          <div class="input-group">
            <span class="text-center cursor-pointer"
              :class="{ 'bg-error text-error-content': errors.length > 0, 'bg-opacity-50 text-opacity-50': disabled  }"
                  @click="togglePopover">
              <component :is="icon" class="w-6 h-6" :class="{'opacity-50': disabled}" />
            </span>
            <input
              v-bind="field"
              class="input input-bordered w-full"
              :disabled="disabled"
              readonly
              :class="{ 'input-error': errors.length > 0 }"
              :value="inputValue"
              v-on="inputEvents"
            />
            <span v-if="!!vModel && !disabled" class="cursor-pointer hover:bg-primary hover:text-base-100" @click="vModel = null">
              <XIcon class="w-6 h-6" />
            </span>
          </div>
          <label class="label" v-show="!!errorMessage">
            <span class="label-text-alt text-error">{{ errorMessage }}</span>
          </label>
        </Field>
      </div>
    </template>
  </date-picker>
</template>

<script>
import BaseInput from './BaseInput'
import { DatePicker } from 'v-calendar'
import { v4 as uuidv4 } from 'uuid'
import { Field } from 'vee-validate'

export default {
  name: 'base-date-picker',
  extends: BaseInput,
  components: { DatePicker, Field },
  props: {
    modelValue: {
      type: [Date, null]
    },
    icon: {
      default: 'CalendarIcon'
    },
    timeTo: {
      type: String,
      default: 'beginning',
      validator: (value) => ['end', 'beginning'].includes(value)
    }
  },
  setup () {
    return {
      id: uuidv4()
    }
  },
  computed: {
    attributes () {
      return {
        modelConfig: {
          timeAdjust: this.timeTo === 'beginning' ? '00:00:01' : '23:59:59'
        },
        ...this.$attrs
      }
    }
  }
}
</script>

<style scoped>

</style>
