
import { computed, defineComponent, ref } from 'vue'
import { Line } from 'vue-chartjs'
import Api from '@/api/'
import Axios from '@/api/axios'

import {
  Chart as ChartJS,
  CategoryScale,
  Colors,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend, ChartDataset, TooltipItem, ChartData
} from 'chart.js'
import { useRoute } from 'vue-router'
import { AxiosResponse } from 'axios'
import HyperspaceLoader from '@/components/loader/HyperspaceLoader.vue'
import ColoredTab from '@module/reports_backend/components/ColoredTab.vue'
import { useColorPalette } from '@/composables/colorPalette'
import { Revenue, Response, RevenueByYear } from '@module/reports_backend/interface/nfc_chip_revenue'
import MonthCard from '@module/reports_backend/components/MonthCard.vue'

ChartJS.register(
  Colors,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)
export default defineComponent({
  name: 'report-mensa-chip',
  components: { MonthCard, HyperspaceLoader, Line, ColoredTab },
  setup () {
    const { generate } = useColorPalette({
      diverging: true,
      bezier: true,
      correctLightness: true,
      left: ['#00429d', '#96ffea', 'lightyellow'],
      right: ['lightyellow', '#ff005e', '#93003a']
    })

    const showDetails = ref(false)
    const revenue = ref<Response>([])
    const availableYears = computed(() => {
      return revenue.value.reduce<Array<string>>((acc, record: Revenue) => {
        if (!acc.includes(record.year)) {
          acc.push(record.year)
        }
        return acc
      }, []).sort((a, b) => parseInt(a) > parseInt(b) ? -1 : 1)
    })
    const colorMap = computed(() => {
      const colors: Record<string, string> = {}
      const palette = generate(availableYears.value.length)
      availableYears.value.forEach((value:string, index) => {
        colors[value] = palette[index]
      })
      return colors
    })
    const years = ref<Array<number>>([new Date().getFullYear()])
    const loading = ref(false)
    const route = useRoute()
    // console.log(colors.value, generate(3))#
    const fetch = async () => {
      loading.value = true
      const url = Api.createEndPointUrl('api_report_gather', { key: route.name })
      const response : AxiosResponse<Response> = await Axios.get(url)
      revenue.value = response.data
      loading.value = false
    }
    fetch()
    return {
      loading,
      showDetails,
      selectedYears: years,
      revenue,
      colors: colorMap,
      years: availableYears
    }
  },
  methods: {
    selectYear (year: string) {
      const index = this.selectedYears.findIndex((lYear) => parseInt(year) === lYear)
      if (index === -1) {
        this.selectedYears.push(parseInt(year))
      } else {
        this.selectedYears.splice(index, 1)
      }
      if (this.selectedYears.length > 1) {
        this.showDetails = false
      }
      if (this.selectedYears.length === 0) {
        this.selectedYears.push(new Date().getFullYear())
      }
      // console.log(this.colors)
    }
  },
  computed: {
    revenueByYear () {
      const byYear: Record<string, Array<Revenue>> = {}
      this.revenue.forEach((data: Revenue) => {
        if (!byYear[data.year]) {
          byYear[data.year] = []
        }

        byYear[data.year].push(data)
      })
      return byYear
    },
    selectedRevenueByYear () {
      const revenue: Array<RevenueByYear> = []
      const years = Object.keys(this.revenueByYear).filter((year) => this.selectedYears.length ? this.selectedYears.includes(parseInt(year)) : true)
      years.forEach((key) => {
        const revenueInYear = this.revenueByYear[key]
        revenue.push({
          color: this.colors[key],
          year: key.toString(),
          data: Array.from({ length: this.months.length }, (_, i) => {
            const index = revenueInYear.findIndex((record) => i + 1 === parseInt(record.month))
            if (index !== -1) {
              return revenueInYear[index]
            }
            return {
              month: (i + 1).toString(),
              year: key.toString(),
              revenue: 0,
              sold: 0,
              refunded: 0
            }
          })
        })
      })
      return revenue.sort((a, b) => parseInt(a.year) > parseInt(b.year) ? -1 : 1)
    },
    datasets (): Array<ChartDataset> {
      const datasets: Array<ChartDataset> = []
      this.selectedRevenueByYear.forEach((record) => {
        datasets.push({
          backgroundColor: record.color,
          borderColor: record.color,
          label: record.year,
          pointStyle: 'rectRounded',
          pointRadius: 10,
          pointHoverRadius: 15,
          // pointHoverBackgroundColor: '#c00069',
          data: record.data.map(v => v.revenue)
        })
      })
      return datasets
    },
    chart (): any {
      const data : ChartData = {
        labels: this.months,
        datasets: this.datasets
      }
      const options = {
        // interaction: {
        //   intersect: false,
        //   mode: 'index'
        // },
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value: number) {
                return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function (context: TooltipItem<any>) {
                let label = context.dataset.label || ''

                if (label) {
                  label += ': '
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(context.parsed.y)
                }
                return label
              }
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
      return {
        data,
        options
      }
    },
    months (): Array<string> {
      const year = new Date().getFullYear()
      return Array.from({ length: 12 }, (_v, i: number) => new Date(year, i).toLocaleDateString('de-DE', { month: 'long' }))
    }
  }
})
