import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import Client from '@/api/axios'
import Routing from '@/symfony'

interface CreateNfcTokenRequest {
  'tokenId': string,
  'state': string,
  'chipNumber': string,
  'consumer': string
}

export interface NfcTokenApi{
  activate: (id: string) => Promise<AxiosPromise>
  deactivate: (id: string) => Promise<AxiosPromise>
  block: (id: string) => Promise<AxiosPromise>
  create: (data: CreateNfcTokenRequest) => Promise<AxiosPromise>
}

export const nfcTokenApi: NfcTokenApi = {
  async activate (id): Promise<AxiosPromise> {
    return Client.patch(Routing.generate('api_nfc_tokens_update_item', { id }), {
      state: 'activated'
    }, {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
  },
  async deactivate (id): Promise<AxiosPromise> {
    return Client.patch(Routing.generate('api_nfc_tokens_update_item', { id }), {
      state: 'deactivated'
    }, {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
  },
  async block (id): Promise<AxiosPromise> {
    return Client.patch(Routing.generate('api_nfc_tokens_update_item', { id }), {
      state: 'blocked'
    }, {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
  },
  async create (data: CreateNfcTokenRequest): Promise<AxiosPromise> {
    return Client.post(Routing.generate('api_nfc_tokens_post_collection'), {
      ...data,
      consumer: Routing.generate('api_consumers_get_item', { id: data.consumer })
    })
  }
}
