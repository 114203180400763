<template>
  <transition
    enter-active-class="transform-gpu"
    enter-from-class="opacity-0 -translate-x-full"
    enter-to-class="opacity-100 translate-x-0"
    leave-active-class="absolute transform-gpu"
    leave-from-class="opacity-100 translate-x-0"
    leave-to-class="opacity-0 -translate-x-full"
    tag="div"
    @before-leave="beforeLeave"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'transition-swipe',
  methods: {
    beforeLeave (el) {
      const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)
      el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`
      el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`
      el.style.width = width
      el.style.height = height
    }
  }
}
</script>
