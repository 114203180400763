
import { defineComponent } from 'vue'
import VModel from '@/mixins/vModel'
import { Field } from 'vee-validate'
export default defineComponent({
  name: 'step-select',
  mixins: [VModel],
  components: {
    Field
  },
  methods: {
    setAmount (amount:number):void {
      if (amount > this.activeSteps) { return }
      this.vModel = amount
    }
  },
  computed: {
    activeSteps (): number {
      return this.enabled === -1 ? this.max : this.enabled
    }
  },
  setup () {
    return {
      // @ts-ignore
      id: window.crypto.randomUUID()
    }
  },
  props: {
    modelValue: {
      type: Number
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    max: {
      type: Number,
      default: 7
    },
    enabled: {
      type: Number,
      default: -1
    }
  }
})
