import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { CustomerUpdateRequest, TransactionData } from '@interface/api/Customer'
import Client from '@/api/axios'
import Routing from '@/symfony'
import { Wallet } from '@interface/entity/wallet'

export interface CustomerApi{
  get: (id: string) => Promise<AxiosPromise>
  update: (id: string, data: CustomerUpdateRequest) => Promise<AxiosPromise>,
  sendPasswortResetMail: (id: string) => Promise<AxiosPromise>,
  createTransaction: (id: string, transaction: TransactionData) => Promise<AxiosPromise>,
  getWalletUri: (wallet: Wallet | null) => string
}

export const customerApi: CustomerApi = {
  async get (id): Promise<AxiosPromise> {
    return Client.get(Routing.generate('api_customers_get_item', { id: id }))
  },
  async update (id, data): Promise<AxiosPromise> {
    return Client.patch(Routing.generate('api_customers_update_item', { id: id }), data, {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
  },
  async sendPasswortResetMail (id): Promise<AxiosPromise> {
    return Client.post(Routing.generate('api_admin_customer_password_reset', { customer: id }))
  },
  async createTransaction (id: string, transaction: TransactionData): Promise<AxiosPromise> {
    return Client.post(Routing.generate('api_transaction_customer', { customer: id }), transaction, {
    })
  },

  getWalletUri (wallet: Wallet | null) : string {
    if (!wallet) {
      return ''
    }
    const url = Routing.generate('api_wallets_get_item', { id: wallet.id }, false)
    const baseUrl: string = Client.defaults.baseURL ?? ''
    return url.replace(baseUrl, '')
  }

}
