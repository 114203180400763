import { MutationTree } from 'vuex'
import { CacheState } from './interfaces'
import { Client } from '@interface/entity/client'

export enum CacheMutations {
  SET_CLIENTS = 'client_set',
  SET_REPORTS = 'reports_set',
}

export const mutations: MutationTree<CacheState> = {
  [CacheMutations.SET_CLIENTS] (state, payload: Array<Client>) {
    payload.forEach((element: Client) => {
      state.clients[element.id] = element
    })
  },
  [CacheMutations.SET_REPORTS] (state, payload: Array<any>) {
    state.reports = payload
  }
}
