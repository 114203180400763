<template>
  <div class="min-h-screen min-w-screen p-4 flex flex-col justify-center items-center  overflow-none relative" style="background: #eeeeee">
    <div class="center bg-base-100 p-5 flex-0 overflow-hidden rounded-box relative">
      <a href="#" @click="back" class="p-3 w-full block bg-info text-white absolute left-0 right-0 top-0 flex flex-row space-x-2">
        <ArrowNarrowLeftIcon class="w-6 h-6"></ArrowNarrowLeftIcon> <span>Zurück</span>
      </a>
      <div class="mt-12">
        <router-view />
      </div>
    </div>
    <div class="login_footer mt-2 text-center">
      <router-link :to="{name: 'legal_imprint'}">
        Impressum
      </router-link>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <router-link :to="{name: 'legal_agb'}">
        ABG
      </router-link>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <router-link :to="{name: 'legal_gdpr'}">
        Datenschutz
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'layout-legal',
  methods: {
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="postcss" scoped>
  .background{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .center{
    max-width: 700px;
    width: 100%;
  }
  .login_footer a {
    color: #8B8B8B;
    text-decoration: none;
  }
</style>
