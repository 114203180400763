import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["btn-group", {'cursor-not-allowed pointer-events-none opacity-40': _ctx.disabled}])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysOfOperation, (value, key) => {
      return (_openBlock(), _createElementBlock("button", {
        type: "button",
        class: _normalizeClass(["btn flex-grow", {'btn-outline': _ctx.disabled, 'btn-success': _ctx.vModel[value.key]}]),
        onClick: ($event: any) => (_ctx.select(value.key)),
        key: key
      }, _toDisplayString(value.name), 11, _hoisted_1))
    }), 128))
  ], 2))
}