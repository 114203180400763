import { Module } from 'vuex'
import { RootState } from '../interfaces'
import { MeState } from './interfaces'
import { getters } from './getters'
import { mutations } from './mutations'

const state: MeState = {
  customer: null,
  activeConsumer: null
}

export const me: Module<MeState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations
}
