
import { computed, defineComponent, toRef } from 'vue'
import chroma from 'chroma-js'
const MIN_CONTRAST_RATIO = 7
const WHITE = chroma('white')
const BLACK = chroma('black')
export default defineComponent({
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const selected = toRef(props, 'selected')
    const isSelected = computed(() => selected.value)
    const color = toRef(props, 'color')
    const styles = computed(() => {
      const styles : any = {}
      if (isSelected.value) {
        const { text } = generateColorPair(color.value)
        styles['background-color'] = color.value
        styles.color = text
      }
      return styles
    })
    function generateColorPair (color: string) {
      let text = null
      const bg = chroma(color)
      const contrastWithWhite = chroma.contrast(bg, WHITE)
      // const contrastWithBlack = chroma.contrast(bg, BLACK)
      if (contrastWithWhite >= MIN_CONTRAST_RATIO) {
        text = WHITE
      } else {
        text = BLACK
      }
      return { bg: bg.hex(), text: text.hex() }
    }
    return {
      styles
    }
  }
})
