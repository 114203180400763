
import { useForm } from 'vee-validate'
import { defineComponent, ref } from 'vue'
import * as yup from 'yup'
import { notify } from '@kyvg/vue3-notification'
import Routing from '@/symfony'
import axios from '@/api/axios'
import { Notification } from '@interface/enums/Notification'
interface PasswordForm {
  newPassword: string,
  currentPassword: string,
  confirm: string
}
export default defineComponent({
  setup () {
    const { handleSubmit, isSubmitting, meta, resetForm } = useForm<PasswordForm>({
      validationSchema: yup.object().shape({
        newPassword: yup.string().required(),
        currentPassword: yup.string().required(),
        confirm: yup.string().required().oneOf([yup.ref('newPassword'), null], 'Die Passwörter müssen übereinstimmen.')
      })
    })
    const passwordStrength = ref(0)

    const onStrength = (strength: number) => {
      passwordStrength.value = strength
    }

    const onSubmit = handleSubmit(async (data) => {
      if (data.newPassword !== data.confirm) {
        console.log('SOMETHING does not add up')
        return
      }
      try {
        const url = Routing.generate('api_profile_customer_reset_password')
        await axios.post(url, {
          newPassword: data.newPassword,
          currentPassword: data.currentPassword
        })
        notify({
          title: 'Passwort wurde erfolgreich geändert.',
          text: '',
          type: Notification.SUCCESS
        })
        resetForm()
      } catch (e: any) {
        notify({
          title: e.response.data.message,
          text: 'Bitte überprüfen Sie ihr aktuelles Passwort.',
          type: Notification.ERROR
        })
      }
    })
    return {
      onSubmit,
      isSubmitting,
      onStrength,
      meta
    }
  },
  data () {
    return {
      currentPassword: '',
      passwordStrength: 0
    }
  },
  methods: {
    strength (strength: any) {
      this.passwordStrength = strength
    }
  }
})
