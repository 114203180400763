<template>
  <slot :days="count"><span>{{count}}</span></slot>
</template>

<script>
export default {
  name: 'base-day-count',
  props: {
    from: {
      type: [Date, String],
      default: () => new Date()
    },
    to: {
      type: [Date, String],
      required: true
    }
  },
  computed: {
    dateTo () {
      return typeof this.to === 'string' ? new Date(this.to) : this.to
    },
    dateFrom () {
      return typeof this.from === 'string' ? new Date(this.from) : this.from
    },
    count () {
      const difference = this.dateTo.getTime() - this.dateFrom.getTime()
      const result = Math.ceil(difference / (1000 * 3600 * 24))
      return result > -1 ? result : result * -1
    }
  }
}
</script>

<style scoped>

</style>
