import { NavigationGuardWithThis } from 'vue-router'
import { notify } from '@kyvg/vue3-notification'
import { Notification } from '@interface/enums/Notification'
import TokenStorage from '@/services/TokenStorage'

export const authenticationRequired: NavigationGuardWithThis<undefined> = async (to, from, next) => {
  const routeNeedsAuthentication = to.matched.some(record => !!record.meta.requiresAuth)
  if (routeNeedsAuthentication) {
    if (!TokenStorage.hasToken() && !TokenStorage.hasRefreshToken()) {
      notify({
        title: 'Login erforderlich',
        text: 'Bitte loggen Sie sich ein.',
        type: Notification.ERROR
      })
      next({
        name: 'login_index',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
}
