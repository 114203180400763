
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
interface Data {
  viewOrder: Array<string>,
  transitionName: string
}
export default defineComponent({
  name: 'me-account',
  data ():Data {
    return {
      viewOrder: [
        'me_account_general',
        'me_account_transactions'
      ],
      transitionName: 'slide_right'
    }
  },
  computed: {
    ...mapGetters('me', {
      customer: 'instance',
      kdNumbers: 'kdNumbers',
      walletBalance: 'balance'
    })
  },
  watch: {
    $route (to, from) {
      const move = this.viewOrder.indexOf(to.name) - this.viewOrder.indexOf(from.name)
      this.transitionName = move < 0 ? 'slide_left' : 'slide_right'
    }
  }
})
