
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'me-account-general',
  computed: {
    ...mapGetters('me', {
      customer: 'instance',
      consumers: 'consumers',
      kdNumbers: 'kdNumbers',
      walletBalance: 'balance'
    })
  }
})
