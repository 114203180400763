
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'me-account-transactions',
  computed: {
    ...mapGetters('me', {
      customer: 'instance',
      kdNumbers: 'kdNumbers',
      walletBalance: 'balance'
    })
  }

})
