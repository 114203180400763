<template>
    <div class="card bg-base-100 shadow-xl" v-if="ready">
        <div class="card-body">
            <div class="flex items-center">
              <div class="flex flex-col">
                <span class="badge mr-2">Chip Nr. {{consumer.nfcToken.tokenId}}</span>
                <span v-if="consumer.educationCards && consumer.educationCards.length > 0 && consumer.educationCards[0].active" class="badge badge-info mt-2" :class="[consumer.educationCards[0].verified ? 'badge-info' : 'badge-warning']"> <CheckIcon v-if="consumer.educationCards[0].active" />  <AlertCircleIcon v-if="!consumer.educationCards[0].verified" /> Bildungskarte ({{consumer.educationCards[0].cardNumber}})</span>
              </div>
                <div class="justify-self-end ml-auto flex">
                    <div class="tooltip" data-tip="Speichern">
                        <button @click="saveConsumer" class="btn btn-circle btn-success mr-2 transition-transform" v-if="editConsumer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="tooltip mr-2" :data-tip="[editConsumer ? 'Abbrechen' : 'Editieren']">
                        <button class="btn btn-circle" :class="[editConsumer ? 'btn-error' : '']" @click="clickEditConsumer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil transition-all absolute" :class="[editConsumer ? 'opacity-0 -rotate-180': 'opacity-100 rotate-0']" viewBox="0 0 16 16">
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-x-lg transition-all absolute" :class="[editConsumer ? 'opacity-100': 'opacity-0 rotate-180']" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                                <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                            </svg>
                        </button>
                    </div>
                    <div class="tooltip mb-1" data-tip="Bildungskarten">
                        <button class="btn btn-circle">
                            <IdIcon />
                        </button>
                    </div>
                </div>
            </div>
            <h2 v-if="!editConsumer" class="card-title text-2xl">{{consumer.firstName}} {{consumer.lastName}} ({{consumer.grade}}{{consumer.groupLetter}})</h2>
            <div v-else class="mt-4 grid gap-2">
                <div class="grid grid-cols-3">
                    <label for="firstName">Vorname: </label>
                    <input type="text" name="firstName" placeholder="Vorname" v-model="initialConsumer.firstName" class="mr-2 input input-bordered focus:outline-none input-sm">
                </div>
                <div class="grid grid-cols-3">
                    <label for="lastName">Nachname: </label>
                    <input type="text" name="lastName" v-model="initialConsumer.lastName" placeholder="Nachname" class="mr-2 input input-bordered focus:outline-none input-sm">
                </div>
                <div class="grid grid-cols-3">
                    <label for="grade">Jahrgangsstufe: </label>
                    {{initialConsumer.grade}}
                </div>
                <div class="grid grid-cols-3">
                    <label for="gradeLetter">Klasse: </label>
                    {{initialConsumer.groupLetter}}
                </div>
            </div>
            <div>
                <span v-if="!editConsumer" class="text-xl">{{(getClients) ? getClients[consumer.client].name : ''}}</span>
                <div v-else class="grid grid-cols-3">
                    <span class="mr-2">Schule:</span>
                    {{(getClients) ? getClients[consumer.client].name : ''}}
                </div>
            </div>
            <div>
                <div class="divider"></div>
                <div class="mb-4">
                    <h3 class="text-xl font-bold -mt-2">Abo</h3>
                    <div class="text-base font-bold">({{formatDateFromString(consumer.subscription.vaildFrom)}} - {{ (consumer.subscription.validTill) ? formatDateFromString(consumer.subscription.validTill) : 'Jetzt' }})</div>
                </div>
                <div class="mb-8">
                    <div class="mb-2">Tage Pro Woche: (€ {{getClients[consumer.client].priceSchedule[0].subscriptionPrice}} pro Monat je Tag)</div>
                    <ul class="steps w-full">
                        <li class="step cursor-pointer" @click="setSubscriptionDays(n)" :class="[subscriptionDays >= n ? 'step-neutral' : '', getAvailableDays < n ? 'cursor-not-allowed opacity-20' : '']" v-for="n in 7" :key="n">
                            <template v-if="n <= getAvailableDays">
                                € {{ (getClients[consumer.client].priceSchedule[0].subscriptionPrice * n).toFixed(2) }}
                            </template>
                        </li>
                    </ul>
                </div>
                <div class="">
                    <div class="form-control mb-4">
                        <label class="flex justify-between items-center cursor-pointer">
                            <span>Spontanessen zulassen (€ {{getClients[consumer.client].priceSchedule[0].unitPrice}} pro genutzem Essen)</span>
                            <input type="checkbox" class="toggle" v-model="initialConsumer.subscription.allowAdditionalPurchase" @change="openEditMode">
                        </label>
                    </div>
                    <div :class="[initialConsumer.subscription.allowAdditionalPurchase ? '' : 'opacity-50']">
                        <label for="allowAdditionalPurchaseCount" class="mb-2 block">Erlaubte Essen pro Woche:</label>
                        <div class="text-center text-xl mb-2">
                            <span>{{ allowAdditionalPurchaseCount }}</span>
                        </div>
                        <input type="range" name="allowAdditionalPurchaseCount" min="0" max="10" :disabled="!initialConsumer.subscription.allowAdditionalPurchase" v-model="allowAdditionalPurchaseCount" class="range range-sm" @change="openEditMode">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    consumer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editConsumer: false,
      subscriptionDays: 3,
      initialSubscriptionDays: 3,
      allowAdditionalPurchase: false,
      allowAdditionalPurchaseCount: 4,
      initialConsumer: null,
      ready: false,
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    }
  },
  mounted () {
    this.initialConsumer = JSON.parse(JSON.stringify(this.consumer))
    this.ready = true
  },
  methods: {
    clickEditConsumer () {
      if (this.editConsumer) {
        console.log('Discard Changes')
        this.initialConsumer = JSON.parse(JSON.stringify(this.consumer))
        this.subscriptionDays = this.initialSubscriptionDays
        this.editConsumer = false
      } else {
        this.editConsumer = true
      }
    },
    saveConsumer () {
      console.log('Save Consumer')
      this.editConsumer = false
      this.$emit('saveConsumer', this.initialConsumer)
      this.initialSubscriptionDays = this.subscriptionDays
    },
    setSubscriptionDays (days) {
      if (days > this.getAvailableDays) {
        return false
      }

      if (!this.editConsumer && days !== this.subscriptionDays) {
        this.editConsumer = true
      }
      this.subscriptionDays = days
    },
    formatDateFromString (dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString('de-DE')
    },
    openEditMode () {
      if (!this.editConsumer) {
        this.editConsumer = true
      }
    }
  },
  computed: {
    ...mapGetters(['getClients']),
    getAvailableDays () {
      const clientDaysOfOperation = this.getClients[this.consumer.client].priceSchedule[0].daysOfOperation
      const availableDays = Object.values(clientDaysOfOperation).filter(item => item === true)
      console.log('asd', availableDays)
      return availableDays.length
    }
  }

}
</script>
