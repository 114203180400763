<template>
  <div class="form-control justify-self-end">
    <div class="input-group">
      <span v-if="label">{{ label }}</span>
      <input type="text" v-model="query" @input="handler" @keyup.enter="handler" placeholder="Suche…" class="input input-bordered focus:outline-none flex-1">
      <button class="btn btn-square" :class="{'loading': loading}" :disabled="loading" @click="handler">
        <SearchIcon v-show="!loading" class="h-6 w-6"></SearchIcon>
      </button>
    </div>
  </div>
</template>

<script>
// import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'
export default {
  name: 'base-paginator-search',
  data () {
    return {
      query: '',
      handler: null
    }
  },
  created () {
    this.handler = debounce(this.search, 300)
  },
  props: {
    searchParam: {
      type: String,
      default: 'search'
    },
    label: {
      type: String,
      default: ''
    }
  },
  inject: [
    'paginatorLoading',
    'addParameterToUrl',
    'removeParameterFromUrl',
    'paginatorMeta',
    'url'
  ],
  computed: {
    loading () {
      return this.paginatorLoading
    }
  },
  watch: {
    url (val) {
      if (val.searchParams.has(this.searchParam)) {
        this.query = val.searchParams.get(this.searchParam)
      }
      // console.log(val, val.searchParams.has(this.searchParam))
    }
  },
  methods: {
    search () {
      if (!this.query) {
        this.removeParameterFromUrl(this.searchParam)
        return
      }
      this.addParameterToUrl(this.searchParam, this.query)
    }
  }
}
</script>

<style scoped>

</style>
