<template>
  <div class="dropdown" v-show="isActive">
    <div class="indicator">
      <span class="indicator-item indicator-top  badge badge-primary px-1 py-2 mr-2" v-if="filterValue !== ''"><CheckIcon class="w-4" /></span>
      <label tabindex="0" class="btn">
        {{ displayedLabel }}
        <XIcon class="ml-2" @click="remove"/>
      </label>
    </div>
    <div tabindex="0" class="dropdown-content menu p-4 mt-2 rounded-box shadow bg-base-100 w-96">
      <div class="mb-2">
        {{headline}}
      </div>
      <div class="flex flex-row">
        <base-select v-model="selection" :label="label ? label : id" :name="id"  :options="options" v-bind="config"></base-select>
      </div>
    </div>
  </div>
</template>

<script>
import BaseFilterInput from './BaseFilterInput'
export default {
  name: 'base-filter-select',
  extends: BaseFilterInput,
  props: {
    options: Array,
    config: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      selection: '',
      selectedLabel: ''
    }
  },
  computed: {
    displayedLabel () {
      return this.selectedLabel ? this.selectedLabel : this.filterLabel
    }
  },
  watch: {
    selection (newValue) {
      this.filterValue = this.config.props.trackBy ? newValue[this.config.props.trackBy] : newValue
      this.selectedLabel = this.config.props.label ? newValue[this.config.props.label] : newValue
    }
  }
}
</script>
