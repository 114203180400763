<template>
  <div class="form-control w-full z-50">
    <label class="label" v-if="label" :for="name">
      <span class="label-text" :class="{'text-opacity-50': disabled}">{{ label }}</span>
    </label>
    <vue-multiselect v-bind="attributes" :class="{'bg-opacity-50 bg-green-500 text-opacity-50': disabled, 'multiselect__invalid': isInvalid }" v-on="listeners" :name="name" :placeholder="placeholder" :options="options" v-model="inputValue">
      <template #caret>
        <div class="multiselect__select">
          <CaretDownIcon class="w-full h-full"  />
        </div>
      </template>
      <template #maxElements="{ max }">
        Maximum von {{ max }} ausgewählten Optionen. Entfernen Sie zunächst eine ausgewählte Option, um eine andere auszuwählen.
      </template>
      <template #noResult>
        Keine Einträge gefunden. Ändern Sie bitte die Suchanfrage.
      </template>
      <template #noOptions>
        Liste ist leer
      </template>
    </vue-multiselect>
    <label class="label" v-show="!!errorMessage">
      <span class="label-text-alt text-error">{{ errorMessage }}</span>
    </label>
  </div>
</template>

<script>
import { toRef } from 'vue'
import { useField } from 'vee-validate'
export default {
  name: 'base-select',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    modelValue: {},
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    successMessage: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Option wählen'
    },
    props: {
      type: Object,
      default: () => {}
    },
    events: {
      type: Object,
      default: () => {}
    },
    rules: {
      type: String,
      default: undefined
    }
  },
  computed: {
    hasError () {
      return !!this.errorMessage
    },
    attributes () {
      const props = this.props ? this.props : {}
      return {
        ...props,
        disabled: this.disabled,
        multiple: this.multiple,
        tagPlaceholder: props.tagPlaceholder ? props.tagPlaceholder : 'Drücken Sie die Eingabetaste, um einen Tag zu erstellen',
        selectLabel: props.selectLabel ? props.selectLabel : 'Auswählen',
        selectGroupLabel: props.selectGroupLabel ? props.selectGroupLabel : 'Drücken Sie die Eingabetaste, um die Gruppe auszuwählen.',
        selectedLabel: props.selectedLabel ? props.selectedLabel : 'Ausgewählt',
        deselectLabel: props.deselectLabel ? props.deselectLabel : 'Aufheben',
        deselectGroupLabel: props.deselectGroupLabel ? props.deselectGroupLabel : 'Drücken Sie die Eingabetaste, um die Auswahl der Gruppe aufzuheben.'
      }
    },
    listeners () {
      return {
        ...this.events
      }
    },
    isInvalid () {
      return this.errors.length > 0
    }
  },
  setup (props) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validte needs to know if the input name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, 'name')

    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value: inputValue,
      errorMessage,
      errors,
      handleBlur,
      handleChange,
      meta
    } = useField(name, props.rules, {
      initialValue: props.modelValue
    })
    return {
      handleChange,
      handleBlur,
      errorMessage,
      errors,
      inputValue,
      meta
    }
  },
  methods: {
    change (event) {
      this.handleChange(event)
      this.$emit('update:modelValue', this.inputValue)
    }
  },
  watch: {
    inputValue (value) {
      this.$emit('update:modelValue', value)
    }
  }
}
</script>

<style  scoped>

</style>
