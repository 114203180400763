import axios, { AxiosError } from 'axios'
import router from '@/router'
import TokenStorage from '@/services/TokenStorage'
// @ts-ignore
// const API_KEY = import.meta.env.API_TOKEN
// @ts-ignore
const API_URL = import.meta.env.API_URL

const axiosInstance = axios.create({ baseURL: API_URL })

axiosInstance.interceptors.response.use(response => response, (error: AxiosError) => {
  if (error.response?.status === 401) {
    axiosInstance.defaults.headers.common.Authorization = ''
    TokenStorage.clearToken()
    router.push({ name: 'login_index' })
  }
  return Promise.reject(error)
})

export const setBearer = (token: string) => {
  if (!token) {
    axiosInstance.defaults.headers.common.Authorization = ''
  } else {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
  }
}

export default axiosInstance
