import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nfc_scan = _resolveComponent("nfc-scan")!
  const _component_hyperspace_loader = _resolveComponent("hyperspace-loader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_base_notification = _resolveComponent("base-notification")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_nfc_scan),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_hyperspace_loader)
        ]))
      : (_openBlock(), _createBlock(_component_router_view, { key: 1 })),
    _createVNode(_component_notifications, {
      position: "bottom center",
      width: 500,
      classes: "hello"
    }, {
      body: _withCtx((props) => [
        _createVNode(_component_base_notification, {
          item: props.item,
          close: props.close,
          level: props.item.type
        }, null, 8, ["item", "close", "level"])
      ]),
      _: 1
    })
  ], 64))
}