
import { Customer } from '@interface/entity/customer'
import { NewConsumer, Client } from '@interface/api/FrontendApiController'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { Form } from 'vee-validate'
import * as yup from 'yup'

interface Data {
  isConsumer: boolean,
  additionalPurchase: boolean,
  weekdays: number,
  amount: number,
  grade: string,
  showHint: boolean,
  client: Client,
  firstName: string,
  lastName: string
}

export default defineComponent({
  name: 'register-consumer-create',
  components: {
    Form
  },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true
    }
  },
  data () : Data {
    return {
      showHint: true,
      client: {} as Client,
      isConsumer: false,
      additionalPurchase: false,
      weekdays: 1,
      amount: 1,
      grade: '',
      firstName: '',
      lastName: ''
    }
  },
  methods: {
    clear () {
      this.client = {} as Client
      this.isConsumer = false
      this.additionalPurchase = false
      this.weekdays = 1
      this.amount = 1
      this.grade = ''
      this.firstName = ''
      this.lastName = ''
    },
    save () {
      const consumer: NewConsumer = {
        // @ts-ignore
        id: window.crypto.randomUUID(),
        firstName: this.firstName,
        lastName: this.lastName,
        client: this.client,
        isConsumer: this.isConsumer
      }
      if (this.grade) {
        const grade = this.grade.match(/\d+/g)
        if (!grade) { return }
        const letter = this.grade.toString().replace(grade[0], '')
        consumer.grade = parseInt(grade[0])
        consumer.groupLetter = this.isConsumer ? '' : letter
      }
      this.$emit('add', JSON.parse(JSON.stringify(consumer)))
      this.clear()
    }
  },
  computed: {
    schema () {
      const schema = {
        firstName: yup.string().required(),
        lastName: yup.string().required()
      }
      if (!this.isConsumer) {
        // @ts-ignore
        schema.grade = yup.string().matches(/\d{1,2}([A-Za-z])?/, { message: 'Bitte geben Sie eine valide Klassenstufe ein.', excludeEmptyString: true })
      }
      return yup.object().shape(schema)
    },
    isValid () {
      return this.schema.isValidSync({
        firstName: this.firstName,
        lastName: this.lastName,
        grade: this.grade
      }) && this.client.id
    }
  },
  watch: {
    isConsumer (newValue) {
      if (newValue) {
        this.firstName = this.customer.firstName
        this.lastName = this.customer.lastName
        this.grade = ''
      } else {
        this.lastName = this.customer.lastName
        this.firstName = ''
      }
      this.$forceUpdate()
    }
  }
})
