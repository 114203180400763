import { ref } from 'vue'

// by convention, composable function names start with "use"
export function useClipboard () {
  // state encapsulated and managed by the composable
  const canCopy = ref(window.isSecureContext)
  const isCopied = ref(false)
  const textToClip = ref('')

  const copy = async () => {
    try {
      await window.navigator.clipboard.writeText(textToClip.value)
      isCopied.value = true
    } catch (err) {
      console.error('Async: Could not copy text: ', err)
    }
  }

  const dismiss = () => {
    isCopied.value = false
  }

  // expose managed state as return value
  return { canCopy, isCopied, copy, textToClip, dismiss }
}
