import Vuex, { StoreOptions } from 'vuex'
import { registration } from './registration'
import { login } from './login'
// import { device } from './device'
import { cache } from './cache'
import { me } from './me'
import { RootState } from './interfaces'
import onUserSetListenerPlugin from './me/plugin'

const store: StoreOptions<RootState> = {
  state: {
    loading: false
  },
  actions: {
    async initialPayload ({ dispatch, state, rootGetters }) {
      state.loading = true
      await dispatch('login/restoreUser', null, { root: true })
      state.loading = false
    }
  },
  plugins: [onUserSetListenerPlugin],
  modules: {
    registration,
    login,
    cache,
    me
  }
}

export default new Vuex.Store(store)
