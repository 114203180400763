import { RouteRecordRaw } from 'vue-router'
import LoginLayout from '@layout/login.vue'
import store from '@/store'
import { Customer } from '@interface/entity/customer'
import LoginIndexDefault from '@view/login/index.vue'
import LoginIndexHeader from '@view/login/header.vue'
const routes : Array<RouteRecordRaw> =
 [
   {
     path: '',
     name: 'login_index',
     components: {
       default: LoginIndexDefault,
       header: LoginIndexHeader
     }
   },
   {
     path: 'register',
     name: 'login_register',
     components: {
       default: () => import(/* webpackChunkName: "register_index" */ '@view/login/register/index.vue'),
       header: () => import(/* webpackChunkName: "register_header" */ '@view/login/register/header.vue')
     }
   },
   {
     path: 'optin/:token',
     name: 'login_optin',
     components: {
       default: () => import(/* webpackChunkName: "login_optin_index" */ '@view/login/optin/index.vue'),
       header: () => import(/* webpackChunkName: "login_optin_header" */ '@view/login/optin/header.vue')
     }
   },
   {
     path: 'password_forget',
     name: 'login_password_forget',
     components: {
       default: () => import(/* webpackChunkName: "login_optin_index" */ '@view/login/password_forget/index.vue'),
       header: () => import(/* webpackChunkName: "login_optin_header" */ '@view/login/password_forget/header.vue')
     }
   },
   {
     path: 'first_login',
     name: 'login_first',
     components: {
       default: () => import(/* webpackChunkName: "first_login_index" */ '@view/login/first_login/index.vue'),
       header: () => import(/* webpackChunkName: "first_login_header" */ '@view/login/first_login/header.vue')
     }
   },
   {
     name: 'post_login',
     path: '/check',
     redirect (to) {
       if (store.getters['login/user']) {
         return { name: 'customer_index' }
       }
       const customer: Customer = store.getters['login/customer']
       if (customer.state === 'pre_register') {
         return { name: 'first_login' }
       } else {
         return { name: 'me_index' }
       }
       return { name: 'login_index' }
     }
   }
 ]
export default {
  path: '/login/',
  children: routes,
  component: LoginLayout
}
