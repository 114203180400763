
import { defineComponent, PropType } from 'vue'
import { Form } from 'vee-validate'
import { required } from '@vee-validate/rules'
import { Client } from '@interface/entity/client'
import { PriceSchedule } from '@interface/entity/priceschedule'
import { rfc3339 } from '@/utility/date'

interface Data {
  daysOfOperation: any
  unitPrice: number
  subventionPrice: number
  subscriptionPrice: number
  isStaggeredPrice: boolean
  staggeredPrices: any,
  validFrom: Date | null
  validTill: Date | null
}

export default defineComponent({
  name: 'fieldset-price-schedule',
  components: { Form },
  emits: ['priceSchedule'],
  data (): Data {
    return {
      validFrom: null,
      validTill: null,
      daysOfOperation: {},
      unitPrice: 0,
      subscriptionPrice: 0,
      subventionPrice: 0,
      isStaggeredPrice: false,
      staggeredPrices: {}
    }
  },
  beforeMount () {
    if (this.schedule) {
      this.copyFromSchedule(this.schedule)
    }
  },
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true
    },
    schedule: {
      type: Object as PropType<PriceSchedule> | null,
      default: null
    },
    disableDates: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    copyFromSchedule (schedule: PriceSchedule) {
      this.daysOfOperation = schedule.scheduleDays
      this.unitPrice = schedule.unitPrice
      this.subscriptionPrice = schedule.subscriptionPrice
      this.subventionPrice = schedule.subventionPrice
      this.isStaggeredPrice = schedule.isStaggeredPrice
      this.validFrom = new Date(schedule.validFrom)
      this.validTill = new Date(schedule.validTill)
      if (this.isStaggeredPrice) {
        this.staggeredPrices = schedule.schedulePrices
      }
    },
    save () {
      this.$emit('priceSchedule', {
        validFrom: rfc3339(this.validFrom ? this.validFrom : new Date()),
        validTill: rfc3339(this.validTill ? this.validTill : new Date()),
        scheduleDays: this.daysOfOperation,
        unitPrice: this.unitPrice,
        subventionPrice: this.subventionPrice,
        subscriptionPrice: this.subscriptionPrice,
        isStaggeredPrice: this.isStaggeredPrice,
        staggeredPrices: this.staggeredPrices,
        schedulePrices: this.prices
      } as PriceSchedule)
    },
    validateTill (value: Date) {
      if (!value) {
        return required(value)
      }
      if (!this.validFrom) {
        return 'Please select a start date'
      }
      if (value.getTime() < this.validFrom.getTime()) {
        return 'this field must be greater than: ' + this.$d(this.validFrom, 'date')
      }
      return true
    },
    isWithin (schedule: PriceSchedule, value: Date) {
      const from = new Date(schedule.validFrom)
      const till = new Date(schedule.validTill)
      return value.getTime() > from.getTime() && value.getTime() < till.getTime()
    },
    isSmaller (schedule: PriceSchedule, value: Date) {
      const from = new Date(schedule.validFrom)
      const till = new Date(schedule.validTill)
      return value.getTime() < from.getTime() && value.getTime() < till.getTime()
    },
    isGreater (schedule: PriceSchedule, value: Date) {
      const from = new Date(schedule.validFrom)
      const till = new Date(schedule.validTill)
      return value.getTime() > from.getTime() && value.getTime() > till.getTime()
    },
    validateFrom (value: Date) {
      if (!value) {
        return required(value)
      }
      const schedules = Object.values(this.client.upcomingSchedules)
      if (this.schedule) {
        if (this.schedule.id !== this.client.activeSchedule.id) {
          schedules.push(this.client.activeSchedule)
        }
      }
      const isWithin = schedules.some((schedule: PriceSchedule) => {
        const from = new Date(schedule.validFrom)
        const till = new Date(schedule.validTill)
        return value.getTime() < from.getTime() && value.getTime() > till.getTime()
      })
      if (isWithin) {
        return 'The Starting Date can start earliest ' + this.$d(this.earliestValidFromDate, 'date')
      }
      return true
    }
  },
  computed: {
    disabledDates () {
      const dates: any = []
      this.allSchedules.forEach((schedule, index) => {
        if (index === 0) {
          dates.push({ start: null, end: new Date(schedule.validFrom) })
        }
        if (this.schedule) {
          if (schedule.id === this.schedule.id) {
            return
          }
        }
        dates.push({
          start: new Date(schedule.validFrom),
          end: new Date(schedule.validTill)
        })
      })
      return dates
    },
    allSchedules (): PriceSchedule[] {
      return Object.values(this.client.priceSchedule)
    },
    currentIndex (): number {
      if (!this.schedule) { return -1 }
      return this.allSchedules.findIndex((schedule) => schedule.id === this.schedule.id)
    },
    previousSchedule (): PriceSchedule | null {
      if (this.currentIndex === -1) { return null }
      if (this.currentIndex === 0) { return null }
      return this.allSchedules[this.currentIndex - 1]
    },
    nextSchedule (): PriceSchedule | null {
      if (this.currentIndex === -1) { return null }
      const newElement = this.allSchedules[this.currentIndex + 1]
      if (!newElement) { return null }
      return newElement
    },
    minDates () {
      const from = new Date(this.previousSchedule ? this.previousSchedule.validTill : this.client.latestSchedule.validTill)
      from.setDate(from.getDate() + 1)

      const till = new Date(this.validFrom ? this.validFrom.getTime() : '')
      till.setDate(till.getDate() + 1)
      return {
        from,
        till
      }
    },
    maxDates () {
      if (!this.nextSchedule) {
        return {}
      }
      const from = new Date(this.nextSchedule ? this.nextSchedule.validFrom : this.client.latestSchedule.validFrom)
      from.setDate(from.getDate() - 1)
      return {
        from,
        till: from
      }
    },
    earliestValidFromDate (): Date {
      const from = new Date(this.previousSchedule ? this.previousSchedule.validTill : this.client.latestSchedule.validTill)
      from.setDate(from.getDate() + 1)
      return from
    },
    schema () {
      const schema: any = {
        unitPrice: 'required|currency|no_zero',
        subventionPrice: 'required|currency|no_zero',
        from: this.validateFrom,
        till: this.validateTill
      }
      if (this.isStaggeredPrice) {
        Object.keys(this.prices).forEach((day: string) => {
          schema[day] = 'required|currency|no_zero'
        })
      } else {
        schema.subscriptionPrice = 'required|currency|no_zero'
      }
      return schema
    },
    prices () : any {
      const prices: any = {}
      let i = 1
      for (const prop in this.operationalDays) {
        prices[prop] = this.isStaggeredPrice ? this.staggeredPrices[prop] : i * this.subscriptionPrice
        if (!prices[prop]) {
          prices[prop] = 0
        }
        i++
      }
      return prices
    },
    operationalDays () {
      const days: any = {}
      Object.keys(this.daysOfOperation).forEach((day) => {
        if (this.daysOfOperation[day]) {
          days[day] = this.daysOfOperation[day]
        }
      })
      return days
    }
  }
})
