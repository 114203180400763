
import { defineComponent, watch } from 'vue'
import { useClipboard } from '@/composables/clipboard'

export default defineComponent({
  name: 'clip-board',
  setup (props) {
    const { copy, canCopy, isCopied, textToClip, dismiss } = useClipboard()
    textToClip.value = props.text as string

    if (props.dismissAfter > -1) {
      watch(isCopied, (newValue) => {
        if (!newValue) { return }
        if (props.dismissAfter === 0) {
          dismiss()
        }
        setTimeout(dismiss, props.dismissAfter)
      })
    }
    return {
      copy, canCopy, isCopied
    }
  },
  props: {
    text: {
      type: String,
      required: true
    },
    dismissAfter: {
      type: Number,
      default: 2000
    }
  }
})
