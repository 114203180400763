import { defineRule, configure } from 'vee-validate'
import AllRules from '@vee-validate/rules'
import { localize, setLocale } from '@vee-validate/i18n'

export const RULES: any = {
  ...AllRules,
  currency (value: string) {
    const regex = /^\d+((\.|,)\d{1,2})?$/
    if (!value || !value.length) {
      return true
    }
    return regex.test(value)
  },
  alpha_all (value: string) {
    const regex = /^[\dA-ZÄÖÜß\s_-]*$/i
    if (!value || !value.length) {
      return true
    }
    return regex.test(value)
  },
  no_zero (value: string) {
    if (!value || !value.length) {
      return true
    }
    return parseFloat(value) > 0
  },
  german_phone (value: string) {
    const regex = /^[\d\s/]*$/i
    if (!value || !value.length) {
      return true
    }
    return regex.test(value)
  }
}

Object.keys(RULES).forEach(rule => {
  defineRule(rule, RULES[rule])
})

configure({
  generateMessage: localize({
    de: {
      messages: {
        currency: 'Bitte geben Sie eine Ziffer oder Fließkommazahl ein.',
        alpha_all: 'Das Feld darf nur alphanumerische Zeichen sowie Striche, Unterstriche und Leerzeichen enthalten.',
        no_zero: 'Bitte geben Sie einen Wert der 0 ist.',
        _default: 'Das Feld ist ungültig.',
        alpha: 'Das Feld darf nur alphabetische Zeichen enthalten',
        alpha_dash: 'Das Feld darf alphanumerische Zeichen sowie Striche und Unterstriche enthalten',
        alpha_num: 'Das Feld darf nur alphanumerische Zeichen enthalten',
        alpha_spaces: 'Das Feld darf nur alphanumerische Zeichen und Leerzeichen enthalten',
        between: 'Die Eingabe muss zwischen 0:{min} und 1:{max} liegen',
        confirmed: 'Die Bestätigung von {field} stimmt nicht überein',
        digits: 'Das Feld muss numerisch sein und exakt 0:{length} Ziffern enthalten',
        dimensions: 'Das Feld muss 0:{width} x 1:{height} Bildpunkte groß sein',
        email: 'Das Feld muss eine gültige E-Mail-Adresse sein',
        excluded: 'Das Feld muss ein gültiger Wert sein',
        ext: 'Ihr Upload muss eine gültige Datei sein',
        image: 'Die Datei muss eine Grafik sein',
        one_of: '{field} muss ein gültiger Wert sein',
        integer: 'Das Feld muss eine ganze Zahl sein',
        length: 'Die Länge von der Eingabe muss 0:{length} sein',
        max: 'Die Eingabe darf nicht länger als 0:{length} Zeichen sein',
        max_value: 'Die Eingabe darf maximal 0:{max} sein',
        mimes: 'Die Datei muss einen gültigen Dateityp haben',
        min: 'Die Eingabe muss mindestens 0:{length} Zeichen lang sein',
        min_value: 'Die Eingabe muss mindestens 0:{min} sein',
        numeric: 'Das Feld darf nur numerische Zeichen enthalten',
        regex: 'Das Format von der Eingabe ist ungültig',
        required: 'Das Feld ist ein Pflichtfeld',
        required_if: 'Das Feld ist ein Pflichtfeld',
        size: 'Die Datei muss kleiner als 0:{size}KB sein'
      }
    }
  })
})

setLocale('de')

export default RULES
