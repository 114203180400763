import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import { authenticationRequired } from './guards/authenitcationMiddleware'

const router = createRouter({
  history: process.env.NODE_ENV === 'development' ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
  routes: routes
})

router.beforeEach(authenticationRequired)

export default router
