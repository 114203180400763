
import Api from '@/api'
import { defineComponent } from 'vue'
// import { useValidateForm } from 'vee-validate'
import { Form } from 'vee-validate'
import { Notification } from '@interface/enums/Notification'
// import * as yup from 'yup'

interface NewClient {
  name: string,
  street: string,
  zip: string,
  houseNumber: string,
  city: string,
  province: string,
  unitPrice: number,
  subscriptionPrice: number,
  daysOfOperation: any,
  staggeredPrices: any,
  isStaggeredPrice: boolean
}

interface Data {
  states: Array<string>,
  client: NewClient,
  loading: boolean,
}

export default defineComponent({

  name: 'client-new-view',
  components: {
    Form
  },
  data (): Data {
    return {
      states: ['Baden-Württemberg', 'Bayern', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg', 'Hessen', 'Mecklenburg-Vorpommern', 'Niedersachsen', 'Nordrhein-Westfalen', 'Rheinland-Pfalz', 'Saarland', 'Sachsen', 'Sachsen-Anhalt', 'Schleswig-Holstein', 'Thüringen'],
      client: {
        houseNumber: '',
        name: '',
        street: '',
        zip: '',
        city: '',
        province: '',
        unitPrice: 0,
        subscriptionPrice: 0,
        daysOfOperation: {},
        isStaggeredPrice: false,
        staggeredPrices: {}
      },
      loading: false
    }
  },
  computed: {
    prices () : any {
      const prices: any = {}
      let i = 1
      for (const prop in this.daysOfOperation) {
        prices[prop] = this.client.isStaggeredPrice ? this.client.staggeredPrices[prop] : i * this.client.subscriptionPrice
        if (!prices[prop]) {
          prices[prop] = 0
        }
        i++
      }
      return prices
    },
    daysOfOperation () {
      const days: any = {}
      Object.keys(this.client.daysOfOperation).forEach((day) => {
        if (this.client.daysOfOperation[day]) {
          days[day] = this.client.daysOfOperation[day]
        }
      })
      return days
    }
  },
  methods: {
    async save (values: any) {
      if (!this.client.isStaggeredPrice) {
        delete this.client.staggeredPrices
      } else {
        this.client.subscriptionPrice = 0
      }
      try {
        const { data } = await Api.client.create(this.client)
        this.$notify({
          title: 'Schule wurde angelegt.',
          text: `${data.name} wurde erfolgreich erstellt.`,
          type: Notification.SUCCESS
        })
        await this.$router.push({ name: 'client_index' })
      } catch (e) {
        this.$notify({
          title: 'Beim anlegen der Schule ist ein Fehler aufge',
          type: Notification.ERROR
        })
      }
    }
  }
})
