<template>
  <transition
    enter-active-class="enter-active"
    leave-active-class="leave-active"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </transition>
</template>
<style scoped>
  .enter-active,
  .leave-active {
    overflow: hidden;
    transition: height v-bind(cssTime) cubic-bezier(.56,0,.08,1.02);
    max-height: none;
  }
</style>
<script>
export default {
  name: 'transition-collapse-height',
  data () {
    return {}
  },
  props: {
    time: {
      type: Number,
      default: 0.3
    }
  },
  methods: {
    /**
       * @param {HTMLElement} element
       */
    beforeEnter (element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = '0px'
        }

        element.style.display = null
      })
    },
    enter (element, done) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`
        })
      })
    },
    /**
       * @param {HTMLElement} element
       */
    afterEnter (element) {
      element.style.height = null
    },
    /**
       * @param {HTMLElement} element
       */
    beforeLeave (element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`
        }
      })
    },
    leave (element, done) {
      element.style.height = '0px'
    },
    /**
       * @param {HTMLElement} element
       */
    afterLeave (element) {
      element.style.height = null
    }
  },
  computed: {
    cssTime () {
      return this.time.toString() + 's'
    }
  }
}

</script>
