import { setLocale } from 'yup'
import { LocaleObject } from 'yup/lib/locale'

const options : LocaleObject = {
  mixed: {
    default: 'Der eingegeben Wert ist nicht korrekt.',
    required: 'Dies ist ein Pflichtfeld.'
  },
  number: {
    min: ({ min, value }) => ({ key: 'invalid.number.min', values: { min, value } }),
    max: ({ max, value }) => ({ key: 'invalid.number.max', values: { max, value } })
  },
  string: {
    email: 'Bitte geben Sie eine valide E-Mail-Addresse ein.'
  }
}

setLocale(options)
