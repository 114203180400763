import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, mergeProps as _mergeProps, withDirectives as _withDirectives, vShow as _vShow, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["disabled", "id", "min", "max"]
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "label-text-alt text-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-control", {'bg-opacity-50': _ctx.disabled}])
  }, [
    _createVNode(_component_Field, {
      name: _ctx.name,
      modelValue: _ctx.vModel,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.vModel) = $event)),
      ref: "field",
      rules: _ctx.rules,
      "validate-on-input": true
    }, {
      default: _withCtx(({ field, errorMessage, errors }) => [
        _createElementVNode("label", {
          for: _ctx.id,
          class: "mb-2 block"
        }, _toDisplayString(_ctx.label), 9, _hoisted_1),
        _createElementVNode("div", {
          class: _normalizeClass(["text-center text-xl mb-2", { 'text-error': errors.length > 0 }])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.vModel), 1)
        ], 2),
        _withDirectives(_createElementVNode("input", _mergeProps(field, {
          disabled: _ctx.disabled,
          id: _ctx.id,
          type: "range",
          min: _ctx.min,
          max: _ctx.max,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vModel) = $event)),
          class: [{ 'range-error': errors.length > 0 }, "range"]
        }), null, 16, _hoisted_2), [
          [_vModelText, _ctx.vModel]
        ]),
        _withDirectives(_createElementVNode("label", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(errorMessage), 1)
        ], 512), [
          [_vShow, !!errorMessage]
        ])
      ]),
      _: 1
    }, 8, ["name", "modelValue", "rules"])
  ], 2))
}