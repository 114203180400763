import { App } from 'vue'
import { ModalPluginOptions } from './types'
import BModal from './BModal.vue'
import { modal } from './modal'

export function install (app: App, args: ModalPluginOptions = {}): void {
  const name = args.name || 'modal'

  app.config.globalProperties['$' + name] = modal
  app.component(args.componentName || 'BModal', BModal)
}
