import { MutationTree } from 'vuex'
import { LoginState } from './interfaces'
import { Customer } from '@interface/entity/customer'
import { User } from '@interface/entity/user'

export enum LoginMutations {
  SET_USER = 'set_user',
  SET_TOKEN = 'set_token',
  TOGGLE_LOADING = 'toggle_loading',
  CLEAR = 'clear_state'
}

function isCustomer (userOrCustomer: Customer | User): userOrCustomer is Customer {
  return !!(userOrCustomer as Customer).subscription || !!(userOrCustomer as Customer).consumers
}

export const mutations: MutationTree<LoginState> = {
  [LoginMutations.SET_USER] (state, payload: Customer | User) {
    if (isCustomer(payload)) {
      state.customer = payload
    } else {
      state.user = payload
    }
  },
  [LoginMutations.SET_TOKEN] (state, payload: string) {
    state.token = payload
  },
  [LoginMutations.CLEAR] (state, _payload) {
    state.user = null
    state.customer = null
    state.token = ''
  },
  [LoginMutations.TOGGLE_LOADING] (state, payload: boolean | null | undefined) {
    if (typeof payload === 'boolean') {
      state.loading = payload
    } else {
      state.loading = !state.loading
    }
  }
}
