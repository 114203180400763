
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { Notification } from '@interface/enums/Notification'
export default defineComponent({
  name: 'layout-default',
  computed: {
    ...mapGetters('login', ['user']),
    ...mapGetters('cache', ['reports']),
    placeHolder (): string {
      if (!this.user) { return '' }
      return this.user.name.split(' ', 2).map((val:string) => val[0].toUpperCase()).join('')
    },
    breadCrumbs () {
      console.log(this.$route.matched, this.$route.matched.filter((route) => {
        const name = route.name as string ?? ''
        return !name.includes('root') && name !== this.$route.name
      }))
      return ['home', 'client_index']
    }
  },
  methods: {
    ...mapActions('login', ['logout']),
    async logOut () {
      await this.logout()
      this.$notify({
        title: 'Sie wurden erfolgreich ausgeloggt!',
        type: Notification.SUCCESS
      })
      this.$router.push({ name: 'login_index' })
    }
  }
})
