
// import { ApiClient } from '../../api'
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import WalletDisplay from '@module/customer_frontend/components/display/WalletDisplay.vue'
import DashboardCalltoaction from '@module/customer_frontend/components/DashboardCalltoaction.vue'

export default defineComponent({
  name: 'me-index',
  components: { WalletDisplay, DashboardCalltoaction },
  data () {
    return {
      active: false
    }
  },
  inject: ['logOut'],
  mounted () {
    setTimeout(() => {
      this.active = true
    }, 100)
  },
  unmounted () {
    this.active = false
  },
  computed: {
    ...mapGetters('me', {
      customer: 'instance',
      kdNumbers: 'kdNumbers',
      consumers: 'consumers',
      walletBalance: 'balance'
    }),
    isLoaded (): boolean {
      return !!this.customer && this.active
    }

  }
})
