import { GetterTree } from 'vuex'
import { RootState } from '../interfaces'
import { CacheState } from './interfaces'
import { Client } from '@interface/entity/client'
import Api from '@/api'

export const getters: GetterTree<CacheState, RootState> = {
  clients (state): Record<string, Client> {
    return state.clients
  },
  apiClients (state): Record<string, Client> {
    const clients: Record<string, Client> = {}
    Object.values(state.clients).forEach((client) => {
      const id = Api.client.getUri(client.id)
      clients[id] = client
    })
    return clients
  },
  reports (state) {
    return state.reports
  }
}
