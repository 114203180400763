import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import Client from '@/api/axios'
import Routing from '@/symfony'
import { ConsumerUpdateRequest } from '@interface/api/Consumer'
import { EducationCard } from '@interface/entity/educationcard'

export interface ConsumerApi{
  all: (params: any) => Promise<AxiosPromise>
  get: (id: string) => Promise<AxiosPromise>
  update: (id: string, update: ConsumerUpdateRequest) => Promise<AxiosPromise>
  newEducationCard: (consumer: string, card: EducationCard) => Promise<AxiosPromise>
}

export const consumerApi: ConsumerApi = {
  async all (parmas: any): Promise<AxiosPromise> {
    return Client.get(Routing.generate('api_consumers_get_collection', parmas))
  },
  async get (id:string): Promise<AxiosPromise> {
    return Client.get(Routing.generate('api_consumers_get_item', { id }))
  },
  async update (id, data: ConsumerUpdateRequest): Promise<AxiosPromise> {
    return Client.patch(Routing.generate('api_consumers_update_item', { id: id }), data, {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
  },
  async newEducationCard (consumer: string, card: EducationCard): Promise<AxiosPromise> {
    const url = Routing.generate('api_education_cards_post_collection')
    return Client.post(url, {
      ...card,
      verifiedAt: new Date(),
      consumer: Routing.generate('api_consumers_get_item', { id: consumer })
    })
  }
}
