<template>
    <div class="flex flex-col space-y-4">
        <base-modal name="newUser" title="Neuen Benutzer anlegen" >
          <Form v-slot="{meta}" class="flex space-y-4 flex-col" @submit="newUser">
            <base-input name="name" label="Name" v-model="name" rules="required"></base-input>
            <base-input name="mail" label="E-Mail" v-model="email" rules="required|email"></base-input>
            <password-input name="password" label="Passwort" v-model="password"></password-input>
            <div class="flex">
              <div class="flex-1"></div>
              <button type="submit" class="btn btn-success" :disabled="!meta.valid || !password">
                Nutzer erstellen
              </button>
            </div>
          </Form>
        </base-modal>
        <div class="flex flex-row items-center space-x-4 p-4">
          <div class="text-xl">
            Nutzer Übersichts
          </div>
        </div>
        <base-paginator url="/api/users" :show-meta="false" ref="paginator">
          <template v-slot="{ list, loading }">
            <div class="card bg-base-100 shadow shadow-xl">
              <div class="card-body">
                <div class="flex mb-4">
                  <div class="flex-1"></div>
                  <button class="btn btn-success" @click="emitter.emit('modal:open', 'newUser')">
                    <PlusIcon class="w-6 h-6"></PlusIcon>
                    Neuen Benutzer anlegen
                  </button>
                </div>
                <div class="overflow-x-auto" >
                  <table class="table w-full">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>E-Mail-Adresse</th>
                        <th>Status</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <user-table-row :key="user.id" v-for="user in list" :user="user" :loading="loading"></user-table-row>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </template>
        </base-paginator>
    </div>
</template>
<script>
import { Form } from 'vee-validate'
import UserTableRow from '../components/UserTableRow.vue'
// import Api from '@/api'
export default {
  name: 'user-index',
  components: {
    Form,
    UserTableRow
  },
  data () {
    return {
      name: '',
      email: '',
      password: ''
    }
  },
  methods: {
    async newUser (values) {
      await Api.user.create(values.name, values.mail, values.password)
      this.$refs.paginator.load()
      this.emitter.emit('modal:close', 'newUser')
    }
  },
  i18n: {
    messages: {
      'de-DE': {
        sortingOptions: {
          none: 'Keine Sortierung',
          placeholder: 'Sortieren Sie nach:',
          name: 'Schulname',
          zip: 'Postleitzahl',
          city: 'Stadt'
        }
      }
    }
  }
}
</script>
