import { RouteRecordRaw, RouterView } from 'vue-router'
import LegalLayout from '@layout/legal.vue'
// Routes
import Price from '@/views/legal/price.vue'
import IMPRINT from '@/views/legal/imprint.vue'
import AGB from '@/views/legal/terms.vue'
import GDPR from '@/views/legal/gdpr.vue'

const routes : Array<RouteRecordRaw> =
[
  {
    path: '/price/:id',
    name: 'legal_price',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Price
  },
  {
    path: '/agb',
    name: 'legal_agb',
    component: AGB
  },
  {
    path: '/imprint',
    name: 'legal_imprint',
    component: IMPRINT
  },
  {
    path: '/gdpr',
    name: 'legal_gdpr',
    component: GDPR
  }
]

export default {
  path: '/legal',
  children: routes,
  component: LegalLayout

}
