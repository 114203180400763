<template>
  <div id="login">
    <h1>Allgemeine Geschäftsbedingungen</h1>Stand: 18.03.2022<br>
    <br>
    Allgemeine Geschäftsbedingungen der VIVENO Group GmbH - Cultina  <br>
    <br>
    Das Vertragsverhältnis besteht zwischen dem Erziehungsberechtigten (im Folgenden der „Kunde“ oder „Sie“ genannt) und Cultina.<br>
    <br>
    1.  Voraussetzung für die Teilnahme an der Mittagsverpflegung ist die Registrierung bei Cultina sowie die Buchung eines Abonnements. Um am Abo-Essen teilnehmen zu können, muss der geschuldete Betrag für den jeweiligen Monat auf dem Kunden-Konto gutschrieben sein. <br>
    <br>
    2.  Die Teilnahme am sogenannten Spontanessen setzt ebenfalls den vorherigen Abschluss eines Abonnement-Vertrages voraus. Lehrern und Oberstufenschülern ist das Spontanessen auch ohne Abonnement-Vertrag möglich.<br>
    <br>
    3.  Die Beträge für das Abo müssen jeweils am 20. des Vormonats auf dem Kundenkonto verbucht sein. Bitte beachten Sie auch eventuelle längere Banklaufzeiten.<br>
    <br>
    4.  Sie haben das gesetzliche Recht, den abgeschlossenen Abonnementvertrag innerhalb von 14 Tagen nach Vertragsabschluss zu widerrufen. Hierfür gilt die <br>
    Widerrufsbelehrung.<br>
    <br>
    5.  Unabhängig von dem gesetzlichen Widerrufsrecht räumen wir jedem Kunden inner-halb der ersten 14 Tage jedes Schulhalbjahres die Möglichkeit ein, ausschließlich im Falle von Stundenplanänderungen eine Reduzierung der Abotage durchzuführen. Bitte wenden Sie sich ggf. innerhalb dieses Zeitraumes an Cultina.<br>
    <br>
    6.  Sofern ein Abonnement bis zum Ersten eines Monats nicht bezahlt wurde, ist der Mensachip bis zur vollständigen Zahlung des Monatsbeitrages für das Essen gesperrt. Wird ein Abo aus nicht von Cultina zu vertretenden Gründen nicht genutzt, erfolgt keine Rückerstattung. Dies gilt auch für die Schulferienzeiten oder gesetzliche Feiertage. Eine Gutschrift erfolgt bei Nichtnutzung der Mittagsverpflegung wegen Krankheit, Schulpraktika oder Klassenfahrten, jedoch erst ab einer Abwesenheitsdauer von mindestens drei Tagen. Bei Klassenfahrten und Schulpraktika ist dies durch einen Nach-weis der Schule zu belegen. Bei Krankheit ist die Vorlage einer ärztlichen Bescheinigung bei Cultina mit Angabe der Schule und Chipnummer erforderlich.<br>
    <br>
    7.  Bei Verlust oder Beschädigung ist der Mensachip unverzüglich zu sperren, um einen Missbrauch des Guthabens durch Dritte zu verhindern. Sie können den Chip selbst über Ihr Kundenkonto sperren oder sich an die Cultina Verwaltung (Anschrift siehe unten) wenden. Wird der Chip wiedergefunden, können Sie ihn entsperren. Bei Verlust können Sie gebührenpflichtig einen neuen Chip bestellen. Der Kunde haftet für jeglichen Missbrauch des Mensachips, beispielsweise für durch die unberechtigte Weitergabe des Mensachips an Dritte entstehende Kosten, sofern der Missbrauch nicht von Cultina zu vertreten ist. <br>
    <br>
    8.  Cultina ist berechtigt, nach Abschluss eines Abonnements die Preise für die Mittagsverpflegung in demjenigen Umfang anzupassen, in dem Cultina selbst andere als die bei Vertragsschluss bestehenden Kosten entstehen, welche sich auf die Preisgestaltung der Mittagsverpflegung auswirken. Hierzu zählen insbesondere Kostenänderungen bei Löhnen und Gehältern, Mieten, Lebensmitteln, Wasser, Energie oder Abfallbeseitigung. Cultina wird dem Kunden derartige Preiserhöhungen zumindest sechs Wochen vor deren Wirksamwerden schriftlich oder in Textform ankündigen. Cultina hat den Umfang, den Anlass und die Bestandteile einer Preisanpassung in der dem Kun-den zugeschickten Email bzw. dem Schreiben nachvollziehbar darzulegen. Der Kunde ist in diesem Fall berechtigt, das Abonnement gegenüber Cultina in Textform oder schriftlich zu kündigen. Die Kündigungserklärung muss zu ihrer Wirksamkeit mindestens zwei Wochen vor dem Tag des Wirksamwerdens einer Preiserhöhung bei Cultina eingegangen sein. <br>
    <br>
    9.  Der monatlich zu zahlende Betrag berechnet sich auf der Grundlage eines Jahresbetrages, der gleichmäßig auf 12 Monate aufgeteilt wird bzw. auf der Grundlage eines Halbjahresbetrages, der gleichmäßig auf 6 Monate aufteilt wird.  Die Berechnung erfolgt inklusive aller Ferien und sonstiger unterrichtsfreier Schultage.<br>
    <br>
    10.  Der Vertrag über Schulverpflegung kann von beiden Parteien ohne Angabe von Gründen schriftlich per Post, per E-Mail oder über die Internetseite des Kundenkontos gekündigt werden. Eine Kündigung des Abonnements ist mit einer Frist von 4 Wochen zum Ende eines Schulhalbjahres; zum Ende der Sommerferien sowie dann, wenn der/ die Essensteilnehmer /in die Schule planmäßig zum Ende des Schuljahres verlässt möglich.<br>
    <br>
    Verlässt ein/e Essensteilnehmer/in die Schule während eines laufenden Schuljahres, so kann der Vertrag auch zum Ultimo desjenigen Monats gekündigt werden, in dem der/die Essensteilnehmer/in ausscheidet (Bescheinigung der Schule muss vorliegen). <br>
    <br>
    11.  Für Verträge, die ab dem Stichtag 01.03.2022 geschlossen worden sind, gilt, dass der Kunde, sofern sich der Vertrag stillschweigend verlängert hat, den Vertrag monatlich kündigen kann. Er kann per Post, per E-Mail oder über die Internetseite des Kundenkontos gekündigt werden. <br>
    Der Betrag, der auf Grund der Aufteilung nach Ziff. 9 der AGB anteilig auf die Ferienzeit und sonstige unterrichtsfreie Tage entfällt, ist vom Kunden nach der Kündigung auszugleichen. Dieser wird dem Kunden nach Erhalt der Kündigung von Cultina in Rechnung gestellt.<br>
    <br>
    12.  Kommt der Kunde nach Zugang einer Zahlungsaufforderung der Cultina seinen Zahlungsverpflichtungen nicht nach, ist Cultina berechtigt, den Vertrag über die Mittags-verpflegung aus wichtigem Grund und ohne Einhaltung einer Frist zu kündigen. <br>
    Bei Nichtzahlung des Abo-Preises versendet Cultina insgesamt drei Mahnungen und erhebt ab Verzugseintritt Mahngebühren. Erfolgt auch auf die dritte Mahnung hin keine Zahlung, wird ein Inkasso-Unternehmen mit der Durchsetzung von Cultinas <br>
    Forderungen beauftragt. <br>
    <br>
    13.  Dieser Vertrag wird darüber hinaus automatisch beendet, wenn der Rahmenvertrag zwischen dem Schulträger und Cultina beendet wird, worüber Cultina Sie unverzüglich informieren wird. Cultina wird in diesem Fall ein zum Zeitpunkt des Wirksamwerdens der Kündigung noch bestehendes Guthaben auf ein von Ihnen zu benennendes Bankkonto überweisen.<br>
    <br>
    14.  Sofern ein bei Cultina geführtes Kundenkonto nach Beendigung des Abonnements ein Guthaben aufweist und Cultina, ohne dass dies von ihr zu vertreten ist, die aktuelle Bankverbindung des Kunden nicht bekannt ist, wird Cultina den Kunden zumindest zweimal in Textform mit einem Abstand von zumindest vier Wochen auffordern, ihr eine gültige Bankverbindung zu benennen, damit Cultina das Guthaben zurückerstatten kann. Kommt der Kunde diesen Aufforderungen nicht nach, ist Cultina nach Ab-lauf von zwölf Monaten nach Beendigung des Abonnements berechtigt, das auf dem Konto befindliche Guthaben Dritten für wohltätige Zwecke zu spenden. Ein Rückforderungsanspruch des Kunden ist nach Ablauf dieser zwölf Monate ausgeschlossen.<br>
    <br>
    15.  Die Gewährleistung und Haftung der Cultina richtet sich nach den gesetzlichen Vorschriften, insbesondere denen des Bürgerlichen Gesetzbuches.<br>
    <br>
    16.  Änderungen oder Ergänzungen bedürfen zu ihrer Wirksamkeit der Schriftform. Mündliche oder schriftliche Nebenabreden bestehen nicht.<br>
    <br>
    17.  Alle personenbezogenen Daten werden streng vertraulich und im Einklang mit daten-schutzrechtlichen Vorschriften, insbesondere der EU-Datenschutz-Grundverordnung,  erhoben und nur zum Zwecke der Registrierung, Vertragsabwicklung sowie der bar-geldlosen Abrechnung verarbeitet und genutzt. Wir verweisen insoweit auf die gesonderte Datenschutzerklärung.<br>
    <br>
    VIVENO Group GmbH – Cultina<br>
    Kirchstr. 27<br>
    33330 Gütersloh<br>
    Tel.: 05241-877330<br>
    Email: info@cultina.de            Stand März 2022<br>
    <br>
  </div>
</template>

<script>
export default {
  name: 'legal-privacy'
}
</script>
<style lang="postcss" scoped>
  h2{
    font-size: 16px;
    margin-top: 15px;
    font-weight: normal;
    line-height: 20px;
    color: #4270AA;
    border-bottom: 1px solid #4270AA;
  }
  a {
    color: #4270AA;
    text-decoration: underline;
  }
  ul {
    @apply list-disc pl-8 mt-4 mb-4
  }
  p{
    @apply mt-4 mb-4
  }
</style>
