
import { computed, defineComponent, inject, PropType, ref, toRef } from 'vue'
import BasePrice from '@/components/base/ultilty/BasePrice.vue'
import { useModal } from '@plugin/modal/modal'
import { Statement } from '@interface/entity/statement'
import AssignStatementModal from '@module/accounting_backend/components/AssignStatementModal.vue'
import BModal from '@plugin/modal/BModal.vue'
import Api from '@/api'

export default defineComponent({
  components: { BModal, AssignStatementModal, BasePrice },
  props: {
    statement: {
      type: Object as PropType<Statement>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const statement = toRef(props, 'statement')
    const reload = inject<Function>('reloadPage', () => {})
    const loading = ref(false)
    const { modal } = useModal()
    const isLoading = computed(() => loading.value || props.loading)
    const assignModalKey: string = 'assign_user_statemnent' + statement.value.id
    const ignoreModalKey: string = 'ignore_user_statemnent' + statement.value.id
    const triggerAssign = () => {
      modal(assignModalKey)
    }
    const triggerIgnore = () => {
      modal(ignoreModalKey)
    }
    const ignoreTransaction = async () => {
      loading.value = true
      await Api.statement.ignore(statement.value.id)
      await reload()
      loading.value = false
    }
    return {
      isLoading,
      ignoreTransaction,
      triggerIgnore,
      triggerAssign,
      assignModalKey,
      ignoreModalKey
    }
  }
})
