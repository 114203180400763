<template>
  <div class="flex flex-col space-y-4">
    <div class="flex flex-row items-center space-x-4 p-4">
      <div class="text-xl">
        Nutzer Übersichts
      </div>
    </div>
    <base-paginator url="/api/transactions" :show-pagination="false" :defaults="{'order[time]': 'DESC', 'discr': 'normal'}" :show-meta="false" ref="paginator">
      <template v-slot="{ list, loading }">
        <div class="card bg-base-100 shadow shadow-xl">
          <div class="card-body">
            <base-paginator-search />
            <table class="table w-full">
              <thead>
                <tr>
                  <th>Datum & Uhrzeit</th>
                  <th>Kunde</th>
                  <th>Art des Vorgangs</th>
                  <th>Grund der Transaktion</th>
                  <th>Betrag</th>
                </tr>
              </thead>
              <tbody>
                <tr  :key="i" v-for="i in list">

                </tr>
                <tr :key="transaction.id" v-for="transaction in list">
                  <template v-if="loading">
                    <td :key="i" v-for="i in 5">
                      <div class="rounded-xl animate-pulse bg-neutral h-2 w-full"></div>
                    </td>
                  </template>
                  <template v-if="!loading">
                    <td>
                      <base-date :from="transaction.time"></base-date>
                    </td>
                    <td>
                      {{ transaction.customer.firstName }} {{ transaction.customer.lastName}}
                    </td>
                    <td>
                      {{ transaction.amount < 1 ? 'Abbuchung' : 'Gutschrift'}}
                    </td>
                    <td>
                      {{ transaction.reason }}
                    </td>
                    <td>
                      <base-price :price="transaction.amount"></base-price>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
            <base-paginator-pagination></base-paginator-pagination>
          </div>
        </div>
      </template>
    </base-paginator>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import BasePrice from '@/components/base/ultilty/BasePrice'

export default defineComponent({
  name: 'transaction-internal',
  components: { BasePrice }
})
</script>
