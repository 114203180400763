
import { Module } from 'vuex'
import { RootState } from '../interfaces'
import { RegistrationState } from './interfaces'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: RegistrationState = {
  user: null,
  step: 1,
  loading: false,
  submitted: false
}

export const registration: Module<RegistrationState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
