
import { defineComponent, PropType, inject } from 'vue'
import { useForm } from 'vee-validate'
import { User } from '@interface/entity/user'
import { Notification } from '@interface/enums/Notification'
import Api from '@/api'
import * as yup from 'yup'

export default defineComponent({
  name: 'user-table-row',
  data () {
    const user = this.user
    return {
      isFetching: false,
      email: user.email,
      name: user.name,
      edit: false
    }
  },
  inject: ['replaceItemInResponse'],
  i18n: {
    messages: {
      'de-DE': {
        deleted: {
          title: '{name} wurde gelöscht.',
          text: 'Der Nutzer wurde permanent gelöscht.'
        },
        activation: {
          enable: {
            title: '{name}´s Zugang wurde aktiviert.',
            text: 'Der Nutzer kann sich einloggen.'
          },
          disable: {
            title: '{name}´s Zugang wurde deaktiviert.',
            text: 'Der Nutzer kann sich nicht mehr einloggen, bis dieser wieder freigeschaltet worden ist.'
          }
        }
      }
    }
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const schema: any = yup.object().shape({
      mail: yup.string().email().required(),
      name: yup.string().required()
    })
    const { meta } = useForm({ validationSchema: schema })
    const replaceItemInResponse: Function = inject('replaceItemInResponse') ?? (() => {})
    const reloadPaginator: Function = inject('reloadPage') ?? (() => {})
    return {
      replaceItemInResponse,
      reloadPaginator,
      meta
    }
  },
  methods: {
    async save () {
      this.edit = false
    },
    async deleteUser () {
      if (!this.user.id) { return }
      const name = this.user.name
      await Api.user.delete(this.user.id)
      this.emitter.emit('modal:close', this.user.id)
      this.$notify({
        title: this.$t('deleted.title', { name }),
        text: this.$t('deleted.text', { name }),
        type: Notification.SUCCESS
      })
      this.reloadPaginator()
    },
    async toggleState () {
      if (this.isBusy) { return }
      if (!this.user?.id) { return }
      const wasActive = this.user.isActive
      const { data } = await Api.user.toggle(this.user.id)
      this.replaceItemInResponse(this.user.id, data)
      this.edit = false
      this.$notify({
        title: this.$t(wasActive ? 'activation.disable.title' : 'activation.enable.title', { name: this.user.name }),
        text: this.$t(wasActive ? 'activation.disable.text' : 'activation.enable.text', { name: this.user.name }),
        type: wasActive ? Notification.ERROR : Notification.SUCCESS
      })
    }
  },
  computed: {
    isCurrentUser () {
      if (!this.loggedInUser) {
        return false
      }
      return this.loggedInUser.id === this.user.id
    },
    loggedInUser (): User {
      return this.$store.getters['login/user']
    },
    isBusy (): boolean {
      return this.loading || this.isFetching
    }
  }
})
