<template>
  <div class="collapse w-full" :class="{'collapse-open': collapsed, 'collapse-close': !collapsed}">
    <div class="collapse-title p-0 cursor-pointer min-h-0">
      <slot name="header" :toggle="toggle" :open="collapsed">
        <div class="flex flex-row p-2 items-center content-between">
          <div class="flex-1 flex flex-row items-center" @click="toggle">
            {{headline}}
          </div>
        </div>
      </slot>
    </div>
    <div class="collapse-content" v-auto-animate v-show="collapsed">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'base-collapse',
  data () {
    return {
      collapsed: false
    }
  },
  props: {
    collapse: {
      type: Boolean,
      default: false
    },
    headline: {
      type: String,
      default: ''
    }
  },
  beforeMount () {
    this.collapsed = this.collapse
  },
  methods: {
    toggle () {
      this.collapsed = !this.collapsed
      this.$emit('toggle', { collapsed: this.collapsed })
    },
    open () {
      this.collapsed = true
    },
    close () {
      this.collapsed = false
    }
  },
  watch: {
    collapse (newValue) {
      this.collapsed = newValue
    }
  }
}
</script>
