
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      loading: false
    }
  },
  async mounted () {
    this.loading = true
    try {
      await this.$store.dispatch('initialPayload')
      this.loading = false
    } catch (e) {
      this.$router.push({ name: 'post_login' })
    }
    this.loading = false
  }
})
