<template>
    <div class="dropdown">
      <div class="indicator">
        <span class="indicator-item indicator-top  badge badge-primary px-1 py-2 mr-2" v-if="filterValue !== ''"><CheckIcon class="w-4" /></span>
        <label tabindex="0" class="btn">{{filter.label}} <XIcon class="ml-2" @click="removeSelfFunction(filter)"/></label>
      </div>
      <div tabindex="0" class="dropdown-content menu p-4 mt-2 rounded-box shadow bg-base-100 w-96">
         <div class="mb-2">
           Filtere nach:
         </div>
        <div class="flex flex-row">
          <select v-if="filter.type === 'select'" v-model="filterValue" @change="blurEvent" class="select select-bordered max-w-xs select-sm">
            <option value="" selected>Nichts</option>
            <option v-for="item in filter.values" :key="item.value" :value="item.value">{{item.display}}</option>
          </select>
          <input v-else :type="filter.type" v-model="filterValue" placeholder="Filter.." class="input input-sm input-bordered w-full max-w-xs ml-2" @blur="blurEvent">
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    removeSelfFunction: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      filterValue: ''
    }
  },
  methods: {
    blurEvent () {
      this.$emit('filterChange', { property: this.filter.property, value: this.filterValue, rule: this.filterRule })
    }
  }
}
</script>
