
import { defineComponent } from 'vue'
import { Notification } from '@interface/enums/Notification'
export default defineComponent({
  name: 'base-notification',
  data () {
    return {}
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      default: () => {}
    },
    level: {
      type: String,
      required: true,
      validator (value: Notification) {
        // The value must match one of these strings
        return [Notification.WARNING, Notification.SUCCESS, Notification.INFO, Notification.ERROR].includes(value)
      }
    }
  },
  methods: {}
})
