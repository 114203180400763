import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-409bde81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row space-x-4 justify-center" }
const _hoisted_2 = { class: "label cursor-pointer flex-0" }
const _hoisted_3 = { class: "label-text" }
const _hoisted_4 = { class: "days flex-1" }
const _hoisted_5 = { class: "steps w-full" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "label-text-alt text-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1)
    ]),
    _createVNode(_component_Field, {
      name: _ctx.name,
      modelValue: _ctx.vModel,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vModel) = $event))
    }, {
      default: _withCtx(({ errorMessage, errors }) => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("ul", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.max, (n) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["step step-neutral cursor-pointer", {
          'step-error': errors.length > 0,
          'step-disabled':  n > _ctx.activeSteps,
          'step-primary': n <= _ctx.vModel
        }]),
                onClick: ($event: any) => (_ctx.setAmount(n)),
                key: n
              }, null, 10, _hoisted_6))
            }), 128))
          ])
        ]),
        _withDirectives(_createElementVNode("label", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(errorMessage), 1)
        ], 512), [
          [_vShow, !!errorMessage]
        ])
      ]),
      _: 1
    }, 8, ["name", "modelValue"])
  ]))
}