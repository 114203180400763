<template>
  <div id="login">
    <h1>Impressum</h1>
    <h2>Für die Inhalte dieser Seite verantwortlich ist</h2>
    <p>
      VIVENO Group GmbH / Cultina<br>
      Kirchstraße 27<br>
      33330 Gütersloh
    </p>

    <p>
      Telefon: 05241 877 - 330<br>
      Fax: 05241 877 - 333<br>
      Internet: <a href="http://www.cultina.de" target="_blank">www.cultina.de</a><br>
      E-Mail: <a href="mailto:info@sg-mensa.de">info@cultina.de</a>
    </p>

    <p>
      Geschäftsführer: Sönke Hendrik Tuchel und Dr. Hartmut Klein
    </p>

    <p>
      Registergericht: Amtsgericht Gütersloh<br>
      Registernummer: HRB 3613
    </p>

    <p>
      Steuer-Nr. 351/5900/0010
    </p>

    <p>
      Umsatzsteuer-Identifikationsnummer gemäß P. 27a Umsatzsteuergesetz:<br>
      DE 205 514 274
    </p>

    <p>
      Inhaltlich Verantwortlicher gemäß P. 10 Absatz 3 MDStV:<br>
      Sönke Hendrik Tuchel (Anschrift wie oben)
    </p>

    <p>
      Zuständige Behörde: Ordnungsamt Gütersloh
    </p>
</div>
</template>

<script>
export default {
  name: 'lega-imprint'
}
</script>

<style lang="postcss" scoped>
  h2{
    font-size: 16px;
    margin-top: 15px;
    font-weight: normal;
    line-height: 20px;
    color: #4270AA;
    border-bottom: 1px solid #4270AA;
  }
  a {
    color: #4270AA;
    text-decoration: underline;
  }
  ul {
    @apply list-disc pl-8 mt-4 mb-4
  }
  p{
    @apply mt-4 mb-4
  }
</style>
