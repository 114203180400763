
import { defineComponent } from 'vue'
import vModel from '@/mixins/vModel'

interface Data {
  alphabet: Array<string>
}
export default defineComponent({
  name: 'group-letter-select',
  mixins: [vModel],
  props: {
    modelValue: String
  },
  data (): Data {
    return {
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    }
  }
})
