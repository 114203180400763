import { RouteRecordRaw, Router } from 'vue-router'
import DefaultLayout from '@layout/default.vue'
import IndexView from '@module/accounting_backend/views/internal.vue'
import DepositView from '@module/accounting_backend/views/deposits.vue'
import AssignView from '@module/accounting_backend/views/assignable.vue'

const routes : Array<RouteRecordRaw> =
  [
    {
      path: 'internal',
      name: 'transactions_internal',
      component: IndexView
    },
    {
      path: 'deposits',
      name: 'transactions_deposit',
      component: DepositView
    },
    {
      path: 'assignable',
      name: 'transactions_assign',
      component: AssignView
    }
  ]

const record : RouteRecordRaw = {
  path: 'transaction/',
  name: 'transaction_root',
  children: routes,
  component: DefaultLayout
}

export default (router: Router) => {
  router.addRoute('backend_root', record)
}
