
import { defineComponent, PropType } from 'vue'
import { Client } from '@interface/entity/client'

export default defineComponent({
  name: 'client-price-history',
  components: {},

  props: {
    client: {
      type: Object as PropType<Client>
    }
  },
  computed: {

  }
})
