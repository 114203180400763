<template>
  <h1 class="mb-8">Kundenübersicht</h1>
  <base-paginator url="/api/customers">
    <template #sorterItem="{ item }">
      {{$t('sorting.' + item.key)}}
    </template>
    <template #filterOption="{ filter, addFilter }">
      <a @click="addFilter(filter)">
        {{$t('filter.' + filter.key)}}
      </a>
    </template>
    <template v-slot="{ list, loading }">
      <div class="flex w-full mb-4 pt-4 space-x-4">
        <education-card-filter id="hasEducationCard" :label="$t('filter.hasEducationCard')"></education-card-filter>
        <base-filter-input id="consumers.grade" :label="$t('filter.consumers.grade')"></base-filter-input>
        <base-filter-input id="consumers.groupLetter" :label="$t('filter.consumers.groupLetter')" ></base-filter-input>
        <client-paginator-filter id="consumers.client.name" :label="$t('filter.consumers.client.name')"  />
      </div>
      <table class="table w-full">
        <!--          <thead>-->
        <!--            <tr>-->
        <!--              <th>-->
        <!--              </th>-->
        <!--              <th>Kdn. Nr.</th>-->
        <!--              <th>Name</th>-->
        <!--              <th>E-Mail</th>-->
        <!--              <th>Status</th>-->
        <!--              <th></th>-->
        <!--            </tr>-->
        <!--          </thead>-->
        <tbody>
          <template v-if="loading">
            <tr :key="_client.id" v-for="_client in list">
              <td>
                <div class="text-placeholder"></div>
              </td>
              <td>
                <div class="text-placeholder"></div>
              </td>
              <td>
                <div class="text-placeholder"></div>
              </td>
              <td>
                <div class="text-placeholder"></div>
              </td>
              <td>
                <div class="text-placeholder"></div>
              </td>
              <td>
                <div class="text-placeholder"></div>
              </td>
            </tr>
          </template>
          <template v-else>
            <customer-index-table-row :customer="customer" v-for="customer in list" :key="customer.id"></customer-index-table-row>
          </template>

        </tbody>
      </table>
    </template>
  </base-paginator>
</template>
<script>
import BaseFilterInput from '@/components/base/filter/BaseFilterInput'
import CustomerIndexTableRow from '@/modules/customer_backend/components/CustomerIndexTableRow'
export default {
  name: 'customer-index',
  components: { BaseFilterInput, CustomerIndexTableRow },
  i18n: {
    messages: {
      'de-DE': {
        filter: {
          hasEducationCard: 'Bildungskarte',
          consumers: {
            grade: 'Jahgrangsstufe',
            groupLetter: 'Klassenbuschstabe',
            client: {
              name: 'Schule'
            }
          },
          state: 'Status'
        },
        sorting: {
          consumer: {
            grade: 'Jahgrangsstufe',
            groupLetter: 'Klassenbuschstabe',
            client: {
              name: 'Schule'
            }
          }
        }
      }
    }
  }
}
</script>
