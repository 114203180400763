
import { defineComponent, computed, PropType } from 'vue'
import Axios from '@/api/axios'
import { HydraResponse, HydraMapping } from '@interface/api/Response'

interface Data {
  madeInitialRequest: boolean,
  response: HydraResponse | null,
  page: number,
  loading: boolean,
  params: any,
  activeFilters: Array<any>
}

interface MetaRecord {
  key: string,
  urlParameter: string
}

export default defineComponent({
  name: 'base-paginator',
  components: {},
  data (): Data {
    return {
      madeInitialRequest: false,
      response: null,
      loading: false,
      page: 1,
      params: {},
      activeFilters: []
    }
  },
  props: {
    url: {
      type: String,
      required: true
    },
    showMeta: {
      type: Boolean,
      default: true
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    defaults: {
      type: Object as PropType<Record<string, string>>,
      default: null
    }
  },
  methods: {
    setDefaults () {
      if (this.defaults) {
        for (const key in this.defaults) {
          this.params[key] = this.defaults[key]
        }
      }
    },
    addParameter (key:string, value: string | number) {
      this.params[key] = value
      this.load()
    },
    removeParameter (key:string) {
      delete this.params[key]
      this.load()
    },
    replaceItem (id: string, item: any):void {
      if (!this.response) { return }
      const url = this.url.endsWith('/') ? this.url + id : this.url + '/' + id
      const index = this.collection.findIndex((responseItem) => responseItem['@id'] === url)
      if (index === -1) {
        return
      }
      this.response['hydra:member'][index] = {
        ...this.response['hydra:member'][index],
        ...item
      }
    },
    removeFilter (filter:MetaRecord) {
      this.activeFilters = this.activeFilters.filter(item => item.key !== filter.key)
    },
    async load () {
      if (!this.madeInitialRequest) {
        this.madeInitialRequest = true
        this.setDefaults()
      }
      this.loading = true
      const { data } = await Axios.get<HydraResponse>(this.fullUrl, {
        params: {
          page: this.page
        },
        headers: {
          Accept: 'application/ld+json'
        }
      })
      this.response = data
      this.loading = false
    },
    setPage (page: number) {
      if (page === this.page) { return }
      this.page = page
    }
  },
  async mounted () {
    await this.load()
  },
  computed: {
    collection (): Array<any> {
      if (!this.response) { return [] }
      return this.response['hydra:member']
    },
    hasSearch (): boolean {
      return !!this.searchMeta.find((item) => item.urlParameter === 'search')
    },
    uri (): URL {
      const url = new URL(document.location.origin + this.url)
      const keys = Object.keys(this.params)
      if (keys.length === 0) {
        return url
      }
      keys.forEach((key) => {
        url.searchParams.append(key, this.params[key])
      })
      return url
    },
    fullUrl (): string {
      return this.uri.toString().replace(document.location.origin, '')
    },
    searchMeta (): Array<MetaRecord> {
      if (!this.response) { return [] }
      if (!this.response['hydra:search']) { return [] }
      return this.response['hydra:search']['hydra:mapping'].map((item: HydraMapping) => {
        return {
          key: item.property,
          urlParameter: item.variable
        }
      })
    },
    sortingParams () {
      if (this.searchMeta.length === 0) {
        return []
      }
      return this.searchMeta.filter((item) => {
        return item.urlParameter.startsWith('order[')
      })
    },
    filterParams (): Array<MetaRecord> {
      if (this.searchMeta.length === 0) {
        return []
      }
      return this.searchMeta.filter((item) => {
        if (item.urlParameter.startsWith('order[')) { return false }
        return item.urlParameter !== 'search'
      })
    }
  },
  provide () {
    return {
      paginatorResponse: computed(() => this.response),
      paginatorPage: computed(() => this.page),
      activeFilters: computed(() => this.activeFilters ? this.activeFilters : []),
      url: computed(() => this.uri),
      paginatorMeta: computed(() => this.searchMeta),
      paginatorLoading: computed(() => this.loading),
      addParameterToUrl: this.addParameter,
      removeParameterFromUrl: this.removeParameter,
      removeFilter: this.removeFilter,
      setPaginatorPage: this.setPage,
      sortingOptions: computed(() => this.sortingParams),
      replaceItemInResponse: this.replaceItem,
      reloadPage: this.load
    }
  },
  watch: {
    page () {
      this.load()
    }
    // madeInitialRequest () {
    //   this.setDefaults()
    // }
  }
})
