import router from './router'
import store from './store'
import { Store } from 'vuex'
import Dictionary from '@interface/Dictionary'

export interface Module {
  router?: Function,
  store?: Store<any>
}

const registerModule = (name: string, module: Module) => {
  if (module.store) {
    store.registerModule(name, module.store)
  }

  if (module.router) {
    module.router(router)
  }
}

export const registerModules = (modules: Dictionary<Module>) => {
  Object.keys(modules).forEach(moduleKey => {
    const module = modules[moduleKey]
    registerModule(moduleKey, module)
  })
}
