
import { computed, defineComponent, ref } from 'vue'
import Api from '@/api'
import DashboardCalltoaction from '@module/customer_frontend/components/DashboardCalltoaction.vue'
import store from '@/store'
import { CustomerMutations } from '@store/me/mutations'
import { mapGetters, useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { Notification } from '@interface/enums/Notification'
import { useI18n } from 'vue-i18n'
import { useTimeoutFn } from '@vueuse/core'
import { Form } from 'vee-validate'

export default defineComponent({
  name: 'me-consumer',
  beforeRouteEnter (to) {
    store.commit(`me/${CustomerMutations.SET_CONSUMER}`, to.params.consumer)
    // this.$store.commit()
  },
  setup () {
    const store = useStore()
    const { t } = useI18n({
      useScope: 'global',
      messages: {
        'de-DE': {
          alert: {
            save: {
              title: 'Gespeichert',
              content: 'Ihre Änderungen wurden erfolgreich übernommen'
            }
          }
        }
      }
    })
    const consumer = computed(() => store.getters['me/currentConsumer'])
    const isLoading = ref(false)
    const { start, stop, isPending } = useTimeoutFn(async () => {
      isLoading.value = true
      await Api.consumer.update(consumer.value.id as string, {
        firstName: consumer.value.firstName,
        lastName: consumer.value.lastName,
        grade: consumer.value.grade,
        groupLetter: consumer.value.groupLetter,
        additionalPurchase: consumer.value.additionalPurchase,
        additionalPurchaseLimit: consumer.value.hasPurchaseLimit ? consumer.value.additionalPurchaseLimit : null
      })
      const { data } = await Api.security.current()
      store.commit('login/set_user', data)
      notify({
        type: Notification.SUCCESS,
        title: t('alert.save.title'),
        text: t('alert.save.content')
      })
      isLoading.value = false
    }, 300, {
      immediate: false
    })
    return {
      consumer,
      isLoading,
      updateConsumer: start,
      cancelUpdate: stop,
      isPending
    }
  },
  data () {
    return {
      active: false
    }
  },
  components: {
    CallToAction: DashboardCalltoaction,
    Form
  },
  methods: {
    submit () {
      if (this.isPending) {
        this.cancelUpdate()
      }
      this.updateConsumer()
    },
    handleSubscription () {
      console.log('POW')
    },
    toggleAdditionalPurchase () {
      if (this.isPending) {
        this.cancelUpdate()
      }
      this.consumer.additionalPurchase = !this.consumer.additionalPurchase
      this.updateConsumer()
    }
  },
  mounted () {
    setTimeout(() => {
      this.active = true
    }, 100)
  },
  unmounted () {
    this.active = false
  },
  computed: {
    ...mapGetters('me', {
      currentConsumer: 'currentConsumer',
      customer: 'instance'
    }),
    isLoaded (): boolean {
      return !!this.currentConsumer && this.active
    },
    purchaseLimit: {
      get (): any {
        if (!this.isLoaded) { return '' }
        if (!this.consumer.hasPurchaseLimit) {
          return ''
        }
        return this.consumer.additionalPurchaseLimit > 0 ? this.consumer.additionalPurchaseLimit : ''
      },
      set (value) {
        if (!this.consumer) { return '' }
        this.consumer.additionalPurchaseLimit = value
      }
    }
  }
})
