<template>
  <div class="flex flex-col space-y-4">
    <div class="flex flex-row items-center space-x-4 p-4">
      <div class="text-xl">
        Zuzuweisende Buchungen
      </div>
    </div>
    <base-paginator url="/api/transfer_hub_statements" :show-pagination="false" :defaults="defaultParamters" :show-meta="false" ref="paginator">
      <template v-slot="{ list, loading }">
        <div class="card bg-base-100 shadow shadow-xl">
          <div class="card-body">
            <!--            <base-paginator-search />-->
            <table class="table w-full">
              <thead>
                <tr>
                  <th>Datum</th>
                  <th>Typ</th>
                  <th>Kontoinhaber</th>
                  <th>Kontonummer</th>
                  <th>Verwendungszweck</th>
                  <th>Betrag</th>
                  <th>Zusweisung</th>
                </tr>
              </thead>
              <tbody>
                <statement-tr :loading="loading" :statement="statement" :key="statement.id" v-for="statement in list"></statement-tr>
              </tbody>
            </table>
            <base-paginator-pagination></base-paginator-pagination>
          </div>
        </div>
      </template>
    </base-paginator>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import StatementTr from '@module/accounting_backend/components/StatementTr.vue'

export default defineComponent({
  name: 'transaction-assignable',
  components: { StatementTr },
  setup () {
    return {
      defaultParamters: {
        'order[date]': 'DESC',
        'exists[customer]': 'false',
        ignored: 'false',
        'type[EXCEPT]': ['CASH CONCENTRATING BUCHUNG', 'GEBUEHREN', 'ABSCHLUSS'].join(',')
      }
    }
  }
})
</script>
