
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'progress-bar',
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    max: {
      type: [Number, String],
      default: 100
    }
  },
  computed: {
    percentage (): number {
      const value = typeof this.value === 'string' ? parseInt(this.value) : this.value
      const max = typeof this.max === 'string' ? parseInt(this.max) : this.max
      return (value / max) * 100
    }
  }
})
