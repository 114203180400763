<template>
  <div class="form-control w-full">
    <label class="label" for="clients">
      <span class="label-text">Schule</span>
    </label>
    <vue-multiselect v-bind="multiselect" :class="{'multiselect__invalid': isInvalid }" :options="options" v-model="vModel">
      <template #caret>
        <div class="multiselect__select">
          <CaretDownIcon class="w-full h-full"  />
        </div>
      </template>
      <template #noResult>
        Keine Einträge gefunden. Ändern Sie bitte die Suchanfrage.
      </template>
      <template #singleLabel="{option}">
        <div v-if="option.id">
          <strong>{{ option.name }}</strong> <br>
          <span class="text-neutral text-opacity-70">
            {{ option.street }} <br>
            {{ option.zip }} {{ option.city }} <br>
          </span>
        </div>
        <div v-else>
          {{ multiselect.placeholder }}
        </div>
      </template>
      <template #option="{ option }">
        <div class="flex flex-col space-y-2">
          <strong>{{ option.name }}</strong>
          <span class="text-opacity-50">
            {{option.zip}} {{ option.city }}
          </span>
        </div>

      </template>
    </vue-multiselect>
    <label class="label" v-show="!!errorMessage">
      <span class="label-text-alt text-error">{{ errorMessage }}</span>
    </label>
  </div>
</template>
<script>
import Api from '@/api'
import { mapGetters } from 'vuex'
import { useField } from 'vee-validate'
import { toRef } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'client-select',
  data () {
    return {
      options: [],
      state: null,
      isLoading: false
    }
  },
  setup (props) {
    const name = toRef(props, 'name')
    const {
      value: inputValue,
      errorMessage,
      errors
    } = useField(name, props.required ? 'required' : '', {
      initialValue: props.modelValue
    })
    return {
      errors,
      errorMessage,
      inputValue
    }
  },
  props: {
    name: {
      type: String,
      default: () => uuidv4()
    },
    modelValue: [Object, String],
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selectMode: {
      type: [Object, String],
      default: 'client',
      validator: (value) => {
        return ['client', 'id', 'uri'].includes(value)
      }
    }
  },
  async beforeMount () {
    this.isLoading = true
    const clients = Object.values(this.clients)
    if (clients.length) {
      this.options = clients
    } else {
      const { data } = await Api.client.frontend()
      this.options = data
    }
    this.isLoading = false
  },
  methods: {
    limitText (count) {
      return `und ${count} andere Schulen`
    }
  },
  computed: {
    ...mapGetters('cache', ['clients', 'apiClients']),
    isInvalid () {
      return this.errors.length > 0
    },
    vModel: {
      get () {
        return this.client
      },
      set (value) {
        this.inputValue = value
        if (!value) {
          this.$emit('update:modelValue', null)
          return
        }
        switch (this.selectMode) {
          case 'id':
            if (this.multiple) {
              value = value.map(val => val.id)
            } else {
              value = value.id
            }
            break
          case 'uri':
            if (this.multiple) {
              value = value.map(val => Api.client.getUri(val.id))
            } else {
              value = Api.client.getUri(value.id)
            }
            break
          case 'client':
          default:
            break
        }
        this.$emit('update:modelValue', value)
      }
    },
    client () {
      const resolve = (val) => {
        if (typeof val === 'object') {
          return val
        }
        if (this.clients[val]) {
          return this.clients[val]
        }
        if (this.apiClients[val]) {
          return this.apiClients[val]
        }
        return null
      }
      return this.multiple ? this.modelValue.map(resolve) : resolve(this.modelValue)
    },
    multiselect () {
      return {
        name: 'clients',
        label: 'name',
        trackBy: 'id',
        placeholder: 'Geben Sie eine Schule an',
        openDirection: 'bottom',
        searchable: true,
        loading: this.isLoading,
        internalSearch: true,
        clearOnSelect: true,
        closeOnSelect: true,
        optionsLimit: 300,
        limit: this.multiple ? Math.max(this.options.length, 100) : 3,
        multiple: this.multiple,
        disabled: this.disabled,
        limitText: this.limitText,
        maxHeight: 200,
        showNoResults: false,
        hideSelected: false,
        tagPlaceholder: '',
        selectLabel: '',
        selectGroupLabel: '',
        selectedLabel: '',
        deselectLabel: '',
        deselectGroupLabel: ''
      }
    }
  }
}
</script>
