
import { defineComponent } from 'vue'
import { NfcTokenScanEvents } from '@interface/events/NfcTokenScanEvents'

export default defineComponent({
  i18n: {
    messages: {
      'de-DE': {
        title: 'Bereit zum Scannen',
        content: 'Sie können den Chip jetzt an das Gerät halten, um diesen zu scannen.',
        cancel: 'Abbrechen'
      }
    }
  },
  name: 'nfc-scan',
  data () {
    return {
      showModal: false,
      loading: false,
      isFocused: false
    }
  },
  mounted () {
    this.emitter.on(NfcTokenScanEvents.TRIGGER, () => {
      if (this.showModal) { return }
      this.showModal = true
      this.$nextTick(() => {
        setTimeout(this.focus, 300)
      })
    })
  },
  methods: {
    focus () {
      this.isFocused = true
      this.$nextTick(() => {
        (this.$refs.inputField as HTMLInputElement).focus()
      })
    },
    cancel () {
      this.isFocused = false
      setTimeout(this.close, 300)
    },
    submit (evt: Event) {
      const inputField: HTMLInputElement = evt.target as HTMLInputElement
      this.emitter.emit(NfcTokenScanEvents.RECEIVE, inputField.value)
      this.isFocused = false
      inputField.value = ''
      setTimeout(this.close, 300)
    },
    close () {
      this.showModal = false
      this.loading = false
    }
  },
  computed: {

  }
})
