import { ActionTree } from 'vuex'
import { RootState } from '../interfaces'
import { CacheState } from './interfaces'
import { CacheMutations } from './mutations'
import Api from '@/api'
import Axios from '@/api/axios'

export const actions: ActionTree<CacheState, RootState> = {
  async fetchClients ({ commit }) {
    const { data } = await Api.client.all()
    commit(CacheMutations.SET_CLIENTS, data)
  },
  async fetchReports ({ commit }) {
    const { data } = await Axios.get(Api.createEndPointUrl('api_report_list'))
    commit(CacheMutations.SET_REPORTS, data)
  }
}
