
import BModal from '@plugin/modal/BModal.vue'
import { defineComponent, inject, PropType } from 'vue'
import { Statement } from '@interface/entity/statement'
import { Customer } from '@interface/entity/customer'
// import Api from '@/api'

interface Data {
  selectedCustomer: Customer | null
  loading: Boolean
}
export default defineComponent({
  components: { BModal },
  data (): Data {
    return {
      selectedCustomer: null,
      loading: false
    }
  },
  setup () {
    const reloadPage = inject<Function>('reloadPage', () => {})
    return {
      reloadPage
    }
  },
  props: {
    statement: {
      required: true,
      type: Object as PropType<Statement>
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    async assignTransaction () {
      if (!this.selectedCustomer) {
        return
      }
      this.loading = true
      // await Api.statement.assign(this.statement.id, this.selectedCustomer.id as string)
      await this.reloadPage()
      this.loading = false
      this.$modal.close(this.name)
    },
    selectCustomer (customer: Customer) {
      if (this.selectedCustomer === null) {
        this.selectedCustomer = customer
      } else {
        this.selectedCustomer = customer.id !== this.selectedCustomer.id ? customer : null
      }
    },
    nfcChips (customer: Customer) {
      return customer.consumers.reduce((acc, consumer) => {
        const chips = consumer.nfcTokens.filter((chip) => chip.state === 'activated')
        if (!chips.length) {
          return acc
        }
        return chips.reduce((dacc, chip) => dacc + (dacc !== '' ? ', ' : '') + chip.chipNumber, '')
      }, '')
    },
    kdNumbers (customer: Customer) {
      return customer.consumers.reduce((acc, consumer) => {
        return acc + (acc !== '' ? ', ' : '') + consumer.kdNumber
      }, '')
    },
    consumers (customer: Customer) {
      return customer.consumers.reduce((acc, consumer) => {
        return acc + (acc !== '' ? ', ' : '') + `${consumer.firstName} ${consumer.lastName}`
      }, '')
    }
  }
})
