<template>
  <div class="form-control" :class="{'honeypot': honeypot }">
    <label class="label" v-if="label" :for="id">
      <span class="label-text" :class="{'text-opacity-50': disabled}" >{{ label }}</span>
    </label>

    <div class="flex" :class="{'flex-col space-y-4': !horizontal, 'flex-row space-x-4': horizontal}">
      <label class="flex flex-row space-x-2 py-3 items-center" :class="{'cursor-not-allowed': disabled, 'cursor-pointer': !disabled}" :key="index" v-for="(option,index) in options">
        <Field :name="name" class="radio" :disabled="disabled" :class="{'radio-error': errors.length > 0}" v-model="inputValue" :value="option[optionValue]" type="radio" />
        <span  class="label-text" :class="{'text-error': errors.length > 0, 'text-opacity-50': disabled}"> {{ option[optionLabel] }} </span>
      </label>
    </div>
    <label class="label" v-show="!!errorMessage">
      <span class="label-text-alt text-error">{{ errorMessage }}</span>
    </label>
  </div>
</template>

<script>
import BaseInput from './BaseInput'
import { useField } from 'vee-validate'
import { v4 as uuidv4 } from 'uuid'
import { toRef } from 'vue'
export default {
  name: 'base-choice',
  extends: BaseInput,
  props: {
    horizontal: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      required: true
    },
    optionValue: {
      type: String,
      default: 'value'
    },
    optionLabel: {
      type: String,
      default: 'label'
    }
  },
  setup (props) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validte needs to know if the input name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, 'name')

    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value: inputValue,
      errorMessage,
      errors,
      meta
    } = useField(name, props.rules, {
      initialValue: props.modelValue
    })
    return {
      errorMessage,
      errors,
      inputValue,
      meta,
      id: uuidv4()
    }
  },
  watch: {
    inputValue (newValue) {
      this.$emit('update:modelValue', newValue)
    }
  }
}
</script>

<style scoped>

</style>
