
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { Notification } from '@interface/enums/Notification'
export default defineComponent({
  name: 'page-header',
  computed: {
    ...mapGetters('login', ['customer', 'user']),
    placeHolder (): string {
      if (!this.customer && !this.user) { return '' }
      if (this.customer) {
        return this.customer.firstName[0].toUpperCase() + ' ' + this.customer.lastName[0].toUpperCase()
      }
      return this.user.name.split(' ', 2).map((val:string) => val[0].toUpperCase()).join('')
    }
  },
  methods: {
    ...mapActions('login', ['logout']),
    async logOut () {
      await this.logout()
      this.$notify({
        title: 'Sie wurden erfolgreich ausgeloggt!',
        type: Notification.SUCCESS
      })
      this.$router.push({ name: 'login_index' })
    }
  }
})
