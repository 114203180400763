import { RouteRecordRaw, Router } from 'vue-router'
import DefaultLayout from '@layout/default.vue'
import IndexView from '@module/user_backend/views/index.vue'

const routes : Array<RouteRecordRaw> =
  [
    {
      path: '',
      name: 'user_index',
      component: IndexView
    }
  ]

const record : RouteRecordRaw = {
  path: 'users/',
  name: 'user_root',
  children: routes,
  component: DefaultLayout
}

export default (router: Router) => {
  router.addRoute('backend_root', record)
}
