
import { Form } from 'vee-validate'
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'
import { defineComponent } from 'vue'
import Api from '@/api'
import Client from '@/api/axios'
import { Consumer } from '@interface/entity/consumer'
import { Notification } from '@interface/enums/Notification'
import { NfcTokenScanEvents } from '@interface/events/NfcTokenScanEvents'
import { NfcToken } from '@interface/entity/nfctoken'
import { EducationCard } from '@interface/entity/educationcard'
import ConsumerEducationCardTable from '../components/ConsumerEducationCardTable.vue'
import { Customer } from '@interface/entity/customer'
// import ActivityOverview from '@/components/activity/ActivityOverview.vue'

interface Data {
  customer: Customer | null
  consumer: Consumer | null,
  editConsumer: boolean,
  initialConsumer: Consumer | null,
  isLoading: boolean,
  alphabet: Array<string>
  newChips: Array<NfcToken>,
  educationCardFrom: Date | null
  educationCardTill: Date | null
  educationCardNumber: String
}

export default defineComponent({
  data (): Data {
    return {
      customer: null,
      consumer: null,
      editConsumer: false,
      initialConsumer: null,
      isLoading: false,
      educationCardFrom: null,
      educationCardTill: null,
      educationCardNumber: '',
      newChips: [],
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    }
  },
  components: {
    // ActivityOverview,
    Form,
    ConsumerEducationCardTable
  },
  setup () {
    const route = useRoute()
    return {
      id: route.params.consumer as string
    }
  },
  mounted () {
    this.fetchConsumer()
    this.emitter.on(NfcTokenScanEvents.RECEIVE, (data:string) => {
      if (!this.initialConsumer) { return }
      const chip: NfcToken = {
        id: '',
        chipNumber: '',
        consumer: this.initialConsumer.id,
        tokenId: data,
        state: 'activated'
      }
      this.newChips.push(chip)
    })
  },
  methods: {
    async fetchConsumer () {
      this.isLoading = true
      const id = this.id
      try {
        const { data } = await Api.consumer.get(id)
        const customerResponse = await Client.get(data.customer)
        this.consumer = data
        this.customer = customerResponse.data
        this.initialConsumer = JSON.parse(JSON.stringify(data)) as Consumer
        this.newChips = []
        this.editConsumer = false
        await this.$nextTick()
        this.isLoading = false
      } catch (e) {
        this.$notify({
          title: 'There was a problem fetching the user',
          text: 'Please try again later'
        })
        this.$router.back()
      }
    },
    scanNewChip () {
      if (!this.editConsumer) {
        this.clickEditConsumer()
      }
      this.emitter.emit(NfcTokenScanEvents.TRIGGER)
    },
    clickEditConsumer () {
      if (!this.consumer) { return }
      if (this.editConsumer) {
        console.log('Discard Changes')
        this.initialConsumer = JSON.parse(JSON.stringify(this.consumer))
        this.editConsumer = false
      } else {
        // this.hasPurchaseLimit = !!this.consumer.additionalPurchaseLimit
        this.editConsumer = true
      }
    },
    updateChip (data: any) {
      if (!this.initialConsumer) { return }
      if (this.initialConsumer.nfcTokens.length <= 1) {
        this.initialConsumer.nfcTokens[0] = data.chip
      } else {
        this.initialConsumer.nfcTokens[data.index] = data.chip
      }
    },
    async newEducationCard (val: EducationCard) {
      if (!this.initialConsumer) { return }
      this.$modal.close('card')
      await Api.consumer.newEducationCard(this.initialConsumer.id as string, val)
      await this.fetchConsumer()
    },
    async handleSubscription (values: any) {
      const url = Api.createEndPointUrl('api_subscription_subscribe')
      await Client.post(url, values)
      await this.fetchConsumer()
    },
    async saveConsumer (values: any) {
      if (!this.consumer || !this.initialConsumer) {
        return
      }
      this.isLoading = true
      this.editConsumer = false
      try {
        const { data } = await Api.consumer.update(this.consumer.id as string, {
          firstName: this.initialConsumer.firstName,
          lastName: this.initialConsumer.lastName,
          grade: this.initialConsumer.grade,
          groupLetter: this.initialConsumer.groupLetter,
          additionalPurchase: this.initialConsumer.additionalPurchase,
          additionalPurchaseLimit: this.initialConsumer.hasPurchaseLimit ? this.initialConsumer.additionalPurchaseLimit : null
        })
        this.consumer = data
        this.initialConsumer = JSON.parse(JSON.stringify(this.consumer))
        await this.$nextTick()
        this.$notify({
          title: 'There was a problem fetching the user',
          text: 'Please try again later',
          type: Notification.SUCCESS
        })
      } catch (e) {
        this.$notify({
          title: 'There was a problem saving the user',
          text: 'Please try again later',
          type: Notification.ERROR
        })
      }
      this.isLoading = false
    }
  },
  // watch: {
  //   hasPurchaseLimit (val) {
  //     if (!this.initialConsumer) { return }
  //     if (!this.initialConsumer.additionalPurchaseLimit) { return }
  //     if (!val) {
  //       this.initialConsumer.additionalPurchaseLimit = null
  //     }
  //   }
  // },
  computed: {
    ...mapGetters('cache', ['apiClients']),
    isLoaded (): boolean {
      return !this.isLoading && !!this.consumer
    },
    educationCards (): Array<EducationCard> {
      if (!this.initialConsumer) { return [] }
      return Array.from(this.initialConsumer.educationCards).reverse()
    },
    purchaseLimit: {
      get (): any {
        if (!this.initialConsumer) { return '' }
        if (!this.initialConsumer.hasPurchaseLimit) {
          return ''
        }
        return this.initialConsumer.additionalPurchaseLimit > 0 ? this.initialConsumer.additionalPurchaseLimit : ''
      },
      set (value) {
        if (!this.initialConsumer) { return '' }
        this.initialConsumer.additionalPurchaseLimit = value
      }
    }
  }
})
