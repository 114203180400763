
import { defineComponent, PropType } from 'vue'
import { Customer } from '@interface/entity/customer'
import { Consumer } from '@interface/entity/consumer'

export default defineComponent({
  props: {
    customer: {
      required: true,
      type: Object as PropType<Customer>
    }
  },
  computed: {
    kdNumbers (): string {
      if (!this.customer) { return '' }
      return this.customer.consumers.reduce((acc:string, consumer: Consumer) => {
        return acc + (acc !== '' ? ', ' : '') + consumer.kdNumber
      }, '')
    },
    walletBalance (): Number {
      if (!this.customer) {
        return 0
      }
      if (!this.customer.wallet) {
        return 0
      }
      return this.customer.paymentMethod === 'SEPA' ? Math.abs(this.customer.wallet.balance) : this.customer.wallet.balance
    }
  }
})
