import { createElementVNode as _createElementVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-576cfe07"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-content self-end z-50" }
const _hoisted_2 = { class: "text-xl text-center mb-8" }
const _hoisted_3 = { class: "bg-neutral mx-auto w-44 h-44 text-neutral-content flex justify-center items-center rounded-full" }
const _hoisted_4 = { class: "text-center my-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccessPointIcon = _resolveComponent("AccessPointIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", {'modal-open': _ctx.showModal, 'nfc-ready': _ctx.isFocused}])
  }, [
    _createElementVNode("div", {
      class: "h-full w-full absolute",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args)))
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        ref: "inputField",
        type: "text",
        class: "w-0 h-0 opacity-0",
        onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["enter"]))
      }, null, 544),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('title')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AccessPointIcon, { class: "w-40 h-40" })
      ]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('content')), 1),
      _createElementVNode("button", {
        class: "btn btn-error btn-block",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
      }, _toDisplayString(_ctx.$t('cancel')), 1)
    ])
  ], 2))
}