<template>
  <div class="dropdown" v-show="isActive">
    <div class="indicator">
      <span class="indicator-item indicator-top  badge badge-primary p-0" v-if="filterValue !== ''"><CheckIcon class="w-4" /></span>
      <label tabindex="0" class="btn">
        Bildungskarte
      </label>
    </div>
    <div tabindex="0" class="dropdown-content relative menu p-4 mt-2 rounded-box shadow bg-base-100 w-96">
      <div class="flex flex-row items-center">
        <div>
          {{headline}}
        </div>
        <div class="flex-1"></div>
        <button class="btn btn-ghost btn-circle" @click="remove" title="Filter Entfernen">
          <TrashIcon class="w-6 h-6"/>
        </button>
      </div>
      <div class="flex flex-row items-center">
        <div>
          Nur Personen anzeigen die eine Bildungskarte besitzen
        </div>
        <div>
          <base-toggle name="pow" v-model="show"></base-toggle>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseFilterInput from '@/components/base/filter/BaseFilterInput'

export default {
  name: 'education-card-filter',
  extends: BaseFilterInput,
  data () {
    return {
      show: null
    }
  },
  watch: {
    show (newValue) {
      if (!newValue) {
        this.filterValue = ''
        return
      }
      this.filterValue = true
    }
  }
}
</script>
