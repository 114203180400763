
import { defineComponent, ref } from 'vue'
import Api from '@/api/'
import Axios from '@/api/axios'
import { useRoute } from 'vue-router'
import { AxiosResponse } from 'axios'
import HyperspaceLoader from '@/components/loader/HyperspaceLoader.vue'
import { Response } from '@module/reports_backend/interface/inactive_user_report'
import BasePrice from '@/components/base/ultilty/BasePrice.vue'
import BaseDate from '@/components/base/ultilty/BaseDate.vue'

export default defineComponent({
  name: 'report-mensa-chip',
  components: { BaseDate, BasePrice, HyperspaceLoader },
  setup () {
    const users = ref<Response>([])
    const loading = ref(false)
    const route = useRoute()
    const fetch = async () => {
      loading.value = true
      const url = Api.createEndPointUrl('api_report_gather', { key: route.name })
      const response : AxiosResponse<Response> = await Axios.get(url)
      users.value = response.data
      loading.value = false
    }
    fetch()
    return {
      loading,
      users
    }
  }
})
