import { Customer } from '@interface/entity/customer'
import { Consumer } from '@interface/entity/consumer'
import { computed, ref } from 'vue'
import Api from '@/api'

export function useCustomer (c: Customer) {
  const customer = ref<Customer>(c)

  const kdNumbers = computed<string>(() => {
    if (!customer.value) { return '' }
    return customer.value.consumers.reduce((acc:string, consumer: Consumer) => {
      return acc + (acc !== '' ? ', ' : '') + consumer.kdNumber
    }, '')
  })

  const balance = computed<number>(() => {
    if (!customer.value) {
      return 0
    }
    if (!customer.value.wallet) {
      return 0
    }
    return customer.value.paymentMethod === 'SEPA' ? Math.abs(customer.value.wallet.balance) : customer.value.wallet.balance
  })

  return { customer, balance, kdNumbers }
}
