
import { defineComponent, PropType, ref } from 'vue'
import { useToggle } from '@vueuse/core'
import { RevenueByYear } from '@module/reports_backend/interface/nfc_chip_revenue'
import { Transaction } from '@interface/entity/transaction'
import BasePrice from '@/components/base/ultilty/BasePrice.vue'
import { AxiosResponse } from 'axios'
import Axios from '@/api/axios'
import Api from '@/api'
import { useRoute } from 'vue-router'
import BaseDate from '@/components/base/ultilty/BaseDate.vue'
import HyperspaceLoader from '@/components/loader/HyperspaceLoader.vue'
export default defineComponent({
  components: { HyperspaceLoader, BaseDate, BasePrice },
  setup (props) {
    const route = useRoute()
    const loading = ref(false)
    const transactions = ref<Array<Transaction>>([])
    const selectedMonth = ref(-1)
    const [value, toggle] = useToggle()
    const fetch = async () => {
      loading.value = true
      const url = Api.createEndPointUrl('api_report_gather', { key: route.name })
      const { data } : AxiosResponse<Array<Transaction>> = await Axios.post(url, {
        year: props.byYear.year, month: selectedMonth.value + 1
      })
      transactions.value = data
      loading.value = false
    }
    return {
      loading,
      fetch,
      transactions,
      selectedMonth,
      showDetails: value,
      toggleDetails: toggle
    }
  },
  methods: {
    selectMonth (index: number) {
      this.selectedMonth = index === this.selectedMonth ? -1 : index
    }
  },
  watch: {
    showDetails (val) {
      if (val && this.selectedMonth !== -1) {
        this.fetch()
      }
    },
    selectedMonth (newVal) {
      if (newVal === -1) {
        this.showDetails = false
        return
      }
      if (this.showDetails) {
        this.fetch()
      }
    }
  },
  props: {
    byYear: {
      type: Object as PropType<RevenueByYear>,
      required: true
    }
  },
  computed: {
    sum (): number {
      return this.byYear.data.reduce((acc, data) => {
        return acc + data.revenue
      }, 0)
    },
    months (): Array<string> {
      return this.byYear.data.map((revenue) => new Date(parseInt(revenue.year), parseInt(revenue.month) - 1).toLocaleDateString('de-DE', { month: 'long' }))
    }
  }
})
