
import { defineComponent } from 'vue'
import { Field } from 'vee-validate'
import { v4 as uuidv4 } from 'uuid'
export default defineComponent({
  name: 'base-range',
  components: {
    Field
  },
  setup () {
    return {
      id: uuidv4()
    }
  },
  props: {
    modelValue: {
      type: Number
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vModel: {
      get ():Number {
        if (!this.modelValue) { return this.min }
        return this.modelValue
      },
      set (value: any) {
        this.$emit('update:modelValue', parseInt(value))
      }
    }
  }
})
