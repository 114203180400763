import { GetterTree } from 'vuex'
import { RootState } from '../interfaces'
import { LoginState } from './interfaces'
import { Customer } from '@interface/entity/customer'
import { User } from '@interface/entity/user'

export const getters: GetterTree<LoginState, RootState> = {
  isLoading (state): boolean {
    return state.loading
  },
  userLoggedIn (_state, getters):boolean {
    return getters.user !== null || getters.customer !== null
  },
  success (state): boolean {
    return state.success
  },
  customer (state): Customer | null {
    if (!state.customer) { return null }
    return state.customer
  },
  user (state) : User | null {
    if (!state.user) { return null }
    return state.user
  }
}
