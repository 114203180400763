<template>
  <div class="bg-base-100 card col-span-1 relative z-50">
    <Form class="p-4" @submit="onSubmit"
          :validation-schema="schema"
          v-auto-animate
          @invalid-submit="onInvalidSubmit" >
      <base-input label="Email" icon="AtIcon" type="text" name="username" v-model="username" placeholder="E-Mail"></base-input>
      <base-input label="Password" icon="LockIcon" type="password" name="password" v-model="password" placeholder="Password"></base-input>
      <div class="card-actions mt-8 mb-4">
        <button type="submit" :class="{'btn w-full btn-primary': true, 'loading': isLoading, 'btn-disabled opacity-50': isLoading }">Login</button>
      </div>
      <div class="flex w-full">
        <router-link :to="{name: 'login_register'}" class="flex-1 btn btn-accent">
          Registrieren
        </router-link>
        <div class="divider divider-horizontal"></div>
        <router-link :to="{name: 'login_password_forget'}" class="flex-1 btn btn-accent">
          Passwort vergessen?
        </router-link>
      </div>
    </Form>
  </div>
  <div class="bg-base-100 bg-opacity-95 card mx-8 transition-all overflow-hidden" :class="{'opacity-100 -translate-y-2': loaded, '-translate-y-full opacity-10': !loaded }">
    <div class="bg-opacity-70 h-full px-4 pb-4 pt-6 bg-neutral text-white" >
      <div class="flex space-x-2 col-span-6 justify-between">
        <router-link :to="{name: 'legal_imprint'}">
          Impressum
        </router-link>
        <router-link :to="{name: 'legal_agb'}">
          ABG
        </router-link>
        <router-link :to="{name: 'legal_gdpr'}">
          Datenschutz
        </router-link>
      </div>
    </div>
  </div>
</template>
<style lang="postcss">
  .input-group .input{
    flex: 1;
  }
</style>
<script>
import { Form } from 'vee-validate'
import * as yup from 'yup'
import { mapActions, mapGetters } from 'vuex'
import { Notification } from '@interface/enums/Notification'
import { LoginMutations } from '@/store/login/mutations'
export default {
  name: 'view-layout-index',
  components: {
    Form
  },
  setup () {
    const schema = yup.object().shape({
      username: yup.string().email().required(),
      password: yup.string().required()
    })
    return {
      schema
    }
  },
  data () {
    return {
      username: '',
      password: '',
      loaded: true,
      formIsInvalid: false
    }
  },
  computed: {
    ...mapGetters('login', ['isLoading'])
  },
  methods: {
    ...mapActions('login', ['login']),
    async onSubmit (values) {
      try {
        await this.login({ username: values.username, password: values.password })
        this.$notify({
          title: 'Anmeldung erfolgreich!',
          type: Notification.SUCCESS
        })
        this.$router.push({ name: 'post_login' })
      } catch (e) {
        this.$notify({
          title: 'Anmeldung fehlgeschlagen',
          text: 'Der Benutzername oder das Passwort sind falsch!',
          type: Notification.ERROR
        })
        this.$store.commit(`login/${LoginMutations.TOGGLE_LOADING}`)
      }
    },
    onInvalidSubmit () {
      this.$notify({
        title: 'Bitte korrigieren Sie Ihre Angaben.',
        type: Notification.WARNING
      })
    }
  }

}
</script>
