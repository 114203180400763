import { Module } from 'vuex'
import { RootState } from '../interfaces'
import { CacheState } from './interfaces'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: CacheState = {
  clients: {},
  reports: []
}

export const cache: Module<CacheState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
