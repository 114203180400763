import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { AxiosResponse } from 'axios'
import Axios from '@/api/axios'
import Api from '@/api'
import { useStore } from 'vuex'

export function useReport <T> () {
  const store = useStore()
  const loading = ref(false)
  const isDownloading = ref(false)
  const route = useRoute()
  const data = ref<T | null>(null)
  const url = Api.createEndPointUrl('api_report_gather', { key: route.name })
  const fetch = async () => {
    loading.value = true
    const response: AxiosResponse = await Axios.get(url)
    data.value = response.data
    loading.value = false
  }
  const update = async (params: any) => {
    loading.value = true
    const response: AxiosResponse = await Axios.post(url, params)
    data.value = response.data
    loading.value = false
  }

  const download = async (format: string, params: any = {}) => {
    isDownloading.value = true
    const url = Api.createEndPointUrl('api_report_export', { key: route.name, format: format })
    const response = await Axios.post(url, params, { responseType: 'blob' })
    const objectURL = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = objectURL
    link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1])
    link.click()
    isDownloading.value = false
  }

  const report = computed(() => {
    if (!store.getters['cache/reports']) { return null }
    return store.getters['cache/reports'].filter((report: any) => report.id === route.name)[0]
  })
  const description = computed(() => {
    if (!report.value) { return '' }
    return report.value.description
  })

  const title = computed(() => {
    if (!report.value) { return '' }
    return report.value.label
  })

  return {
    title,
    description,
    download,
    fetch,
    update,
    isDownloading,
    loading,
    data
  }
}
