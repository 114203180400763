import { RouteRecordRaw, Router } from 'vue-router'
import DefaultLayout from '@layout/default.vue'
import NfcChipView from '@module/reports_backend/views/NfcChipRevenue.vue'
import InactiveCustomers from '@module/reports_backend/views/InactiveCustomers.vue'
import RevenueReport from '@module/reports_backend/views/RevenueReport.vue'
import ButCustomerReport from '@module/reports_backend/views/ButCustomerRevenueReport.vue'
import ButCustomer from '@module/reports_backend/views/ButCustomerReport.vue'

const routes : Array<RouteRecordRaw> =
  [
    {
      path: 'mensa-chips',
      name: 'nfc_chip_revenue',
      component: NfcChipView
    },
    {
      path: 'inactive-customers',
      name: 'inactive_user_report',
      component: InactiveCustomers
    },
    {
      path: 'revenue-report',
      name: 'revenue_report',
      component: RevenueReport
    },
    {
      path: 'but-revenue',
      name: 'but_revenue_report',
      component: ButCustomerReport
    },
    {
      path: 'but-customer',
      name: 'but_report',
      component: ButCustomer
    }
  ]

const record : RouteRecordRaw = {
  path: 'reports/',
  name: 'reports_root',
  children: routes,
  component: DefaultLayout
}

export default (router: Router) => {
  router.addRoute('backend_root', record)
}
