import { LoginResponse } from '@interface/api/Response'
import { setBearer } from '@/api/axios'

interface TokenStorageInterface {
  jwtStorageKey: string,
  refreshStorageKey: string,
  hasToken(): boolean,
  setToken(token: string): void,
  getToken(): string,
  hasRefreshToken(): boolean,
  setRefreshToken(token: string): void,
  getRefreshToken(): string
  setTokenIntoStorage(response: LoginResponse): void,
  clear(): void
  clearToken(): void
  restoreToken(): void
}

class TokenStorage implements TokenStorageInterface {
  jwtStorageKey: string;
  refreshStorageKey: string;

  constructor () {
    this.jwtStorageKey = 'api_token'
    this.refreshStorageKey = 'api_refresh'
  }

  getRefreshToken (): string {
    return localStorage.getItem(this.refreshStorageKey) ?? ''
  }

  hasRefreshToken (): boolean {
    return !!this.getRefreshToken()
  }

  setRefreshToken (token: string): void {
    localStorage.setItem(this.refreshStorageKey, token)
  }

  hasToken (): boolean {
    return !!this.getToken()
  }

  getToken (): string {
    return sessionStorage.getItem(this.jwtStorageKey) ?? ''
  }

  setToken (token: string): void {
    sessionStorage.setItem(this.jwtStorageKey, token)
  }

  setTokenIntoStorage (response: LoginResponse) {
    this.setToken(response.token)
    this.setRefreshToken(response.refresh_token)
    this.restoreToken()
  }

  clearToken () {
    sessionStorage.removeItem(this.jwtStorageKey)
  }

  clear () {
    this.clearToken()
    localStorage.removeItem(this.refreshStorageKey)
    setBearer('')
  }

  restoreToken () {
    setBearer(this.getToken())
  }
}

const instance = new TokenStorage()

export default instance
