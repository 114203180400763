<template>
    <tr class="sub-table" :class="{'hidden': !show}">
        <th></th>
        <td  class="font-bold">
            <div>
                <div class="font-bold pb-4 uppercase text-xs" v-if="index == 0">Chip Nr.</div>
                <div>{{consumer.nfcToken?.tokenId}}</div>
            </div>
        </td>
        <td>
          <div class="flex items-center space-x-3">
            <div>
                <div class="font-bold pb-4 uppercase text-xs" v-if="index == 0">Name</div>
              <div>{{consumer.firstName}} {{consumer.lastName}}</div>
            </div>
          </div>
        </td>
        <td>
            <div class="font-bold pb-4 uppercase text-xs" v-if="index == 0">Schule</div>
              {{school ? school.name : '' }}
        </td>
        <td>
            <div class="font-bold pb-4 uppercase text-xs" v-if="index == 0">Klasse</div>
            {{consumer.grade}}{{consumer.groupLetter}}
        </td>
        <td>
          <div v-if="consumer.educationCards && consumer.educationCards.length > 0" class="badge badge-info">Bildungskarte</div>
        </td>
    </tr>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    consumer: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    ...mapGetters('cache', ['clients']),
    school () {
      const identifier = this.consumer.client.split('/').at(-1)
      if (!this.clients[identifier]) {
        return null
      }
      return this.clients[identifier]
    }
  }
}
</script>
