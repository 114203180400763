
import Api from '@/api'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { mapGetters } from 'vuex'
import { Form } from 'vee-validate'
import { Customer } from '@interface/entity/customer'
import { Consumer } from '@interface/entity/consumer'
import HyperspaceLoader from '@/components/loader/HyperspaceLoader.vue'
import WalletDisplay from '@module/customer_frontend/components/display/WalletDisplay.vue'
import { Notification } from '@interface/enums/Notification'
import TransactionForm from '@module/customer_backend/components/TransactionForm.vue'
interface Data {
  customer: Customer | null,
  initialData: Customer | null,
  editing: boolean,
  isLoading: boolean
  submitting: boolean
}
export default defineComponent({
  components: { TransactionForm, HyperspaceLoader, Form, WalletDisplay },
  i18n: {
    messages: {
      'de-DE': {
        sorting: {
          time: 'Gebucht Am'
        },
        alert: {
          save: {
            title: '{firstName} {lastName} wurde gespeichert.',
            content: 'Die Änderungen am Kunden wurden erfolgreich übernommen.'
          }
        }
      }
    }
  },
  data (): Data {
    return {
      isLoading: false,
      customer: null,
      initialData: null,
      editing: false,
      submitting: false
    }
  },
  setup () {
    const route = useRoute()
    const id = route.params.id as string
    return {
      Api,
      id
    }
  },
  mounted () {
    this.fetchUser()
  },
  methods: {
    async createTransaction (data: any) {
      const transaction: any = {
        reason: data.reason,
        amount: (data.modifier * data.amount).toString(),
        type: 'normal'
      }
      if (!this.customer) {
        return null
      }
      const id = this.customer.id ?? ''
      await Api.customer.createTransaction(id, transaction)
      this.$modal('transaction')
      await this.fetchUser()
    },
    async sendPasswordRequest () {
      if (!this.customer) {
        return null
      }
      const id = this.customer.id ?? ''
      return Api.customer.sendPasswortResetMail(id)
    },
    async fetchUser () {
      this.isLoading = true
      try {
        const { data } = await Api.customer.get(this.id)
        this.customer = data
        await this.$nextTick()
        this.isLoading = false
      } catch (e) {
        this.$notify({
          title: 'There was a problem fetching the user',
          text: 'Please try again later'
        })
        this.$router.back()
      }
    },
    editCustomer () {
      if (this.editing) {
        this.editing = false
        this.customer = JSON.parse(JSON.stringify(this.initialData))
        this.initialData = null
      } else {
        this.initialData = JSON.parse(JSON.stringify(this.customer))
        this.editing = true
      }
    },
    async save () {
      this.submitting = true
      if (!this.customer) {
        return
      }
      const id = this.customer.id ?? ''
      const { data } = await Api.customer.update(id, {
        firstName: this.customer.firstName,
        lastName: this.customer.lastName,
        email: this.customer.email,
        street: this.customer.street,
        zip: this.customer.zip,
        city: this.customer.city,
        phone: this.customer.phone
      })
      this.$notify({
        type: Notification.SUCCESS,
        title: this.$t('alert.save.title', { firstName: data.firstName, lastName: data.lastName }),
        text: this.$t('alert.save.content')
      })
      this.customer = data
      this.editing = false
    }
  },
  computed: {
    ...mapGetters('cache', ['apiClients']),
    kdNumbers (): string {
      if (!this.customer) { return '' }
      return this.customer.consumers.reduce((acc:string, consumer: Consumer) => {
        return acc + (acc !== '' ? ', ' : '') + consumer.kdNumber
      }, '')
    },
    isLoaded (): boolean {
      return !this.isLoading && !!this.customer
    },
    walletBalance (): Number {
      if (!this.customer) {
        return 0
      }
      if (!this.customer.wallet) {
        return 0
      }
      return this.customer.paymentMethod === 'SEPA' ? Math.abs(this.customer.wallet.balance) : this.customer.wallet.balance
    }
  }
})
