<template>
  <div class="form-control w-full">
    <label class="label" for="clients">
      <span class="label-text">Schüler</span>
    </label>
    <vue-multiselect v-bind="multiselect" @search-change="find" :class="{'multiselect__invalid': isInvalid }" :options="options" v-model="selectedConsumers">
      <template #caret>
        <div class="multiselect__select">
          <CaretDownIcon class="w-full h-full"  />
        </div>
      </template>
      <template #noResult>
        Keine Einträge gefunden. Ändern Sie bitte die Suchanfrage.
      </template>
      <template #singleLabel="{option}">
        <div v-if="option.id">
          <strong>{{ option.firstName }} {{ option.lastName }}</strong> <br>
          <span class="text-neutral text-opacity-70">
            {{ option.kdNumber }}
          </span>
        </div>
        <div v-else>
          {{ multiselect.placeholder }}
        </div>
      </template>
      <template #option="{ option }">
        <strong>{{ option.firstName }} {{ option.lastName }}</strong> <br>
        <span class="text-neutral text-opacity-70">
          {{ option.kdNumber }}
        </span>
      </template>
      <template #tag="{ option, remove }">
        <span class="multiselect__tag" >
          <span >
            {{ option.firstName }} {{ option.lastName }}
          </span>
          <i tabindex="1" @keypress.enter.prevent="remove(option)"
             @mousedown.prevent="remove(option)" class="multiselect__tag-icon"></i>
        </span>

        <!--        <div class="flex flex-col space-y-2">-->
        <!--          <strong>{{ option.name }}</strong>-->
        <!--          <span class="text-opacity-50">-->
        <!--            {{option.zip}} {{ option.city }}-->
        <!--          </span>-->
        <!--        </div>-->

      </template>
    </vue-multiselect>
    <label class="label" v-show="!!errorMessage">
      <span class="label-text-alt text-error">{{ errorMessage }}</span>
    </label>
  </div>
</template>
<script>
import Api from '@/api'
import { useField } from 'vee-validate'
import { toRef } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'consumer-select',
  data () {
    return {
      options: [],
      selectedConsumers: [],
      state: null,
      isLoading: false
    }
  },
  setup (props) {
    const name = toRef(props, 'name')
    const {
      value: inputValue,
      errorMessage,
      errors
    } = useField(name, props.required ? 'required' : '', {
      initialValue: props.modelValue
    })
    return {
      errors,
      errorMessage,
      inputValue
    }
  },
  props: {
    name: {
      type: String,
      default: () => uuidv4()
    },
    modelValue: [Object, String],
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selectMode: {
      type: [Object, String],
      default: 'id',
      validator: (value) => {
        return ['consumer', 'id'].includes(value)
      }
    }
  },
  methods: {
    async find (query) {
      this.isLoading = true
      const { data } = await Api.consumer.all({ search: query })
      this.options = data
      this.isLoading = false
      // this.isLoading = true
      // ajaxFindCountry(query).then(response => {
      //   this.countries = response
      //   this.isLoading = false
      // })
    },
    limitText (count) {
      return `und ${count} weitere Schüler`
    }
  },
  computed: {
    isInvalid () {
      return this.errors.length > 0
    },
    vModel: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.inputValue = value
        if (!value) {
          this.$emit('update:modelValue', null)
          return
        }
        switch (this.selectMode) {
          case 'id':
            if (this.multiple) {
              value = value.map(val => val.id)
            } else {
              value = value.id
            }
            break
          case 'consumer':
          default:
            break
        }
        this.$emit('update:modelValue', value)
      }
    },
    client () {
      const resolve = (val) => {
        if (typeof val === 'object') {
          return val
        }
        if (this.clients[val]) {
          return this.clients[val]
        }
        if (this.apiClients[val]) {
          return this.apiClients[val]
        }
        return null
      }
      return this.multiple ? this.modelValue.map(resolve) : resolve(this.modelValue)
    },
    multiselect () {
      return {
        name: 'consumers',
        label: 'name',
        trackBy: 'id',
        placeholder: 'Geben Sie eine Schule an',
        openDirection: 'bottom',
        searchable: true,
        loading: this.isLoading,
        internalSearch: false,
        clearOnSelect: true,
        closeOnSelect: true,
        optionsLimit: 300,
        limit: this.multiple ? 10 : 3,
        multiple: this.multiple,
        disabled: this.disabled,
        limitText: this.limitText,
        maxHeight: 200,
        showNoResults: false,
        hideSelected: false,
        tagPlaceholder: '',
        selectLabel: '',
        selectGroupLabel: '',
        selectedLabel: '',
        deselectLabel: '',
        deselectGroupLabel: ''
      }
    }
  }
}
</script>
