<template>
  <div class="text-sm">
    <div class="h-auto w-48 mx-auto mb-4">
      <img src="@/assets/static/cultina_cmyk_ohneclaim.svg" class="w-full">
    </div>
<!--    <p>-->
<!--      Herzlich Willkommen im Login-Bereich von Cultina-->
<!--    </p>-->
<!--    <p class="mt-1">-->
<!--      Sie haben noch kein Konto bei uns?-->
<!--      <br>-->
<!--      Dann können Sie über die Schaltfläche "Registieren", den Registierung Prozess anstoßen-->
<!--    </p>-->
<!--    <p class="mt-1">-->
<!--      Sie haben ihren Zugang vergessen?-->
<!--      <br>-->
<!--      Über die Schaltfläche "Passwort vergessen", können Sie ihr Passwort zurücksetzten.-->
<!--    </p>-->

    <p class="text-center text-white text-xl">
      Herzlich Willkommen im Login-Bereich von Cultina
    </p>
    <div class="alert alert-warning mt-2">
      <div class="text-center flex-1 w-full">
        Zurzeit ist der Login über dieses System bis zum neuen Schuljahr nicht möglich.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'login-layout-header'
}
</script>
