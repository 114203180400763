
import { defineComponent } from 'vue'
import { ModalEvents } from './types'
import emitter from './events'

export default defineComponent({
  inheritAttrs: false,
  data () {
    return {
      showModal: false,
      loading: false
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    }
  },
  mounted () {
    emitter.on(ModalEvents.OPEN, (name: string) => {
      if (this.name !== name) { return }
      this.open()
    })
    emitter.on(ModalEvents.CLOSE, (name: string) => {
      if (this.name !== name) { return }
      this.close()
    })
  },
  methods: {
    open () {
      this.showModal = true
    },
    toggle () {
      this.showModal = !this.showModal
    },
    close () {
      this.showModal = false
      this.loading = false
      this.$emit('close')
    }
  }
})
