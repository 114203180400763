<template>
  {{isDate ? $d(dateMutated, format) : ''}}
</template>

<script>
export default {
  name: 'base-date',
  props: {
    from: {
      type: [Date, String, null]
    },
    format: {
      type: String,
      default: 'date'
    },
    addDays: {
      type: Number,
      default: 0
    }
  },
  computed: {
    dateMutated () {
      if (this.addDays === 0) {
        return this.date
      }
      const result = new Date(this.date)
      result.setDate(result.getDate() + this.addDays)
      return result
    },
    date () {
      if (!this.from) { return '' }
      if (typeof this.from === 'string') {
        return Date.parse(this.from)
      }
      return this.from
    },
    isDate () {
      return !!Date.parse(this.from)
    }
  }
}
</script>

<style scoped>

</style>
