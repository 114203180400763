
import { defineComponent } from 'vue'
import { ModalEvents } from '@interface/events/ModalEvents'

export default defineComponent({
  name: 'base-modal',
  data () {
    return {
      showModal: false,
      loading: false
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.emitter.on(ModalEvents.OPEN, (name: string) => {
      if (this.name !== name) { return }
      this.open()
    })
    this.emitter.on(ModalEvents.CLOSE, (name: string) => {
      if (this.name !== name) { return }
      this.close()
    })
    this.emitter.on(ModalEvents.TOGGLE, (name: string) => {
      if (this.name !== name) { return }
      this.toggle()
    })
  },
  methods: {
    open () {
      this.showModal = true
    },
    toggle () {
      this.showModal = !this.showModal
    },
    close () {
      this.showModal = false
      this.loading = false
    }
  }
})
