
import { defineComponent, PropType } from 'vue'
import { Transaction } from '@interface/entity/transaction'

export default defineComponent({
  name: 'transaction-item',
  props: {
    transaction: {
      type: Object as PropType<Transaction>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    balance () {
      if (!this.transaction) { return 0 }
      return this.transaction.balanceBefore + this.transaction.amount
    }
  }
})
