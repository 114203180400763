// import Api from '@/render.process/api/MarlinRepository'
import { ActionTree } from 'vuex'
import { RootState } from '../interfaces'
import { RegisterPayload, RegistrationState } from './interfaces'
import { RegistrationMutations } from './mutations'
import { Customer } from '@interface/entity/customer'
import { Consumer } from '@interface/entity/consumer'

import { NewConsumer, NewCustomer } from '@interface/api/FrontendApiController'
import Api from '@/api'
export const actions: ActionTree<RegistrationState, RootState> = {
  async register ({ commit, state }, payload: RegisterPayload) {
    commit(RegistrationMutations.TOGGLE_LOADING, true)
    const { data } = await Api.security.register(payload)
    state.submitted = true
    commit(RegistrationMutations.TOGGLE_LOADING, false)
  }
}
