
import { defineComponent } from 'vue'
// import { NfcToken } from '@interface/entity/nfctoken'
// import { Notification } from '@interface/enums/Notification'
// import Api from '@/api'
import NfcChip from './nfc-chip.vue'
import { useField } from 'vee-validate'
import Api from '@/api'

export default defineComponent({
  name: 'nfc-chip-new',
  extends: NfcChip,
  data () {
    return {
      focused: false
    }
  },
  setup () {
    const { value, meta, errorMessage } = useField('chipNumber', 'required|numeric', {
      initialValue: ''
    })
    return {
      value,
      meta,
      errorMessage
    }
  },
  mounted () {
    this.focus()
  },
  methods: {
    focus () {
      (this.$refs.field as any).focus()
      this.focused = true
    },
    unfocus () {
      this.focused = false
    },
    onInput (e: InputEvent) {
      const div: HTMLInputElement = e.target as HTMLInputElement
      this.value = div.value.trim()
    },
    async create () {
      await Api.nfcToken.create({
        tokenId: this.nfcChip.tokenId as string,
        consumer: this.nfcChip.consumer as string,
        state: 'activated',
        chipNumber: this.value as string
      })
      this.$emit('created')
    }
  },
  computed: {
    currentNumber (): string {
      return this.value.padStart(5, '0')
    }
  }
})
