import axios from './axios'
import { Login, LoginApi } from '@/api/security'
import { client, ClientApi } from '@/api/client'
import { scheduleApi, PriceScheduleApi } from '@/api/priceschedule'
import { customerApi, CustomerApi } from '@/api/customer'
import { consumerApi, ConsumerApi } from '@/api/consumer'
import { user, UserApi } from '@/api/user'
import { NfcTokenApi, nfcTokenApi } from '@/api/nfctoken'
import { BankStatementApi, statement } from '@/api/transferhubstatment'
import Routing from '@/symfony'

/**
 * @deprecated Use the axios instance directly
 */
export const Client = axios

export interface Api {
  security: LoginApi,
  user: UserApi,
  client: ClientApi,
  priceSchedule: PriceScheduleApi
  customer: CustomerApi
  consumer: ConsumerApi
  nfcToken: NfcTokenApi
  statement: BankStatementApi
  createEndPointUrl(routeName: string, params?: any): string
}

const api: Api = {
  security: Login,
  user: user,
  client: client,
  priceSchedule: scheduleApi,
  customer: customerApi,
  consumer: consumerApi,
  nfcToken: nfcTokenApi,
  statement: statement,
  createEndPointUrl (routeName: string, params: any = {}) {
    return Routing.generate(routeName, params)
  }
}
export default api
