import { RouteRecordRaw, RouterView } from 'vue-router'
import loginRoutes from './login'
import legalRoutes from './legal'
import redirects from './redirects'

const routes : Array<RouteRecordRaw> = []

export const BackendRoute = {
  path: '/backend/',
  name: 'backend_root',
  meta: {
    requiresAuth: true
  },
  component: RouterView
}

routes.push(BackendRoute)
routes.push(loginRoutes)
routes.push(legalRoutes)

export default routes.concat(redirects)
