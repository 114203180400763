<template>
  <div class="dropdown" v-show="isActive">
    <div class="indicator">
      <span class="indicator-item indicator-top  badge badge-primary px-1 py-2 mr-2" v-if="filterValue !== ''"><CheckIcon class="w-4" /></span>
      <label tabindex="0" class="btn">
        {{filterLabel }}
        <XIcon class="ml-2" @click="remove"/>
      </label>
    </div>
    <div tabindex="0" class="dropdown-content menu p-4 mt-2 rounded-box shadow bg-base-100 w-96">
      <div class="mb-2">
        {{headline}}
      </div>
      <div class="flex flex-row">
        <base-input type="text" :label="label ? label : id" :name="id" class="w-full" v-model="filterValue"></base-input>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-filter-input',
  inject: [
    'activeFilters',
    'addParameterToUrl',
    'removeParameterFromUrl',
    'removeFilter'
  ],
  props: {
    id: {
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    headline: {
      type: String,
      default: 'Filter nach:'
    }
  },
  data () {
    return {
      filterValue: ''
    }
  },
  methods: {
    remove () {
      this.filterValue = ''
      this.$nextTick(() => {
        this.removeFilter(this.currentFilter)
      })
    }
  },
  computed: {
    filterLabel () {
      if (this.filterValue === '') {
        return this.label ? this.label : this.id
      }
      return this.filterValue
    },
    allFilters () {
      return this.activeFilters
    },
    currentFilter () {
      return this.allFilters.find(item => item.key === this.id)
    },
    isActive () {
      return !!this.currentFilter
    }
  },
  watch: {
    filterValue (newValue) {
      if (!newValue) {
        this.removeParameterFromUrl(this.currentFilter.key)
        return
      }
      this.addParameterToUrl(this.currentFilter.key, newValue)
    }
  }
}
</script>

<style scoped>

</style>
