import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.client.priceSchedule, (schedule) => {
      return (_openBlock(), _createElementBlock("div", {
        key: schedule.id,
        class: "card shadow-xl"
      }))
    }), 128))
  ]))
}