<template>
  <template v-if="format">
    <span :class="{'text-error': price < 0, 'text-success': price > 0}">
      {{$n(display, 'currency')}}
    </span>
  </template>
  <template v-else>
    {{$n(display, 'currency')}}
  </template>
</template>

<script>
export default {
  name: 'base-price',
  props: {
    price: {},
    format: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    display () {
      return this.price ? this.price : 0
    }
  }
}
</script>

<style scoped>

</style>
