<script>
import { h } from 'vue'

const AnimationEntrance = (props, { slots, attrs }) => {
  const delay = props.delay || 250
  const tag = props.tag || 'span'
  let animation = props.animation || 'entranceFromRight'
  // eslint-disable-next-line eqeqeq
  if (props.fromTop != undefined) animation = 'entranceFromTop'
  // eslint-disable-next-line eqeqeq
  if (props.fromRight != undefined) animation = 'entranceFromRight'
  // eslint-disable-next-line eqeqeq
  if (props.fromBottom != undefined) animation = 'entranceFromBottom'
  // eslint-disable-next-line eqeqeq
  if (props.fromLeft != undefined) animation = 'entranceFromLeft'

  const children = slots.default()
  if (children) {
    children.forEach((child, index) => {
      if (!child.props) {
        child.props = {}
      }
      if (!child.props.style) {
        child.props.style = {}
      }
      child.props.style = {
        opacity: 0,
        animationFillMode: 'forwards',
        animationDelay: index * delay + 'ms'
      }
      child.props.class += ' ' + animation
    })
    return h(tag, children)
  }
  return h(tag, children)
}

AnimationEntrance.props = {
  delay: {
    type: Number,
    default: 250
  },
  tag: {
    type: String,
    default: 'span'
  },
  animation: {
    type: String,
    default: 'entranceFromRight'
  },
  fromTop: {},
  fromBottom: {},
  fromRight: {},
  fromtLeft: {}
}

export default AnimationEntrance
</script>
<style>
  .entranceFromTop {
    animation-duration: 2s;
    animation-name: entranceFromTop;
  }

  @keyframes entranceFromTop {
    from {
      opacity: 0;
      transform: translateY(-80px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .entranceFromBottom {
    animation-duration: 2s;
    animation-name: entranceFromBottom;
  }

  @keyframes entranceFromBottom {
    from {
      opacity: 0;
      transform: translateY(80px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .entranceFromLeft {
    animation-name: entranceFromLeft;
    animation-duration: 1s;
  }

  @keyframes entranceFromLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .entranceFromRight {
    animation-name: entranceFromRight;
    animation-duration: 1s;
  }

  @keyframes entranceFromRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
</style>
