
import { defineComponent, PropType } from 'vue'
import { Customer } from '@interface/entity/customer'
import { mapGetters } from 'vuex'
export default defineComponent({
  data () {
    return {
      open: false
    }
  },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true
    }
  },
  methods: {
    toggle () {
      if (this.customer.consumers.length) {
        this.open = !this.open
      }
    }
  },
  computed: {
    ...mapGetters('cache', ['apiClients']),
    detailLink () {
      return {
        name: 'customer_detail',
        params: { id: this.customer.id }
      }
    },
    stateLabel (): string {
      switch (this.customer.state) {
        case 'active': return 'Aktiv'
        case 'deactivated': return 'Deaktiviert'
        case 'locked': return 'Gesperrt'
        case 'preregistered': return 'Pre-Registriert'
        case 'registered': return 'Registriert'
      }
      return ''
    },
    stateLabelClass (): string {
      switch (this.customer.state) {
        case 'active': return 'text-success'
        case 'deactivated': return 'text-error'
        case 'locked': return 'text-error'
        case 'preregistered': return 'text-info'
        case 'registered': return 'text-warning'
      }
      return ''
    }
  }
})
