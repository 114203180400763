<template>
  <div class="form-control">
    <div class="input-group">
      <span v-if="label">{{ label }}</span>
      <select class="select rounded-l-none rounded-r-none select-bordered focus:outline-none flex-1" :class="selectClass" v-model="sorting">
        <slot name="options" :options="sortingParams">
          <option disabled>{{ $t('placeholder') }}</option>
          <option value="" selected>{{ $t('none') }}</option>
          <option :key="item.key" :value="item.urlParameter" v-for="item in sortingParams">
            <slot name="item" :item="item">
              {{item.key}}
            </slot>
          </option>
        </slot>
      </select>
      <button class="btn" title="Aufsteigend" :class="{'btn-active btn-accent': direction === 'asc'}" @click="changeOrder('asc')">
        <SortAscendingLettersIcon class="w-6 h-6"></SortAscendingLettersIcon>
      </button>
      <button class="btn" title="Absteigend" :class="{'btn-active btn-accent': direction === 'desc'}" @click="changeOrder('desc')">
        <SortDescendingLettersIcon class="w-6 h-6"></SortDescendingLettersIcon>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-paginator-sorter',
  inject: [
    'url',
    'addParameterToUrl',
    'removeParameterFromUrl',
    'paginatorMeta',
    'sortingOptions'
  ],
  i18n: {
    messages: {
      'de-DE': {
        none: 'Keine',
        placeholder: 'Wählen Sie eine Sortierung aus.'
      }
    }
  },
  data () {
    return {
      sorting: '',
      appliedDefaults: false,
      direction: 'asc'
    }
  },
  methods: {
    setDefaults (defaults) {
      if (this.appliedDefaults) { return }
      Object.values(defaults).forEach((option) => {
        if (this.url.searchParams.has(option.urlParameter)) {
          this.sorting = option.urlParameter
          this.direction = this.url.searchParams.get(option.urlParameter).toLowerCase()
        }
      })
      this.appliedDefaults = true
    },
    changeOrder (sorting = '') {
      if (!sorting) {
        this.direction = this.direction === 'asc' ? 'desc' : 'asc'
      } else {
        this.direction = sorting
      }
      if (this.sorting) {
        this.addParameterToUrl(this.sorting, this.direction)
      }
    }
  },
  computed: {
    sortingParams () {
      return this.sortingOptions
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    selectClass: {
      type: String,
      default: ''
    }
  },
  watch: {
    sortingParams (newValue) {
      this.setDefaults(newValue)
    },
    sorting (newValue, oldValue) {
      this.removeParameterFromUrl(oldValue)
      if (newValue) {
        this.addParameterToUrl(newValue, this.direction)
      }
    }
  }
}
</script>

<style scoped>

</style>
