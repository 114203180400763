
import { defineComponent } from 'vue'
import vModel from '@/mixins/vModel'

export default defineComponent({
  name: 'days-of-operation-field',
  mixins: [vModel],
  methods: {
    select (key: string) {
      this.vModel[key] = !this.vModel[key]
    }
  },
  beforeMount () {
    if (Object.values(this.vModel).length === 0) {
      this.days.forEach((day) => {
        this.vModel[day.key] = false
      })
    }
  },
  setup () {
    return {
      days: [
        { name: 'Montag', key: 'MON' },
        { name: 'Dienstag', key: 'TUE' },
        { name: 'Mittwoch', key: 'WED' },
        { name: 'Donnerstag', key: 'THU' },
        { name: 'Freitag', key: 'FRI' }
      ]
    }
  },
  computed: {
    daysOfOperation (): Array<Object> {
      if (!this.vModel) { return [] }
      return this.days
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
