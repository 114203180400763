import { ActionTree } from 'vuex'
import { RootState } from '../interfaces'
import { LoginState } from './interfaces'
import { LoginMutations } from './mutations'
import Router from '@/router'
import TokenStorage from '@/services/TokenStorage'
import Api from '@/api'
import { LoginResponse } from '@interface/api/Response'
import axios, { AxiosResponse } from 'axios'
import Routing from '@/symfony'

const refreshTokenWorker = new Worker(new URL('@/workers/interval.worker.ts', import.meta.url))
const DEFAULT_INTERVAL = 1000 * 60
let workerInitiated = false

interface WorkerEvent {
  success: boolean,
  data: LoginResponse
}

const handleNewToken = (evt: MessageEvent<WorkerEvent>) => {
  if (evt.data.success) {
    const data = evt.data.data
    TokenStorage.setTokenIntoStorage(data)
    refreshTokenWorker.postMessage({
      interval: DEFAULT_INTERVAL,
      jwt: data.token,
      token: data.refresh_token
    })
  } else {
    TokenStorage.clear()
    refreshTokenWorker.terminate()
    Router.push({ name: 'login_index' })
  }
}
const initWebWorker = () => {
  if (workerInitiated) { return }
  refreshTokenWorker.postMessage({ interval: DEFAULT_INTERVAL, token: TokenStorage.getRefreshToken(), jwt: TokenStorage.getToken() })
  refreshTokenWorker.onmessage = handleNewToken
  workerInitiated = true
}
export const actions: ActionTree<LoginState, RootState> = {
  async fetchUser ({ commit, getters, dispatch }) {
    commit(LoginMutations.TOGGLE_LOADING, true)
    try {
      const { data } = await Api.security.current()
      commit(LoginMutations.SET_USER, data)
      if (getters.success) {
        await Promise.all([
          dispatch('cache/fetchClients', null, { root: true }),
          dispatch('cache/fetchReports', null, { root: true })
        ])
      }
      commit(LoginMutations.TOGGLE_LOADING)
      initWebWorker()
    } catch (error) {
      console.log('Error fetching user', error)
      commit(LoginMutations.TOGGLE_LOADING)
    }
  },
  async logout ({ state, commit }) {
    commit(LoginMutations.TOGGLE_LOADING)
    await Api.security.logout()
    refreshTokenWorker.terminate()
    commit(LoginMutations.CLEAR)
    commit(LoginMutations.TOGGLE_LOADING)
  },
  async login ({ commit, state, dispatch }, payload) {
    commit(LoginMutations.TOGGLE_LOADING, true)
    const { data } = await Api.security.login(payload.username, payload.password)
    state.success = true
    commit(LoginMutations.SET_TOKEN, data.token)
    await dispatch('fetchUser')
  },
  async restoreUser ({ state, dispatch }) {
    if (TokenStorage.hasToken() && TokenStorage.hasRefreshToken()) {
      TokenStorage.restoreToken()
      state.success = true
    } else if (!TokenStorage.hasToken() && TokenStorage.hasRefreshToken()) {
      try {
        const { data }: AxiosResponse<LoginResponse> = await axios.post(Routing.generate('gesdinet_jwt_refresh_token'), { refresh_token: TokenStorage.getRefreshToken() })
        TokenStorage.setTokenIntoStorage(data)
        state.success = true
      } catch (e) {
        TokenStorage.clear()
        state.success = false
        throw new Error('Token no longer valid')
      }
    }
    if (state.success) {
      await dispatch('fetchUser')
    }
  }
}
