<template>
  <div class="dropdown" v-show="isActive">
    <div class="indicator">
      <span class="indicator-item indicator-top  badge badge-primary px-1 py-2 mr-2" v-if="filterValue !== ''"><CheckIcon class="w-4" /></span>
      <label tabindex="0" class="btn">
        {{filterLabel }}
        <XIcon class="ml-2" @click="remove"/>
      </label>
    </div>
    <div tabindex="0" class="dropdown-content menu p-4 mt-2 rounded-box shadow bg-base-100 w-96">
      <div class="mb-2">
        {{headline}}
      </div>
      <div class="flex flex-row">
        <base-input type="number" :label="label ? label : id" class="w-full" v-model.number="filterValue"></base-input>
      </div>
    </div>
  </div>
</template>

<script>
import BaseFilterInput from './BaseFilterInput'
export default {
  name: 'base-filter-number',
  extends: BaseFilterInput
}
</script>

<style scoped>

</style>
