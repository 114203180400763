
import { defineComponent, PropType } from 'vue'
import { PriceSchedule } from '@interface/entity/priceschedule'
import { Client } from '@interface/entity/client'
import FieldsetPriceSchedule from '@/components/fieldset/FieldsetPriceSchedule.vue'
import { PriceSchedulePatch } from '@interface/api/PriceSchedule'
import { Notification } from '@interface/enums/Notification'
import Api from '@/api'

interface Data {
  createdSchedule: PriceSchedule | null
  editing: boolean,
  acceptChanges: boolean,
}

export default defineComponent({
  name: 'client-price-history-item',
  components: { FieldsetPriceSchedule },
  props: {
    schedule: {
      type: Object as PropType<PriceSchedule>,
      required: true
    },
    client: {
      type: Object as PropType<Client>,
      required: true
    }
  },
  data (): Data {
    return {
      createdSchedule: null,
      editing: false,
      acceptChanges: false
    }
  },
  methods: {
    toggleEdit () {
      this.editing = !this.editing
    },
    newSchedule (schedule: PriceSchedule) {
      this.createdSchedule = schedule
      this.toggleEdit()
    },
    async save () {
      if (!this.createdSchedule) { return }
      const id = this.schedule.id ?? ''
      const params :PriceSchedulePatch = {
        validFrom: this.createdSchedule.validFrom,
        validTill: this.createdSchedule.validTill,
        isStaggeredPrice: this.createdSchedule.isStaggeredPrice,
        unitPrice: this.createdSchedule.unitPrice,
        daysOfOperation: this.createdSchedule.scheduleDays,
        client: this.client.id
      }
      if (params.isStaggeredPrice) {
        params.staggeredPrices = this.createdSchedule.staggeredPrices
      } else {
        params.subscriptionPrice = this.createdSchedule.subscriptionPrice
      }
      const { data } = await Api.priceSchedule.update(id, params)
      this.$emit('updated', data)
      this.createdSchedule = null
      if (this.schedule.active) {
        this.acceptChanges = false
      }
      this.$notify({
        title: 'Die Änderungen wurden erfolgreich gespeichert.',
        text: data.active ? 'Die Preise sind nun aktiv.' : '',
        type: Notification.SUCCESS
      })
    }
  },
  computed: {
    displayedSchedule (): PriceSchedule {
      return this.createdSchedule ? this.createdSchedule : this.schedule
    },
    scheduledDays () {
      const days = this.displayedSchedule.scheduleDays
      delete days.SAT
      delete days.SUN
      return days
    },
    canSubmit (): boolean {
      if (this.isEditMode) { return false }
      if (this.schedule.active) {
        return this.acceptChanges && this.hasScheduleChanges
      }
      return this.hasScheduleChanges
    },
    changes () {
      if (!this.client || !this.schedule || !this.createdSchedule) { return false }
      const amountOfOpenDays = Object.values(this.createdSchedule.scheduleDays).filter((val) => val === true).length
      return {
        daysOfOperation: amountOfOpenDays !== this.schedule.amountOfOperationalDays,
        unitPrice: this.createdSchedule.unitPrice !== this.schedule.unitPrice,
        subscriptionPrice: this.createdSchedule.subscriptionPrice !== this.schedule.subscriptionPrice,
        isStaggeredPrice: this.createdSchedule.isStaggeredPrice !== this.schedule.isStaggeredPrice,
        validFrom: this.createdSchedule.validFrom !== this.schedule.validFrom,
        validTill: this.createdSchedule.validTill !== this.schedule.validTill
      }
    },
    hasScheduleChanges (): boolean {
      return Object.values(this.changes).some(val => val) && !this.isEditMode
    },
    isEditMode () {
      return this.editing
    }
  }
})
