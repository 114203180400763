<template>
  <div class="form-control" :class="{'honeypot': honeypot }">
    <label class="label" v-if="label" :for="id">
      <span class="label-text" :class="{'text-opacity-50': disabled}" >{{ label }}</span>
    </label>
    <Field
      :name="name"
      v-model="vModelNumber"
      ref="field"
      :rules="validators"
      :validate-on-input="true"
      v-slot="{ field, errorMessage, errors }"
    >
      <div class="input-group">
        <span v-if="!!icon" :class="{ 'bg-error text-error-content': errors.length > 0, 'bg-opacity-50 text-opacity-50': disabled  }">
          <component :is="icon" class="w-6 h-6" :class="{'opacity-50': disabled}" ></component>
        </span>
        <input v-bind="field" @paste="paste" :disabled="disabled" :type="type" :placeholder="placeholder" :id="id" class="input input-bordered w-full" :class="{ 'input-error': errors.length > 0 }">
        <span :class="{ 'bg-error text-error-content': errors.length > 0, 'bg-opacity-50 text-opacity-50': disabled  }">
          <CurrencyEuroIcon class="w-6 h-6" />
        </span>
      </div>
      <label class="label" v-show="!!errorMessage">
        <span class="label-text-alt text-error">{{ errorMessage }}</span>
      </label>
    </Field>
  </div>
</template>

<script>
import BaseInput from './BaseInput'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'base-money',
  extends: BaseInput,
  props: {
    rules: {
      default: 'currency'
    }
  },
  setup () {
    return {
      id: uuidv4()
    }
  },
  beforeMount () {
    if (this.vModel) {
      this.vModelNumber = typeof this.vModel === 'string' ? this.vModel : this.vModel.toFixed(2)
    }
  },
  computed: {
    validators () {
      const rules = this.rules.split('|')
      if (!rules.includes('currency')) {
        rules.push('currency')
      }
      return rules.join('|')
    },
    isInt () {
      const n = parseInt(this.vModel)
      if (isNaN(n)) {
        return false
      }
      return Number(n) === n && n % 1 === 0
    },
    isFloat () {
      const n = parseFloat(this.vModel)
      if (isNaN(n)) {
        return false
      }
      return Number(n) === n && n % 1 !== 0
    },
    vModelNumber: {
      get () {
        if (this.isFloat) {
          return this.vModel.toString().replace('.', ',')
        }
        return this.vModel
      },
      set (value) {
        if (value.includes(',')) {
          value = value.replace(',', '.')
        }
        const parsed = parseFloat(value)
        this.vModel = isNaN(parsed) ? '' : parsed
      }
    }
  }
}
</script>
