
import { defineComponent, PropType } from 'vue'
import { NfcToken } from '@interface/entity/nfctoken'
import { Notification } from '@interface/enums/Notification'
import Api from '@/api'

export default defineComponent({
  name: 'nfc-chip',
  props: {
    nfcChip: {
      required: true,
      type: Object as PropType<NfcToken>
    },
    index: {
      type: [String, Number],
      required: false,
      default: -1
    }
  },
  i18n: {
    messages: {
      'de-DE': {
        state: {
          activated: 'Aktiv',
          deactivated: 'Inaktiv',
          blocked: 'Blockiert'
        },
        notification: {
          activated: {
            title: 'NFC-Chip ist aktiv',
            text: 'Der Chip kann wieder an den Terminal verwendet werden.'
          },
          block: {
            title: 'NFC-Chip ist blockiert',
            text: 'Der Chip kann nicht mehr an einem Terminal verwendet werden.'
          }
        }
      }
    }
  },
  methods: {
    async sendActive () {
      const { data } = await Api.nfcToken.activate(this.nfcChip.id)
      this.$notify({
        title: this.$t('notification.activated.title'),
        text: this.$t('notification.activated.text'),
        type: Notification.SUCCESS
      })
      this.$emit('update', {
        index: this.index,
        chip: data
      })
    },
    async sendBlock () {
      const { data } = await Api.nfcToken.block(this.nfcChip.id)
      this.$notify({
        title: this.$t('notification.block.title'),
        text: this.$t('notification.block.text'),
        type: Notification.WARNING
      })
      this.$emit('update', {
        index: this.index,
        chip: data
      })
    }
  },
  computed: {
    isActive (): boolean {
      return this.nfcChip.state === 'activated'
    },
    isDisabled (): boolean {
      return !this.isActive
    }
  }
})
