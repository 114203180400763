import { Store, Plugin } from 'vuex'
import { LoginMutations } from '@store/login/mutations'
import { CustomerMutations } from './mutations'
import { RootState } from '@store/interfaces'

const plugin: Plugin<RootState> = (store) => {
  const mutationToWatch = `login/${LoginMutations.SET_USER}`
  const mutationsToCommit = `me/${CustomerMutations.SET_CUSTOMER}`
  store.subscribe((mutation, state) => {
    if (mutation.type !== mutationToWatch) { return null }
    if (!store.getters['login/customer']) { return null }
    store.commit(mutationsToCommit, mutation.payload)
  })
}
export default plugin
