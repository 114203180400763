
import dayjs from 'dayjs'
import { computed, defineComponent, ref } from 'vue'
import Api from '@/api/'
import Axios from '@/api/axios'
import { useRoute } from 'vue-router'
import { AxiosResponse } from 'axios'
import HyperspaceLoader from '@/components/loader/HyperspaceLoader.vue'
import { ClientReport, Response } from '@module/reports_backend/interface/revenue_report'
import BasePrice from '@/components/base/ultilty/BasePrice.vue'
import CountUp from '@/components/CountUp.vue'
import BaseRangeDatePicker from '@/components/base/input/BaseDateRangePicker.vue'

export default defineComponent({
  name: 'report-revenue',
  components: { BaseRangeDatePicker, CountUp, BasePrice, HyperspaceLoader },
  setup () {
    const data = ref<Response|null>(null)
    const loading = ref(false)
    const route = useRoute()
    const url = Api.createEndPointUrl('api_report_gather', { key: route.name })
    const period = ref<any>({
      till: dayjs().endOf('month').toDate(),
      from: dayjs().startOf('month').toDate()
    })
    const multipleClient = ref([])
    const fetch = async () => {
      loading.value = true
      const response: AxiosResponse<Response> = await Axios.get(url)
      data.value = response.data
      loading.value = false
    }
    const update = async () => {
      loading.value = true
      const response: AxiosResponse<Response> = await Axios.post(url, {
        client: multipleClient.value,
        from: dayjs(period.value.from).unix(),
        till: dayjs(period.value.till).unix()
      })
      data.value = response.data
      loading.value = false
    }
    fetch()
    const reports = computed(() => {
      if (!data.value) { return [] }
      return Object.values(data.value).map((val: ClientReport) => {
        val.revenue.subscription = val.revenue.subscription * -1
        val.revenue.impulse = val.revenue.impulse * -1
        return val
      })
    })
    const impulse = computed(() => {
      return reports.value.reduce((acc, val: ClientReport) => {
        return acc + val.revenue.impulse
      }, 0)
    })
    const subvention = computed(() => {
      return reports.value.reduce((acc, val: ClientReport) => {
        return acc + val.revenue.subvention
      }, 0)
    })
    const subscription = computed(() => {
      return reports.value.reduce((acc, val: ClientReport) => {
        return acc + val.revenue.subscription
      }, 0)
    })
    const total = computed(() => {
      return impulse.value + subscription.value + subvention.value
    })
    return {
      multipleClient,
      impulse,
      subvention,
      subscription,
      total,
      loading,
      reports,
      update,
      period
    }
  }
})
