
import Api from '@/api'
import { Client } from '@interface/entity/client'
import { ClientPatch } from '@interface/api/Client'
import { defineComponent } from 'vue'
import { Form } from 'vee-validate'
import { PriceSchedule } from '@interface/entity/priceschedule'

// eslint-disable-next-line no-unused-vars
enum Mode {
  // eslint-disable-next-line no-unused-vars
  NORMAL = 2,
  // eslint-disable-next-line no-unused-vars
  EDIT = 1
}

interface Data {
  acceptChanges: boolean,
  client: null | Client,
  loading: boolean,
  mode: Mode,
}

export default defineComponent({

  name: 'client-edit-view',
  components: {
    Form
  },
  data (): Data {
    return {
      acceptChanges: false,
      client: null,
      loading: false,
      mode: Mode.NORMAL
    }
  },
  setup () {
    return {
      Mode: Mode,
      days: [
        { name: 'Montag', key: 'MON' },
        { name: 'Dienstag', key: 'TUE' },
        { name: 'Mittwoch', key: 'WED' },
        { name: 'Donnerstag', key: 'THU' },
        { name: 'Freitag', key: 'FRI' }
      ]
    }
  },
  beforeMount () {
    this.load()
  },
  computed: {
    scheduleGaps () {
      if (!this.client) { return [] }
      const gaps: any = []
      // eslint-disable-next-line no-unused-vars
      let previous: PriceSchedule | null = null
      const threshold = 1000 * 60 * 24 // 1day
      const schedules = [this.client.activeSchedule].concat(Object.values(this.client.upcomingSchedules)).sort(this.sort)
      schedules.forEach((schedule: PriceSchedule) => {
        if (!previous) { previous = schedule }
        const from = new Date(previous.validTill)
        const till = new Date(schedule.validFrom)
        if (till.getTime() - from.getTime() > threshold) {
          gaps.push({
            from: previous,
            to: schedule
          })
        }
        previous = schedule
      })
      return gaps
    },
    schedules (): Array<PriceSchedule> {
      if (!this.client) { return [] }
      const schedules = Object.values(this.client.priceSchedule).filter((schedule: PriceSchedule) => !schedule.expired)
      return schedules.sort(this.sort)
    },
    isEditMode (): boolean {
      return (this.mode as Mode) === Mode.EDIT
    }
  },
  methods: {
    sort (a:PriceSchedule, b:PriceSchedule) {
      return (new Date(a.validFrom)).getTime() <= (new Date(b.validFrom)).getTime() ? -1 : 1
    },
    edit () {
      this.setMode(Mode.EDIT)
    },
    confirm () {
      this.setMode(Mode.NORMAL)
    },
    updateSchedule (schedule: PriceSchedule) {
      if (!this.client) { return }
      for (const key in this.client.priceSchedule) {
        const existingSchedule = this.client.priceSchedule[key]
        if (schedule.id === existingSchedule.id) {
          this.client.priceSchedule[key] = schedule
        }
      }
      if (schedule.id === this.client.activeSchedule.id) {
        this.loading = true
        this.load()
      }
    },
    async save () {
      if (!this.client) { return }
      const payload: ClientPatch = {
        name: this.client.name,
        province: this.client.province,
        street: this.client.street,
        zip: this.client.zip,
        city: this.client.city
      }
      this.confirm()
      this.loading = true
      await Api.client.update(this.client.id as string, payload)
      await this.load()
    },
    setMode (mode: Mode) {
      this.mode = mode
    },
    async load () {
      this.loading = true
      const { data } = await Api.client.get(this.$router.currentRoute.value.params.id as string)
      this.client = JSON.parse(JSON.stringify(data))
      this.loading = false
    },
    async newSchedule (schedule: PriceSchedule) {
      if (!this.client) { return }
      await Api.priceSchedule.create({
        validFrom: schedule.validFrom,
        validTill: schedule.validTill,
        unitPrice: schedule.unitPrice,
        isStaggeredPrice: schedule.isStaggeredPrice,
        daysOfOperation: schedule.scheduleDays,
        client: this.client.id,
        subscriptionPrice: schedule.subscriptionPrice,
        staggeredPrices: schedule.staggeredPrices
      })
      this.load()
    }
  }
})
