import { ModalEvents } from './types'
import emitter from './events'

export const modal = (id: string): void => {
  emitter.emit(ModalEvents.OPEN, id)
}

modal.close = (id: unknown): void => {
  emitter.emit(ModalEvents.CLOSE, id)
}

export const useModal = () => {
  return { modal }
}
