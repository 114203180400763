<template>
  <div class="relative flex flex-col items-center">
    <slot>{{$t('loading')}}</slot>
    <div class="wrapper mt-1">
      <div class="loader">
        <div :key="i" v-for="i in 10" :style="'animation-delay:'+ (computedDuration * (boxes - i)) + 's'"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hyperspace-loader',
  i18n: {
    messages: {
      'de-DE': {
        loading: '...Laden...'
      }
    }
  },
  props: {
    boxes: {
      type: Number,
      default: 10
    },
    duration: {
      type: Number,
      default: 0.8
    },
    color: {
      type: String,
      default: 'hsl(var(--p))'
    }
  },
  computed: {
    computedDuration () {
      return -(this.duration / this.boxes)
    },
    durationInSeconds () {
      return this.duration + 's'
    }
  }
}
</script>

<style lang="postcss" scoped>
  .wrapper{
    width: 200px;
    height: 2px;
    background: hsla(var(--n) / var(--tw-bg-opacity, 1)) ;
  }
  .loader{
    height: 100%;
    display: flex;
    transform: translateZ(0);
  }
  .loader div {
    flex: 1;
    background: v-bind(color);
    animation: go v-bind(durationInSeconds) infinite alternate ease;
    box-shadow: 0 0 20px v-bind(color);
  }

  @keyframes go {
    100% {
      background: transparent;
      flex: 10;
      box-shadow: 0 0 0 transparent;
    }
  }
</style>
