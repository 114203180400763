
import { defineComponent } from 'vue'
import VModel from '@/mixins/vModel'
import { Field } from 'vee-validate'
export default defineComponent({
  components: {
    Field
  },
  mixins: [VModel],
  name: 'descriptive-checkbox',
  setup () {
    return {
      // @ts-ignore
      id: window.crypto.randomUUID()
    }
  },
  props: {
    modelValue: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [String, Object],
      default: ''
    },
    name: {
      type: String,
      required: true
    }
  }
})
