import { Module } from 'vuex'
import { RootState } from '../interfaces'
import { LoginState } from './interfaces'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

const state: LoginState = {
  user: null,
  customer: null,
  loading: false,
  success: false,
  token: ''
}

export const login: Module<LoginState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
