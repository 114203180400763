import { MutationTree } from 'vuex'
import { MeState } from './interfaces'
import { Customer } from '@interface/entity/customer'

export enum CustomerMutations {
  SET_CUSTOMER = 'customer_set',
  SET_CONSUMER = 'consumer_set',
  CLEAR = 'customer_clear',
}

export const mutations: MutationTree<MeState> = {
  [CustomerMutations.SET_CUSTOMER] (state, payload: Customer) {
    state.customer = payload
  },
  [CustomerMutations.CLEAR] (state) {
    state.customer = null
    state.activeConsumer = null
  },
  [CustomerMutations.SET_CONSUMER] (state, payload: string) {
    state.activeConsumer = payload
  }
}
