<template>
  <div class="form-control" :class="{'honeypot': honeypot }">
    <label class="label" v-if="label" :for="id">
      <span class="label-text" :class="{'text-opacity-50': disabled}" >{{ label }}</span>
    </label>
    <Field
      :name="name"
      v-model="vModel"
      ref="field"
      :rules="rules"
      :validate-on-input="true"
      v-slot="{ field, errorMessage, errors }"
    >
      <template v-if="!!icon">
        <div class="input-group">
          <span :class="{ 'bg-error text-error-content': errors.length > 0, 'bg-opacity-50 text-opacity-50': disabled  }">
            <component :is="icon" class="w-6 h-6" :class="{'opacity-50': disabled}" ></component>
          </span>
          <input v-bind="field" @paste="paste" :disabled="disabled" :type="type" :placeholder="placeholder" :id="id" class="input input-bordered w-full" :class="{ 'input-error': errors.length > 0 }">
        </div>
      </template>
      <input v-else v-bind="field" @paste="paste" :disabled="disabled" :id="id" :type="type" :placeholder="placeholder" class="input input-bordered w-full" :class="{ 'input-error': errors.length > 0 }">
      <label class="label" v-show="!!errorMessage">
        <span class="label-text-alt text-error">{{ errorMessage }}</span>
      </label>
    </Field>
  </div>
</template>

<script>
import VModel from '@/mixins/vModel'
import { Field } from 'vee-validate'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'base-input',
  mixins: [VModel],
  components: {
    Field
  },
  props: {
    modelValue: {},
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    successMessage: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    honeypot: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String,
      default: ''
    }
  },
  setup () {
    return {
      id: uuidv4()
    }
  },
  methods: {
    paste () {
      this.$nextTick(this.$refs.field.validate)
    }
  },
  computed: {
    validateOnInput () {
      return this.$store.getters['device/isMobile'] || this.$store.getters['device/isTablet'] || this.$store.getters['device/isTouch']
    }
  }
}
</script>

<style  scoped>
  .honeypot {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
</style>
