import { createI18n, I18n, I18nOptions } from 'vue-i18n'
export default createI18n({
  locale: 'de-DE',
  messages: {
    'de-DE': {
      days: 'Keinem Tag | {count} Tag | {count} Tagen',
      plural: 'keine | eine | mehrere',
      day: {
        MON: 'Montag',
        TUE: 'Dienstag',
        WED: 'Mittwoch',
        THU: 'Donnerstag',
        FRI: 'Freitag',
        SAT: 'Samstag',
        SUN: 'Sonntag'
      }
    }
  },
  datetimeFormats: {
    'de-DE': {
      monthYear: {
        year: 'numeric',
        month: 'long'
      },
      date: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      dateTime: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }
    }
  },
  numberFormats: {
    'de-DE': {
      currency: {
        style: 'currency', currency: 'EUR', notation: 'standard'
      },
      decimal: {
        style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
      },
      percent: {
        style: 'percent', useGrouping: false
      }
    }
  }
})

// import { nextTick } from 'vue'
// import { messages } from '@/locales/de'
//
// type TranslationSchema = typeof messages
//
// export const SUPPORT_LOCALES = ['en', 'ja']
//
// const options : I18nOptions = {
//   availableLocales: ['de_DE'],
//   messages: {
//     de_DE: messages
//   }
// }
//
// export function setupI18n () {
//   const i18n: I18n = createI18n<TranslationSchema>({
//
//   })
//   setI18nLanguage(i18n, options.locale)
//   return i18n
// }
//
// export function setI18nLanguage (i18n: I18n, locale: string) {
//   if (i18n.mode === 'legacy') {
//     i18n.global.locale = locale
//   } else {
//     i18n.global.locale.value = locale
//   }
//
//   /**
//    * NOTE:
//    * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
//    * The following is an example for axios.
//    *
//    * axios.defaults.headers.common['Accept-Language'] = locale
//    */
//   // @ts-ignore
//   document.querySelector('html').setAttribute('lang', locale)
// }
//
// export async function loadLocaleMessages (i18n, locale) {
//   // load locale messages with dynamic import
//   const messages = await import(
//     /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
//   )
//
//   // set locale and locale message
//   i18n.global.setLocaleMessage(locale, messages.default)
//
//   return nextTick()
// }
