import * as MODULE from '@tabler/icons-vue'
import { FunctionalComponent } from 'vue'

/**
 * Autoloading components
 */
export const register = (app: any) => {
  /**
   * "Components"-directory
   */
  require.context('@/components/', true, /\.vue$/).keys().forEach(function (elementPath) {
    const component = require('@/components/' + elementPath.replace('./', '')).default
    if (typeof (component.name) === 'undefined') {
      const split = elementPath.split('/')
      component.name = split[split.length - 1]
      component.name = component.name.split('.')[0]
    }
    app.component(component.name, component)
  })
  /**
   * "Vue Tabler Icons"-directory... Because im lazy
   */
  Object.keys(MODULE).forEach((name) => {
    if (!name.includes('Icon')) { return }
    const newName = name.replace('Icon', '')
    const cmp: FunctionalComponent = (MODULE[name as keyof typeof MODULE]) as FunctionalComponent
    app.component(newName + 'Icon', cmp)
  })
}
