import { GetterTree } from 'vuex'
import { RootState } from '../interfaces'
import { MeState } from './interfaces'
import { Consumer } from '@interface/entity/consumer'

export const getters: GetterTree<MeState, RootState> = {
  instance (state) {
    return state.customer
  },
  kdNumbers (state): string {
    if (!state.customer) { return '' }
    return state.customer.consumers.reduce((acc:string, consumer: Consumer) => {
      return acc + (acc !== '' ? ', ' : '') + consumer.kdNumber
    }, '')
  },
  consumers (state, getters, rootState, rootGetters): Array<Consumer> {
    const lengthOfClient = Object.values(rootGetters['cache/clients'])
    if (!lengthOfClient.length || !state.customer) { return [] }
    return state.customer.consumers.map((consumer: Consumer) => {
      consumer.client = rootGetters['cache/clients'][consumer.client.id]
      return consumer
    })
  },
  aliasAbbreviation (state): string {
    if (!state.customer) { return '' }
    return state.customer.firstName[0].toUpperCase() + ' ' + state.customer.lastName[0].toUpperCase()
  },
  balance (state): Number {
    if (!state.customer) {
      return 0
    }
    if (!state.customer.wallet) {
      return 0
    }
    return state.customer.paymentMethod === 'SEPA' ? Math.abs(state.customer.wallet.balance) : state.customer.wallet.balance
  },
  currentConsumer (state, getters): Consumer | null {
    if (!state.activeConsumer || !state.customer) {
      return null
    }
    const consumers = state.customer.consumers.filter((consumer: Consumer) => consumer.id === state.activeConsumer)
    if (!consumers.length) {
      return null
    }
    return consumers[0]
  }
}
