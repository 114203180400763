<template>
  <div class="dropdown ">
    <label tabindex="0" class="btn flex flex-nowrap" :class="{'btn-disabled': selectedFilters.length === filters.length}">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-funnel mr-1" viewBox="0 0 16 16">
        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
      </svg>
    </label>
    <ul tabindex="0" class="dropdown-content menu p-2 mt-2 shadow bg-base-100 rounded-box" v-if="selectedFilters.length !== filters.length">
      <li v-for="filter in filters" :key="filter.property" v-show="!selectedFilters.includes(filter.key)">
        <slot name="filter" :filter="filter" :addFilter="addFilter">
          <a @click="addFilter(filter)">{{ filter.key }}</a>
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'base-paginator-filter',
  props: {
    filters: {
      type: Array
    }
  },
  methods: {
    addFilter (filter) {
      if (!this.activeFilters.find(item => item.key === filter.key)) {
        this.activeFilters.push(filter)
      }
    }
  },
  computed: {
    selectedFilters () {
      return this.activeFilters.map((filter) => filter.key)
    }
  },
  inject: [
    'paginatorLoading',
    'addParameterToUrl',
    'removeParameterFromUrl',
    'paginatorMeta',
    'activeFilters'
  ]
}
</script>

<style scoped>

</style>
