
import { defineComponent, PropType } from 'vue'
import { useWindowSize } from '@vueuse/core'
interface AspectRatio {
  width: number,
  height: number,
  reverse: boolean,
}
const __default__ = defineComponent({
  setup () {
    const { width, height } = useWindowSize()
    return {
      width,
      height
    }
  },
  name: 'AspectRatio',
  props: {
    ratios: {
      type: Object as PropType<Record<number, AspectRatio>>,
      required: true
    },
    computed: {

    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3599a146": (_ctx.width),
  "b1387b20": (_ctx.height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__