<template>
  <div id="login">
    <h1>Datenschutzerklärung</h1>
    <p>
      <strong>Datenschutzerklärung</strong><br>
      Der Mensa-Server stellt Schulen und Unternehmen ein einfaches Buchungs- und Abrechnungssystem zur Verfügung, um Schulmensen zu betreiben.
      Bei Ihrer Registrierung für dieses System und während seiner Nutzung speichern wir verschiedene persönliche (personenbezogene) Daten von Ihnen. Hier möchten wir Sie darüber informieren, welche Daten wir speichern und wer Zugriff auf diese erhält. Wir nehmen den Schutz Ihrer persönlichen (personenbezogenen) Daten dabei sehr ernst und halten uns strikt an die EU-Datenschutz-Grundverordnung. In keinem Fall werden die erhobenen Daten verkauft oder aus anderen Gründen an andere als hier angegebene Personen und Unternehmen weitergegeben.
    </p>
    <p>
      <strong>Bei der Registrierung erhobene Daten</strong><br>
      Bei der Registrierung für dieses System erheben wir folgende persönliche (personenbezogene) Daten (Stammdaten):
    </p>
    <ul class="list-disc ml-12">
      <li>Vorname / Nachname des Erziehungsberechtigten</li>
      <li>Anschrift des Erziehungsberechtigten</li>
      <li>Telefonnummer und E-Mail Adresse des Erziehungsberechtigten</li>
      <li>Vorname / Nachname des Schülers </li>
    </ul>

    <p>Diese Daten benötigen wir, um unsere Kunden zu identifizieren sowie mit Ihnen Kontakt aufzunehmen.</p>
    <p> Rechtsgrundlage dafür ist Art. 6 Abs. 1 S. 1 lit. b DS-GVO.</p>

    <p><strong>Während der Nutzung erhobene Daten</strong><br>
      Während der Nutzung des Systems werden sowohl personenbezogene als auch andere Daten erhoben, die zum Betrieb des Systems, oder zum Nachvollziehen von Vorgängen nötig sind.</p>
    <p>Protokolliert werden dabei:</p>

    <p>Bestellungen, Kündigungen und Änderungen von Abonnements</p>
    <ul class="list-disc ml-12">
      <li>Änderungen an Ihren oben genannten Stammdaten</li>
      <li>Transaktionen (Einzahlungen, Abbuchungen) Ihres Guthabenkontos</li>
      <li>Der erfolgte E-Mail Verkehr mit Ihnen (Sowohl Informationen von uns an Sie, als auch Anfragen von Ihnen)</li>
      <li>Das Akzeptieren von geänderten allgemeinen Geschäftsbedingungen</li>
      <li>Zahlungseingänge per Banküberweisung und Bareinzahlung (Kontonummern werden dabei gekürzt und maskiert)</li>
      <li>Nutzung von Abonnements und Spontanessen</li>
      <li>Sperren / Entsperren von Mensachips/Mensakarten</li>
      <li>Anmeldungen am System</li>
      <li>Verlängerungen von Abonnements für Folgemonate durch das System</li>
    </ul>
    <p>Zu diesen Ereignissen speichern wir Datum und Uhrzeit ihres Auftretens, sodass wir später alle Vorgänge und Zustände des Systems nachvollziehen können.</p>
    <p><strong>Wer hat Zugriff auf die Daten?</strong><br>
      Zunächst haben die mit der Betreuung des Systems beauftragten Mitarbeiter des Cateringunternehmens Zugriff auf die im System hinterlegten Daten. Welche Unternehmen hier beteiligt sind, entnehmen Sie bitte dem <router-link :to="{name: 'legal_imprint'}">Impressum</router-link>, welches die verantwortliche Stelle gem. Art. 4 Abs. 7 DS-GVO bezeichnet. Sofern Sie genauere Informationen dazu wünschen, welche Mitarbeiter mit der Betreuung des Systems beauftragt sind, kontaktieren Sie bitte das jeweilige Unternehmen.
    </p>
    <p>Die Bereitstellung der für dieses System notwendigen Internetdienste (Webserver, Datenbanken, usw.) erfolgt momentan durch die Firma Mittwald CM Service GmbH & Co. KG, Königsberger Straße 4-6, 32339 Espelkamp (www.mittwald.de).
      Diese Firmen haben keinen direkten Zugang zum System und greifen auch nicht in anderer Weise auf die Daten zu, allerdings wäre dies theoretisch möglich, da sich die Daten auf deren Geräten befinden.
    </p>
    <p><strong>Löschung Ihrer Daten</strong><br>
      Sobald Sie Ihr Kundenkonto kündigen, löschen wir alle Informationen, wodurch Ihnen das Kundenkonto zugeordnet werden kann. Sollten wir Ihre Daten zum Zwecke der Abrechnung mit Ihnen noch über das Kündigungsdatum hinaus benötigen, werden wir diese löschen, sobald dies nicht mehr zutrifft. Zur Erfüllung gesetzlicher, oder vertraglicher Aufbewahrungsfristen können bestimmte Daten auch über das Kündigungsdatum hinaus gespeichert/aufbewahrt bleiben (z.B. Vertragsdokumente oder Kontoumsätze).
    </p>
    <br>
  </div>
</template>

<script>
export default {
  name: 'privacy-gdpr'
}
</script>

<style lang="postcss" scoped>
  h2{
    font-size: 16px;
    margin-top: 15px;
    font-weight: normal;
    line-height: 20px;
    color: #4270AA;
    border-bottom: 1px solid #4270AA;
  }
  a {
    color: #4270AA;
    text-decoration: underline;
  }
  ul {
    @apply list-disc pl-8 mt-4 mb-4
  }
  p{
    @apply mt-4 mb-4
  }
</style>
