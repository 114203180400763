
import { defineComponent, PropType } from 'vue'
import { Form, FormActions, useField } from 'vee-validate'
import { EducationCard } from '@interface/entity/educationcard'

interface Data {
  validFrom: Date | null
  validTill: Date | null
  cardNumber: String,
  cardOptions: Array<String>
}

export default defineComponent({
  components: {
    Form
  },
  props: {
    cards: {
      type: Array as PropType<Array<EducationCard>>,
      required: true
    }
  },
  setup () {
    const { errorMessage, value, meta } = useField('cardNumber', 'numeric', {
      initialValue: ''
    })
    return {
      tagValue: value,
      tagError: errorMessage,
      tagMeta: meta
    }
  },
  methods: {
    newEducationCard (val: any, actions: FormActions<any>) {
      this.validTill = null
      this.validFrom = null
      this.cardNumber = ''
      this.$emit('newCard', val)
      actions.resetForm()
    }
  },
  beforeMount () {
    this.cards.forEach((card: EducationCard) => {
      if (!this.cardOptions.includes(card.cardNumber)) {
        this.cardOptions.push(card.cardNumber)
      }
    })
    if (this.cardOptions.length === 1) {
      this.cardNumber = this.cardOptions[0]
    }
  },
  computed: {
    earliestValidFrom () : Date {
      const now = new Date()
      if (this.cards.length === 0) {
        return now
      }
      const cards = this.cards.filter((card) => {
        const validTill = new Date(card.validTill)
        return validTill >= now
      }).sort((a, b) => {
        const validTillA = new Date(a.validTill)
        const validTillB = new Date(b.validTill)
        return validTillA.getTime() - validTillB.getTime()
      })
      if (cards.length === 0) {
        return now
      }
      const validTillDay = new Date(cards[0].validTill)
      validTillDay.setDate(validTillDay.getDate() + 1)
      return validTillDay
    }
  },
  data (): Data {
    return {
      validFrom: null,
      validTill: null,
      cardNumber: '',
      cardOptions: []
    }
  }
})
