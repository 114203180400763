
import { defineComponent, PropType, toRef } from 'vue'
import Api from '@/api'
import { useField } from 'vee-validate'
import { v4 as uuidv4 } from 'uuid'
import { Client } from '@interface/entity/client'
import { PriceSchedule } from '@interface/entity/priceschedule'

export default defineComponent({
  name: 'price-schedule-select',
  setup (props) {
    const name = toRef(props, 'name')
    const {
      value: inputValue,
      errorMessage,
      errors
    } = useField(name, props.required ? 'required' : '', {
      initialValue: props.modelValue
    })
    return {
      errors,
      errorMessage,
      inputValue,
      scheduleLabel (option: PriceSchedule) {
        const validFrom = new Intl.DateTimeFormat('de-DE', { year: 'numeric' }).format(Date.parse(option.validFrom))
        const validTill = new Intl.DateTimeFormat('de-DE', { year: 'numeric' }).format(Date.parse(option.validTill))
        if (validFrom !== validTill) {
          return [validFrom, validTill].join(' / ')
        }
        return validTill
      }
    }
  },
  props: {
    client: {
      type: Object as PropType<Client>,
      required: true
    },
    name: {
      type: String,
      default: () => uuidv4()
    },
    modelValue: [Object, String],
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    selectMode: {
      type: [Object, String],
      default: 'client',
      validator: (value:string) => {
        return ['client', 'id', 'uri'].includes(value)
      }
    }
  },
  computed: {
    options () {
      const schedules = [this.client.activeSchedule]
      return schedules.concat(Object.values(this.client.upcomingSchedules))
    },
    isInvalid () {
      if (!this.errors) {
        return false
      }
      return this.errors.length > 0
    },
    vModel: {
      get () {
        return this.modelValue
      },
      set (value: any) {
        this.inputValue = value
        if (!value) {
          this.$emit('update:modelValue', null)
          return
        }
        switch (this.selectMode) {
          case 'id':
            value = value.id
            break
          case 'uri':
            value = Api.client.getUri(value.id)
            break
          case 'client':
          default:
            break
        }
        console.log('test', value)
        this.$emit('update:modelValue', value)
      }
    },
    multiselect () {
      return {
        name: 'clients',
        label: 'unitPrice',
        trackBy: 'id',
        placeholder: 'Wählen Sie das HalbJahr aus',
        openDirection: 'bottom',
        searchable: false,
        internalSearch: false,
        clearOnSelect: true,
        closeOnSelect: true,
        optionsLimit: 300,
        disabled: this.disabled,
        maxHeight: 200,
        showNoResults: false,
        hideSelected: false,
        tagPlaceholder: '',
        selectLabel: '',
        selectGroupLabel: '',
        selectedLabel: '',
        deselectLabel: '',
        deselectGroupLabel: ''
      }
    }
  }
})
