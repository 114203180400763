
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { Notification } from '@interface/enums/Notification'
import { CustomerMutations } from '@store/me/mutations'
import { OnClickOutside } from '@vueuse/components'
const timeout = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export default defineComponent({
  name: 'layout-me',
  mounted () {
    this.active = true
  },
  components: {
    OnClickOutside
  },
  provide () {
    return {
      logOut: this.logOut
    }
  },
  data () {
    return {
      active: false,
      dropdownOpen: false
    }
  },
  computed: {
    ...mapGetters('me', {
      customer: 'instance',
      placeholder: 'aliasAbbreviation'
    })
  },
  methods: {
    ...mapActions('login', ['logout']),
    toggle () {
      this.dropdownOpen = !this.dropdownOpen
    },
    async logOut () {
      this.dropdownOpen = false
      await timeout(100)
      this.$store.commit(`me/${CustomerMutations.CLEAR}`)
      this.active = false
      await timeout(300)
      await this.logout()
      this.$notify({
        title: 'Sie wurden erfolgreich ausgeloggt!',
        type: Notification.SUCCESS
      })
      await timeout(300)
      this.$router.push({ name: 'login_index' })
    }
  }
})
