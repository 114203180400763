import { MutationTree } from 'vuex'
import { RegistrationState } from './interfaces'
import { Customer } from '@interface/entity/customer'

export enum RegistrationMutations {
  SET_USER = 'set_user',
  SET_STEP = 'set_step',
  TOGGLE_LOADING = 'toggle_loading',
}

export const mutations: MutationTree<RegistrationState> = {
  [RegistrationMutations.SET_USER] (state, payload: Customer) {
    state.user = payload
  },
  [RegistrationMutations.SET_STEP] (state, payload: number) {
    state.step = payload < 1 ? 1 : payload
  },
  [RegistrationMutations.TOGGLE_LOADING] (state, payload: boolean | null | undefined) {
    if (typeof payload === 'boolean') {
      state.loading = payload
    } else {
      state.loading = !state.loading
    }
  }
}
