<template>
  <div class="form-control w-full relative">
    <label class="label" :for="id">
      <span class="label-text">{{ label }}</span>
    </label>
    <div class="input-group">
      <span :class="{ 'bg-error text-error-content': errors.length > 0  }">
        <LockIcon class="w-6 h-6"></LockIcon>
      </span>
      <Field
        :id="id"
        :name="name"
        :type="inputType"
        class="input input-bordered w-full"
        :class="{ 'input-error': errors.length > 0 }"
        v-model="inputValue"></Field>
      <span @click="show" @mouseleave="hide" class="cursor-pointer" :class="{ 'bg-error text-error-content': errors.length > 0  }">
        <EyeIcon class="w-6 h-6" v-if="!visible"></EyeIcon>
        <EyeOffIcon  v-else class="w-6 h-6"></EyeOffIcon>
      </span>
    </div>
    <progress-bar class="w-full strengthBar" v-show="errors.length === 0" :class="{'progress-error': poor, 'progress-warning' : guessable, 'progress-success': unguessable}" :value="passwordStrength" max="5"></progress-bar>
    <label class="label" v-show="!!errorMessage">
      <span class="label-text-alt text-error">{{ errorMessage }}</span>
    </label>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn'
import { useField, Field } from 'vee-validate'
import { toRef } from 'vue'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'password-input',
  components: {
    Field
  },
  data () {
    return {
      visible: false
    }
  },
  setup (props) {
    const name = toRef(props, 'name')
    const {
      value: inputValue,
      errorMessage,
      errors,
      meta
    } = useField(name, props.rules, {
      initialValue: props.modelValue
    })
    return {
      inputValue,
      errorMessage,
      meta,
      errors,
      id: uuidv4()
    }
  },
  computed: {
    poor () {
      return this.passwordStrength <= 1
    },
    guessable () {
      return this.passwordStrength === 2
    },
    unguessable () {
      return this.passwordStrength >= 3
    },
    passwordStrength () {
      if (!this.inputValue) { return 0 }
      return zxcvbn(this.inputValue, null).score + 1
    },
    inputType () {
      return this.visible ? 'text' : 'password'
    }
  },
  props: {
    modelValue: {},
    icon: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    }
  },
  watch: {
    passwordStrength (newValue) {
      this.$emit('strength', newValue)
    },
    inputValue (newValue) {
      this.$emit('update:modelValue', newValue)
    }
  }
}
</script>

<style  scoped>
  .strengthBar {
    @apply h-2;
  }
  .input-group :first-child{
    @apply rounded-bl-none;
  }
  .input-group :last-child{
    @apply rounded-br-none;
  }
</style>
