
import { defineComponent } from 'vue'
import { AxiosError } from 'axios'
import { Notification } from '@interface/enums/Notification'

export default defineComponent({
  name: 'api-request',
  props: {
    send: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async load () {
      this.loading = true
      try {
        await this.send()
      } catch (e) {
        const error: AxiosError = e as AxiosError
        this.$notify({
          title: 'Ein Fehler ist aufgetreten.',
          text: error.response?.data.detail ?? '',
          type: Notification.ERROR,
          duration: -1
        })
      }
      this.loading = false
    }
  }
})
