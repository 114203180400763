import { RouteRecordRaw, Router } from 'vue-router'
import DefaultLayout from '@layout/default.vue'
import IndexView from '@module/client_backend/views/index.vue'
import DetailView from '@module/client_backend/views/_id.vue'
import NewView from '@module/client_backend/views/new.vue'

const routes : Array<RouteRecordRaw> =
  [
    {
      path: '',
      meta: {
        nav: {
          label: 'Schulübersicht'
        }
      },
      name: 'client_index',
      component: IndexView
    },
    {
      path: ':id',
      meta: {
        nav: {
          label: 'Schulinformation'
        }
      },
      name: 'client_detail',
      component: DetailView
    },
    {
      path: 'new',
      meta: {
        nav: {
          label: 'Neue Schule anlegen'
        }
      },
      name: 'client_new',
      component: NewView
    }
  ]

const record : RouteRecordRaw = {
  path: 'client/',
  name: 'client_root',
  meta: {
    requiresAuth: true,
    nav: {
      label: 'Schulen'
    }
  },
  children: routes,
  component: DefaultLayout
}

export default (router: Router) => {
  router.addRoute('backend_root', record)
}
