<template>
  <Popper>
    <template #trigger="{toggle}">
      <div class="form-control" @click="toggle" >
        <label class="label" v-if="label" :for="id">
          <span class="label-text" :class="{'text-opacity-50': disabled}" >{{ label }}</span>
        </label>
        <div class="input-group">
          <span class="text-center cursor-pointer"
                :class="{ 'bg-error text-error-content': errors.length > 0, 'bg-opacity-50 text-opacity-50': disabled  }">
            <component :is="icon" class="w-6 h-6" :class="{'opacity-50': disabled}" />
          </span>
          <input
            class="input input-bordered w-full"
            :disabled="disabled"
            readonly
            :class="{ 'input-error': errors.length > 0 }"
            :value="inputValue"
          />
          <span v-if="!!value && !disabled" class="cursor-pointer hover:bg-primary hover:text-base-100" @click="value = null">
            <XIcon class="w-6 h-6" />
          </span>
        </div>
        <label class="label" v-show="!!errorMessage">
          <span class="label-text-alt text-error">{{ errorMessage }}</span>
        </label>
      </div>
    </template>

    <template #default="{popperUpdate}">
      <date-range-picker @select="selectDate" @custom="popperUpdate"> </date-range-picker>
    </template>
  </Popper>
</template>

<script>
import { defineComponent } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useField } from 'vee-validate'
import DateRangePicker from '@/components/daterange/DateRangePicker.vue'

export default defineComponent({
  name: 'base-range-date-picker',
  components: { DateRangePicker },
  props: {
    modelValue: {},
    icon: {
      default: 'CalendarIcon'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    rules: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const { value, errors, errorMessage } = useField(props.name, props.rules, { initialValue: props.modelValue })
    return {
      id: uuidv4(),
      errors,
      value,
      errorMessage
    }
  },
  computed: {
    inputValue () {
      if (!this.value) { return '' }
      if (!this.value.from || !this.value.till) { return '' }
      return this.$d(this.value.from, 'date') + ' - ' + this.$d(this.value.till, 'date')
    }
  },
  methods: {
    selectDate (val) {
      this.value = val
      this.$emit('update:modelValue', val)
    }
  }
})
</script>
