import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import Client from '@/api/axios'
import Routing from '@/symfony'

export interface UserApi{
  all: () => Promise<AxiosPromise>
  create: (name: string, email: string, password:string) => Promise<AxiosPromise>,
  toggle: (id: string) => Promise<AxiosPromise>,
  delete: (id: string) => Promise<AxiosPromise>
}

export const user: UserApi = {
  async all (): Promise<AxiosPromise> {
    return Client.get(Routing.generate('api_user_collection_post_new'))
  },
  async create (name: string, email: string, password:string): Promise<AxiosPromise> {
    return Client.post(Routing.generate('api_user_collection_post_new'), {
      name,
      email,
      password,
      isActive: true
    })
  },
  async delete (id: string): Promise<AxiosPromise> {
    return Client.delete(Routing.generate('api_users_delete_item', { id: id }))
  },
  async toggle (id): Promise<AxiosPromise> {
    return Client.put(Routing.generate('api_user_put_active', { id: id }), {})
  }
}
