
import { useForm } from 'vee-validate'
import { computed, defineComponent, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { Customer } from '@interface/entity/customer'
import { Notification } from '@interface/enums/Notification'
import Api from '@/api'
import { notify } from '@kyvg/vue3-notification'
import { useI18n } from 'vue-i18n'
interface CustomerForm {
  firstname: string
  lastname: string
  email: string
  phone: string
  street: string
  city: string
  zip: number
}

export default defineComponent({
  setup () {
    const store = useStore()
    const { t } = useI18n({
      useScope: 'global',
      messages: {
        'de-DE': {
          alert: {
            save: {
              title: 'Gespeichert',
              content: 'Ihre Änderungen wurden erfolgreich übernommen'
            }
          }
        }
      }
    })
    const customer = computed<Customer>(() => store.getters['login/customer'])
    const { meta, isSubmitting, handleSubmit, resetForm } = useForm<CustomerForm>()
    const save = handleSubmit(async (values) => {
      const id = customer.value.id ?? ''
      await Api.customer.update(id, {
        firstName: values.firstname,
        lastName: values.lastname,
        email: values.email,
        street: values.street,
        zip: values.zip.toString(),
        city: values.city,
        phone: values.phone
      })
      const { data } = await Api.security.current()
      notify({
        type: Notification.SUCCESS,
        title: t('alert.save.title'),
        text: t('alert.save.content')
      })
      store.commit('login/set_user', data)
    })
    const setForm = () => {
      resetForm({
        values: {
          firstname: customer.value.firstName,
          lastname: customer.value.lastName,
          email: customer.value.email,
          phone: customer.value.phone,
          street: customer.value.street,
          city: customer.value.city,
          zip: parseInt(customer.value.zip)
        }
      })
    }
    onMounted(() => {
      if (customer.value === null) { return }
      setForm()
    })
    watch(customer, () => {
      setForm()
    })
    return {
      meta,
      isSubmitting,
      save
    }
  }
})
