<template>
    <div v-if="!isLoading">
        <p>
        <strong>Informationen zur Mittagsverpflegung</strong> <br>
        <strong>am {{client.name}} {{client.city}}  - gültig ab 01.02.2022</strong>
        </p>
        <br>
        <p>
            In der Mensa bietet Ihnen die VIVENO Group GmbH – Cultina an den Tagen <template v-for="(day, index) in daysOfOperation" :key="day">{{day}}{{index === (daysOfOperation.length-1) ? '' : ', '}} </template> ein Mittagessenangebot an.
        </p>
        <br>
        <p>
            Sie haben die Möglichkeit, ein Abonnement über 1 bis 5 Mittagessen pro Woche abzuschließen. Die gebuchten Mittagessen können flexibel innerhalb der Woche eingelöst werden, sind jedoch nicht übertragbar auf die Folgewoche. Eine Kostenerstattung für während der Woche nicht in Anspruch genommene Mittagessen erfolgt nicht.
        </p>
        <br>
        <p>
            Unser Angebot beinhaltet: <br>
        </p>
        <br>
        <ul class="list-none ml-12">
            <li>- Auswahl aus vegetarischem oder nicht vegetarischem Hauptgericht, ohne Vorbestellung</li>
            <li>- Beilagen und Gemüse zum Nachnehmen</li>
            <li>- Pastabar und Salatbar zur Selbstbedienung und zum Nachnehmen</li>
            <li>- Dessert zur Selbstbedienung</li>
        </ul>
        <br>
        <p>
            Der Speiseplan wird im Internet veröffentlicht.
        </p>
        <br>
        <p>
        Die Bezahlung erfolgt bargeldlos über ein Online-Bestellsystem.
        </p>
        <br>
        <p>
        Dafür wird ein persönliches Kunden-Konto angelegt.
        </p>
        <br>
        <p>
        Jede/r Essenteilnehmer/in erhält gegen eine Gebühr von 4,50 € einen Mensachip, der für den Zutritt benötigt wird.
        </p>
        <br>
        <p>
        Haben Sie sich entschieden, an wie vielen Wochentagen Ihr Kind in der Mensa essen möchte, können Sie die Anzahl der Tage über Ihr Kundenkonto abonnieren. Je nach Anzahl der gewünschten Abotage ergibt sich ein Monatsbetrag. Dieser ist auch in Schließungszeiten (z.B. Ferienzeit, Feiertage) in voller Höhe zu entrichten. Wird ein Abo im laufenden Monat abgeschlossen, ist der Betrag anteilig zu zahlen.
        </p>
        <br>
        <table class="table">
            <tbody>
                <tr>
                    <td>Abo-Tage pro Woche</td>
                    <td v-for="n in client.latestSchedule.amountOfOperationalDays" :key=n>{{n}}</td>
                </tr>
                <tr>
                    <td>Betrag pro Monat</td>
                    <td v-for="(price, index) in client.latestSchedule.schedulePrices" :key="index">
                      {{ $n(price, 'currency') }}
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <p>
            Um am Essen teilzunehmen, muss das Abonnement für den jeweiligen Monat bezahlt sein. Die Beträge für Abonnements müssen jeweils bis zum 20. des Vormonats vollständig auf dem Kunden-Konto eingehen.
        </p>
        <br>
        <p>
            Im Abonnement können Sie die monatlich gebuchten Tage im laufenden Schuljahr erhöhen. Innerhalb der ersten 14 Tage jedes Schulhalbjahres besteht zudem die Möglichkeit, aufgrund von Stundenplanänderungen, eine Reduzierung der Abo-Tage durchzuführen. Änderungen können Sie entweder selbst über Ihr Kundenkonto vornehmen oder Sie kontaktieren die Cultina Verwaltung.
        </p>
        <br>
        <p>
            Die Kündigung eines Abonnements kann jeweils mit einer Frist von 4 Wochen zum Ende des Schulhalbjahres oder zum Ende der Sommerferien erfolgen.
        </p>
        <br>
        <p>
            Ergänzend zum Abo bieten wir die Möglichkeit des Spontanessens. Hieran können alle Schüler teilnehmen, die ein Mensa-Abo für mindestens einen Abo-Tag abgeschlossen haben. Lehrern und Oberstufenschülern ist das Spontanessen auch ohne Abonnement-Vertrag möglich. Hierfür ist eine ausreichende Deckung Ihres Kundenkontos bei uns erforderlich. Überweisen Sie daher bitte einen entsprechenden Betrag auf das Konto. Bei Eintritt in die Mensa werden 4,95 € pro Spontanessen abgebucht. Die Verfügbarkeit möglicher
            Spontanessen pro Tag ist auf eine bestimmte Menge begrenzt.
        </p>
        <br>
        <p>
            Für die Kosten der Mittagsverpflegung können anspruchsberechtigte Personen Leistungen aus dem Bildungs- und Teilhabepaket (BuT) beantragen. Wenden Sie sich hierzu bitte an den Kreis Gütersloh.
        </p>
    </div>
</template>
<script>
import { Client } from '@/api'
export default {
  name: 'price-info',
  data () {
    return {
      isLoading: false,
      client: null,
      days: {
        MON: 'Montag',
        TUE: 'Dienstag',
        WED: 'Mittwoch',
        THU: 'Donnerstag',
        FRI: 'Freitag'
      }
    }
  },
  beforeMount () {
    this.isLoading = true
    console.log(this.$route)
    const id = this.$route.params.id
    Client.get(`/api/frontend/client/${id}`).then(({ data }) => {
      this.client = data
      this.isLoading = false
    })
  },
  computed: {
    daysOfOperation () {
      if (!this.client) { return null }
      return this.client.latestSchedule.daysOfOperation
    }
  }
}
</script>
