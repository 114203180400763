<template>
  <div class="form-control">
    <label class="label cursor-pointer" v-if="alignment === 'horizontal'">
      <span class="tooltip tooltip-right" v-if="tooltip" :data-tip="tooltip">
        <span class="label-text">{{ label }}</span>
      </span>
      <span v-else class="label-text">{{ label }}</span>
      <span class="py-4">
        <input type="checkbox" class="toggle" v-model="vModel" />
      </span>
    </label>
    <template v-else>
      <label class="label cursor-pointer">
        <span class="tooltip tooltip-right" v-if="tooltip" :data-tip="tooltip">
          <span class="label-text">{{ label }}</span>
        </span>
        <span v-else class="label-text">{{ label }}</span>
      </label>
      <span class="py-2 border border-transparent">
        <input type="checkbox" class="toggle" v-model="vModel" />
      </span>
    </template>
  </div>
</template>

<script>
import VModel from '@/mixins/vModel'
export default {
  mixins: [VModel],
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean
    },
    tooltip: {
      type: String,
      default: ''
    },
    alignment: {
      type: String,
      default: 'horizontal',
      validator: (value) => ['horizontal', 'vertical'].includes(value)
    }
  },
  name: 'base-toggle'
}
</script>

<style scoped>

</style>
