import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import Client from '@/api/axios'
import { LoginResponse } from '@interface/api/Response'
import Routing from '@/symfony'
import { RegisterPayload } from '@store/registration/interfaces'
import TokenStorage from '@/services/TokenStorage'

export interface LoginApi{
  current: () => Promise<AxiosPromise>
  login: (username: string, password: string) => Promise<AxiosPromise<LoginResponse>>
  logout: () => void,
  register: (customer: RegisterPayload) => Promise<AxiosPromise>
  optin: (token: string) => Promise<AxiosPromise>
  validateEMail: (email: string) => Promise<AxiosPromise>
  passwordForget: (email: string) => Promise<AxiosPromise>
  passwordReset: (password: string, token: string) => Promise<AxiosPromise>
}

export const Login: LoginApi = {
  async current (): Promise<AxiosPromise> {
    return Client.get('/api/me')
  },

  async logout () {
    const { data } = await Client.post(Routing.generate('gesdinet_jwt_token_invalidate'), { refresh_token: TokenStorage.getRefreshToken() })
    TokenStorage.clear()
  },
  async login (username, password): Promise<AxiosPromise<LoginResponse>> {
    const response : AxiosResponse<LoginResponse> = await Client.post('/login', {
      email: username,
      password: password
    })
    if (response.status === 200) {
      TokenStorage.setTokenIntoStorage(response.data)
    }

    return response
  },
  optin (token): Promise<AxiosPromise> {
    return Client.post(Routing.generate('api_customer_pre_register_optin_confirm', { token: token }))
  },
  validateEMail (email: string): Promise<AxiosPromise> {
    return Client.post(Routing.generate('api_customer_pre_register_validate_mail'), { email: email })
  },
  register (payload: RegisterPayload): Promise<AxiosPromise> {
    payload.customer.consumers = payload.customer.consumers.map((consumer) => {
      if (consumer.client) {
        // @ts-ignore
        consumer.client = Routing.generate('api_clients_get_item', { id: consumer.client.id })
      }
      delete consumer.id
      return consumer
    })
    return Client.post(Routing.generate('api_customer_pre_register_register'), payload)
  },
  passwordForget (email): Promise<AxiosPromise> {
    return Client.post(Routing.generate('api_password_forgot_request'), { email: email })
  },
  passwordReset (password, token) : Promise<AxiosPromise> {
    return Client.post(Routing.generate('api_password_reset'), { password: password, token: token })
  }
}
