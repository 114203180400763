import { GetterTree } from 'vuex'
import { RootState } from '../interfaces'
import { RegistrationState } from './interfaces'
import { Customer } from '@interface/entity/customer'

export const getters: GetterTree<RegistrationState, RootState> = {
  user (state): Customer | null {
    if (!state.user) { return null }
    return state.user
  },
  isLoading (state): boolean {
    return state.loading
  },
  step (state): number {
    return state.step
  },
  submitted (state) {
    return state.submitted
  }
}
