import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "tooltip tooltip-right tooltip-warning",
  "data-tip": "Ihr gewünschtes Passwort ist aktuell nicht sicher genug. Sie können fortfahren, jedoch raten wir Ihnen, Ihren Zugang zu unserm Portal stärker abzusichern."
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!
  const _component_password_input = _resolveComponent("password-input")!
  const _component_InfoCircleIcon = _resolveComponent("InfoCircleIcon")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args))),
    class: "grid grid-cols-12 gap-4"
  }, [
    _createVNode(_component_base_input, {
      class: "col-span-12",
      label: "Aktuelles Password",
      type: "password",
      name: "currentPassword",
      placeholder: "Password"
    }),
    _createVNode(_component_password_input, {
      class: "col-span-12",
      name: "newPassword",
      onStrength: _ctx.strength,
      label: "Passwort"
    }, null, 8, ["onStrength"]),
    _createVNode(_component_base_input, {
      class: "col-span-12",
      type: "password",
      name: "confirm",
      label: "Passwort wiederholen"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "submit",
        disabled: !_ctx.meta.valid,
        class: _normalizeClass(["btn btn-primary", {'loading': _ctx.isSubmitting}])
      }, [
        (!_ctx.isSubmitting)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Speichern "))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2),
      (_ctx.passwordStrength < 3 && _ctx.passwordStrength !== 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_InfoCircleIcon, { class: "w-6 h-6 text-warning" })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 32))
}