<template>
  <div class="form-control" :class="{'honeypot': honeypot }">
    <label class="label" v-if="label" :for="id">
      <span class="label-text" :class="{'text-opacity-50': disabled}" >{{ label }}</span>
    </label>
    <Field
      :name="name"
      v-model="vModel"
      ref="field"
      :rules="rules"
      :validate-on-input="true"
      v-slot="{ field, errorMessage, errors }"
    >
      <template v-if="!!icon">
        <div class="input-group">
          <span :class="{ 'bg-error text-error-content': errors.length > 0, 'bg-opacity-50 text-opacity-50': disabled  }">
            <component :is="icon" class="w-6 h-6" :class="{'opacity-50': disabled}" ></component>
          </span>
          <textarea v-bind="field" @input="resizeTextarea" @paste="paste" :disabled="disabled" :id="id" :placeholder="placeholder" class="textarea textarea-bordered resize-none w-full overflow-hidden" :class="{ 'textarea-error': errors.length > 0 }"></textarea>
        </div>
      </template>
      <textarea v-else v-bind="field" @input="resizeTextarea" @paste="paste" :disabled="disabled" :id="id" :placeholder="placeholder" class="textarea textarea-bordered resize-none w-full overflow-hidden" :class="{ 'textarea-error': errors.length > 0 }"></textarea>
      <label class="label" v-show="!!errorMessage">
        <span class="label-text-alt text-error">{{ errorMessage }}</span>
      </label>
    </Field>
  </div>
</template>

<script>
import BaseInput from './BaseInput'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'base-textarea',
  extends: BaseInput,
  setup () {
    return {
      id: uuidv4()
    }
  },
  methods: {
    resizeTextarea (event) {
      event.target.style.height = 'auto'
      event.target.style.height = (event.target.scrollHeight) + 'px'
    }
  }
}
</script>

<style  scoped>
  .honeypot {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
</style>
